import { css, darkThemeSelector, shadows } from '../stitches.config';

export const FocusRingSelf = css({
  transition: 'all 150ms ease-out',
  boxShadow: shadows.none,
  '&:focus': {
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
});

export const FocusRingSelfIndexed = css(FocusRingSelf, {
  position: 'relative',
  zIndex: 0,
  '&:focus': {
    zIndex: 1,
  },
});

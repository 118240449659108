import { Body2, HStack, Link, Subheading2, Title2, VStack } from '@meterup/metric';
import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { colors, styled } from '../../stitches';
import { getGrafanaURL } from '../../utils/grafana';

const axiosInstance = axios.create({
  withCredentials: true,
  responseType: 'json',
  headers: { Accept: 'application/json' },
  baseURL: `${import.meta.env.REACT_APP_API_URL}/api-proxy`,
});

const fetchPath = async (path: string) => {
  try {
    return (await axiosInstance.get<Record<string, string>>(path)).data;
  } catch (error) {
    return {};
  }
};

export const StatTitle = styled(Subheading2, {
  paddingRight: '6px',
});

export const StatContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  minWidth: '20px',
  backgroundColor: colors['gray-50'],
  borderRadius: '6px',
  padding: '4px',
});

export const StatText = styled(Body2, {
  color: colors['gray-600'],
  lineHeight: '20px',
  fontSize: '14px',
  weight: 'medium',
});

const Stats = () => {
  const { controllerName } = useParams<any>();
  const path = controllerName ? `/v1/noc/controllers/${controllerName}/stats` : '/v1/noc/stats';
  const stats = useQuery(['stats', path], () => fetchPath(path));

  return (
    <div style={{ margin: '20px' }}>
      <Title2>
        Stats
        {controllerName && `: ${controllerName}`} |{' '}
        <Link
          href={getGrafanaURL(controllerName)}
          target="_blank"
          style={{ color: colors['brand-600'].toString() }}
        >
          📈 Grafana
        </Link>
      </Title2>
      <VStack>
        {Object.entries(stats.data ?? {}).map(([k, v]) => (
          <HStack key={k} align="end">
            <StatTitle>{k}:</StatTitle>
            <StatContainer>
              <StatText>{v}</StatText>
            </StatContainer>
          </HStack>
        ))}
      </VStack>
    </div>
  );
};

export default Stats;

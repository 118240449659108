/**
 * Attempts to load an image at the given URL. Resolves with the given URL if
 * the image is available, otherwise null.
 */
export const preloadImage = (url: string) =>
  new Promise<string | null>((resolve) => {
    const imgElement = new Image();
    imgElement.addEventListener('load', () => resolve(url));
    imgElement.addEventListener('error', () => resolve(null));
    imgElement.src = url;
  });

import type { CSS } from '../../stitches.config';

export type AlignTypes = 'baseline' | 'center' | 'end' | 'start' | 'stretch';
export type AlignProps = { align?: AlignTypes };

export const alignCSS = (align?: AlignTypes): CSS =>
  align != null
    ? {
        alignItems: align,
      }
    : {};

import type { AriaCheckboxProps } from '@react-types/checkbox';
import React, { useRef } from 'react';
import { useCheckbox } from 'react-aria';
import { useToggleState } from 'react-stately';

import type { RenameKeys } from '../../types/rename_keys';
import type { Simplify } from '../../types/simplify';
import Icon from '../../assets/Icon/Icon';
import { VisuallyHidden } from '../../common/visually_hidden';
import { colors, styled } from '../../stitches.config';
import {
  iconColor,
  primaryDisabledStyles,
  primaryEnabledStyles,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
  sharedTransition,
} from '../Button/button_styles';

const Container = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$14',
  minWidth: '$14',
  height: '$14',
  minHeight: '$14',
  borderRadius: '$4',
  cursor: 'pointer',
  userSelect: 'none',
  transition: sharedTransition,
  '&:focus': {
    outline: 'none',
  },
  variants: {
    state: {
      initial: {},
      checked: {},
      indeterminate: {},
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      state: 'initial',
      isDisabled: false,
      css: secondaryEnabledStyles,
    },
    {
      state: 'checked',
      isDisabled: false,
      css: primaryEnabledStyles,
    },
    {
      state: 'indeterminate',
      isDisabled: false,
      css: { ...secondaryEnabledStyles, [iconColor]: colors['gray-300'] },
    },
    {
      state: 'initial',
      isDisabled: true,
      css: secondaryDisabledStyles,
    },
    {
      state: 'checked',
      isDisabled: true,
      css: primaryDisabledStyles,
    },
    {
      state: 'indeterminate',
      isDisabled: true,
      css: secondaryDisabledStyles,
    },
  ],
});

const StyledIcon = styled(Icon, {
  color: iconColor,
  transition: sharedTransition,
  width: '$12',
  height: '$12',
});

export type CheckboxInputProps = Simplify<
  RenameKeys<
    AriaCheckboxProps,
    {
      isDisabled: 'disabled';
      isSelected: 'checked';
      isIndeterminate: 'indeterminate';
    }
  >
>;

const CheckboxInput = (props: CheckboxInputProps) => {
  const { disabled = false, indeterminate = false, checked } = props;
  const ariaProps = {
    ...props,
    isSelected: checked,
    isIndeterminate: indeterminate,
    isDisabled: disabled,
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const toggleState = useToggleState(ariaProps);
  const { inputProps } = useCheckbox(ariaProps, toggleState, inputRef);

  let state: 'checked' | 'indeterminate' | 'initial';
  if (toggleState.isSelected) {
    state = 'checked';
  } else if (indeterminate) {
    state = 'indeterminate';
  } else {
    state = 'initial';
  }

  return (
    <Container state={state} isDisabled={disabled}>
      {state !== 'initial' && <StyledIcon icon={state === 'checked' ? 'checkmark' : 'minus'} />}
      <VisuallyHidden>
        <input type="checkbox" {...inputProps} />
      </VisuallyHidden>
    </Container>
  );
};

export default CheckboxInput;

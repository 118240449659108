import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Allows setting a boolean value to true for a certain amount of time.
 *
 * @param timeoutMS Timeout in milliseconds
 */
export const useTimedToggle = (timeoutMS: number = 1000) => {
  const timerRef = useRef<any | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    const startOrStop = () => {
      if (startTime != null) {
        const delta = Date.now() - startTime;
        if (delta >= timeoutMS) {
          setStartTime(null);
        } else {
          timerRef.current = setTimeout(startOrStop, delta);
        }
      }
    };

    startOrStop();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [startTime, timeoutMS]);

  const show = useCallback(() => {
    setStartTime(Date.now());
  }, [setStartTime]);

  const hide = useCallback(() => {
    setStartTime(null);
  }, [setStartTime]);

  const toggle = () => setStartTime((prev) => (prev ? null : Date.now()));

  return {
    value: startTime != null,
    timeout: timeoutMS,
    show,
    hide,
    toggle,
  };
};

/**
 * Allows setting a boolean value to true for a certain amount of time and
 * tracks the timer's progress. If you don't need to use the timer's progress,
 * prefer useTimedToggle to avoid unnecessary re-renders.
 *
 * @param timeoutMS Timeout in milliseconds
 */
export const useTimedToggleWithProgress = (timeoutMS: number = 1000) => {
  const frameRef = useRef<number | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [timeElapsed, setTimeElapsed] = useState<number | null>(null);

  useEffect(() => {
    const handleFrame = () => {
      if (startTime != null) {
        const delta = Date.now() - startTime;
        setTimeElapsed(delta);
        if (delta >= timeoutMS) {
          setStartTime(null);
          setTimeElapsed(null);
        } else {
          frameRef.current = requestAnimationFrame(handleFrame);
        }
      }
    };

    frameRef.current = requestAnimationFrame(handleFrame);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
        frameRef.current = null;
      }
    };
  }, [startTime, setTimeElapsed, timeoutMS]);

  const show = useCallback(() => {
    setStartTime(Date.now());
  }, [setStartTime]);

  const hide = useCallback(() => {
    setStartTime(null);
  }, [setStartTime]);

  const toggle = () => setStartTime((prev) => (prev ? null : Date.now()));

  return {
    value: startTime != null,
    timeElapsed,
    timeRemaining: timeElapsed != null ? timeoutMS - timeElapsed : 0,
    timeout: timeoutMS,
    progress: timeElapsed != null ? timeElapsed / timeoutMS : 0,
    show,
    hide,
    toggle,
  };
};

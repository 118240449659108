import {
  BasicSelect,
  BasicSelectItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';

import { getIdentity } from '../api/api';
import { useLogoutHandler } from '../hooks/useLogoutHandler';
import {
  useChangeCurrentTimezoneCallback,
  useCurrentTimezone,
} from '../providers/CurrentTimezoneProvider';
import {
  CENTRAL_TIMEZONE,
  EASTERN_TIMEZONE,
  MOUNTAIN_TIMEZONE,
  PACIFIC_TIMEZONE,
  timezones,
} from './timestamps';
import { UserProfilePicture } from './UserProfilePicture';

export const TimezoneDropdownString = ({ tz }: { tz: string }) =>
  match(tz)
    .with(PACIFIC_TIMEZONE, () => 'Los Angeles (Pacific Standard Time)')
    .with(MOUNTAIN_TIMEZONE, () => 'Denver (Mountain Standard Time)')
    .with(CENTRAL_TIMEZONE, () => 'Chicago (Central Standard Time)')
    .with(EASTERN_TIMEZONE, () => 'New York (Eastern Standard Time)')
    .otherwise(() => tz);

export const HeaderDropdownMenu = () => {
  const timezone = useCurrentTimezone();
  const setTimezone = useChangeCurrentTimezoneCallback();
  const logout = useLogoutHandler();
  const identity = useQuery('identity', getIdentity, { suspense: false }).data;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        {identity ? (
          <UserProfilePicture
            email={identity.username}
            firstName={identity.first_name}
            lastName={identity.last_name}
          />
        ) : null}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div style={{ maxWidth: 260, padding: '4px 4px 0 4px' }}>
          <BasicSelect
            width="truncate"
            defaultValue={timezone}
            onValueChange={(key) => setTimezone(key as string)}
          >
            {timezones.map((t) => (
              <BasicSelectItem key={t} value={t}>
                {TimezoneDropdownString({ tz: t })}
              </BasicSelectItem>
            ))}
          </BasicSelect>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuItem icon="power" onSelect={logout}>
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

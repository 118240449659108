import React from 'react';

import { StyledSVG } from './common';
import ProductIconConnect from './icons/ProductIconConnect';
import ProductIconNetwork from './icons/ProductIconNetwork';
import ProductIconRemote from './icons/ProductIconRemote';
import ProductIconSettings from './icons/ProductIconSettings';
import ProductIconVPN from './icons/ProductIconVPN';

export type ProductIconProduct = 'connect' | 'network' | 'remote' | 'settings' | 'vpn';

export interface ProductIconProps {
  /**
   * Set which Meter product you wish to display.
   */
  product: ProductIconProduct;
  /**
   * Set to `true` if inside an active element.
   */
  selected?: boolean;
}

export const ProductIcon = ({ product, selected = false }: ProductIconProps) => {
  const content = {
    connect: <ProductIconConnect />,
    network: <ProductIconNetwork />,
    remote: <ProductIconRemote />,
    settings: <ProductIconSettings />,
    vpn: <ProductIconVPN />,
  }[product];

  return (
    <StyledSVG
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={selected ? 'selected' : undefined}
    >
      {content}
    </StyledSVG>
  );
};

export default ProductIcon;

import type React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';

export const ListItemValue = styled('dd', Body2, {
  flexGrow: 1,
  display: 'flex',
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
  variants: {
    wordBreak: {
      'break-all': {
        wordBreak: 'break-all',
      },
      'break-word': {
        wordBreak: 'break-word',
      },
      normal: {
        wordBreak: 'normal',
      },
    },
    align: {
      start: {
        justifyContent: 'start',
      },
      end: {
        justifyContent: 'flex-end',
        textAlign: 'right',
      },
    },
  },
  defaultVariants: {
    align: 'end',
  },
});

export type ListItemValueProps = React.ComponentProps<typeof ListItemValue>;

export default ListItemValue;

import React from 'react';
import { useId } from 'react-aria';

import { Background, Detail, Glyph, Tint } from '../common';

export const ProductIconRemote = () => {
  const mask0 = useId();
  const mask1 = useId();
  return (
    <>
      <Background d="M0 8C0 5.09903 0 3.64855 0.595437 2.55452C1.04613 1.72642 1.72642 1.04613 2.55452 0.595437C3.64855 0 5.09903 0 8 0C10.901 0 12.3515 0 13.4455 0.595437C14.2736 1.04613 14.9539 1.72642 15.4046 2.55452C16 3.64855 16 5.09903 16 8C16 10.901 16 12.3515 15.4046 13.4455C14.9539 14.2736 14.2736 14.9539 13.4455 15.4046C12.3515 16 10.901 16 8 16C5.09903 16 3.64855 16 2.55452 15.4046C1.72642 14.9539 1.04613 14.2736 0.595437 13.4455C0 12.3515 0 10.901 0 8Z" />
      <mask id={mask0} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <path
          fill="white"
          d="M0 8C0 5.09903 0 3.64855 0.595437 2.55452C1.04613 1.72642 1.72642 1.04613 2.55452 0.595437C3.64855 0 5.09903 0 8 0C10.901 0 12.3515 0 13.4455 0.595437C14.2736 1.04613 14.9539 1.72642 15.4046 2.55452C16 3.64855 16 5.09903 16 8C16 10.901 16 12.3515 15.4046 13.4455C14.9539 14.2736 14.2736 14.9539 13.4455 15.4046C12.3515 16 10.901 16 8 16C5.09903 16 3.64855 16 2.55452 15.4046C1.72642 14.9539 1.04613 14.2736 0.595437 13.4455C0 12.3515 0 10.901 0 8Z"
        />
      </mask>
      <g mask={`url(#${mask0})`}>
        <mask id={mask1} maskUnits="userSpaceOnUse" x="-1" y="4" width="18" height="18">
          <circle cx="8.00004" cy="12.8" r="8.8" fill="white" />
        </mask>
        <g mask={`url(#${mask1})`}>
          <Glyph as="circle" cx="8.00004" cy="12.8" r="8.8" />
          <Detail as="ellipse" cx="8.00009" cy="8.4" rx="8.8" ry="3.6" />
          <Tint as="ellipse" cx="8.00009" cy="5.6" rx="4.8" ry="1.6" />
        </g>
      </g>
    </>
  );
};

export default ProductIconRemote;

import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import type { SidebarNavItemProps } from '../Sidebars/SidebarNavItem';
import { SidebarNavItem } from '../Sidebars/SidebarNavItem';

export interface DesktopSidebarNavItemProps extends SidebarNavItemProps {}

const DesktopSidebarNavItem = React.forwardRef(
  <Tag extends React.ElementType>(
    { size = 'medium', ...props }: PolymorphicComponentProps<Tag, DesktopSidebarNavItemProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => <SidebarNavItem ref={forwardedRef} size={size} {...props} />,
) as Polymorphic.ForwardRefComponent<React.ElementType, DesktopSidebarNavItemProps>;

export default DesktopSidebarNavItem;

export interface DisplayableError {
  displayTitle: string;
  displayMessage?: string;
}

export const isDisplayableError = (v: any): v is DisplayableError =>
  typeof v === 'object' &&
  v !== null &&
  'displayTitle' in v &&
  typeof (v as { displayTitle: unknown }).displayTitle === 'string';

/* eslint-disable react/prop-types, react/no-unused-prop-types */
import type { AriaTextFieldProps } from '@react-types/textfield';
import React, { useRef } from 'react';
import { useTextField } from 'react-aria';

import type { RenameKeys } from '../../types/rename_keys';
import type { InputSharedProps } from '../BaseInput/BaseInput';
import type { ControlSize } from '../Control/types';
import { BaseInput } from '../BaseInput/BaseInput';

export interface TextInputProps
  extends InputSharedProps,
    RenameKeys<
      AriaTextFieldProps,
      {
        isDisabled: 'disabled';
      }
    > {
  hasError?: boolean;
  controlSize?: ControlSize;
}

const TextInput = React.forwardRef<HTMLLabelElement, TextInputProps>((props, ref) => {
  const { hasError, icon, prefix, suffix, controlSize, disabled = false } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useTextField({ ...props, isDisabled: disabled }, inputRef);

  return (
    <BaseInput
      ref={ref}
      inputRef={inputRef}
      inputProps={inputProps}
      hasError={hasError}
      disabled={disabled}
      icon={icon}
      prefix={prefix}
      suffix={suffix}
      controlSize={controlSize}
    />
  );
});

export default TextInput;

import type { api } from '@meterup/proto';

export const isOnline = (c: api.ControllerClient | api.UserClient): boolean => {
  if (c.last_seen) {
    const dateLessFive = new Date();
    const itemDate = new Date(c.last_seen);

    dateLessFive.setMinutes(dateLessFive.getMinutes() - 5);

    return itemDate >= dateLessFive;
  }

  return false;
};

export const isOffline = (c: api.ControllerClient | api.UserClient): boolean => !isOnline(c);

export const isGuest = (c: api.ControllerClient | api.UserClient) => c.tags.includes('guest');

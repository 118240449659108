import {
  BasicSelect,
  BasicSelectItem,
  Button,
  CompositeField,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  FieldContainer,
  HStack,
  ListItemHeader,
  ListTitle,
  SecondaryField,
  SecondaryFieldComposite,
  space,
  TextInput,
} from '@meterup/metric';
import { mboot } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';

import type { ValidLegacyNetworkInfoFormData } from './ValidLegacyNetworkInfoFormData';
import { FieldProvider } from '../../../../components/FieldProvider';
import { paths } from '../../../../constants';
import { LegacyNetworkPSKSchemeType } from '../../../../utils/legacy_network_info';
import { makeDrawerLink } from '../../../../utils/makeLink';

export default function LegacyNetworkInfoForm({
  controllerName,
  onClickReview,
}: {
  controllerName: string;
  onClickReview: () => void;
}) {
  const form = useFormikContext<ValidLegacyNetworkInfoFormData>();

  const isGuestPasswordDisabled =
    form.values.guest_strategy !== mboot.GuestPasswordStrategy.UNKNOWN;

  return (
    <>
      <DrawerContent>
        <FieldContainer>
          <ListItemHeader>
            <ListTitle>Private network</ListTitle>
          </ListItemHeader>
          <FieldProvider name="private_ssid">
            <SecondaryField label="SSID 5G" element={<TextInput />} />
          </FieldProvider>
          <FieldProvider name="private_2g_ssid">
            <SecondaryField label="SSID 2G" element={<TextInput />} />
          </FieldProvider>
          <FieldProvider name="private_password">
            <SecondaryField label="Password" element={<TextInput />} />
          </FieldProvider>
        </FieldContainer>
        <FieldContainer>
          <ListItemHeader>
            <ListTitle>Guest network</ListTitle>
          </ListItemHeader>
          <FieldProvider name="guest_ssid">
            <SecondaryField label="SSID" element={<TextInput />} />
          </FieldProvider>
          <SecondaryFieldComposite
            label="Password"
            fields={
              <HStack spacing={space(8)}>
                <FieldProvider name="guest_psk_scheme">
                  <CompositeField
                    label="Type"
                    element={
                      <BasicSelect>
                        <BasicSelectItem
                          value={LegacyNetworkPSKSchemeType.Static}
                          disabled={isGuestPasswordDisabled}
                        >
                          Static
                        </BasicSelectItem>
                        <BasicSelectItem value={LegacyNetworkPSKSchemeType.Rotating} disabled>
                          Rotating
                        </BasicSelectItem>
                      </BasicSelect>
                    }
                  />
                </FieldProvider>
                {form.values.guest_psk_scheme === LegacyNetworkPSKSchemeType.Static && (
                  <FieldProvider name="guest_password">
                    <CompositeField label="Password" element={<TextInput />} />
                  </FieldProvider>
                )}
                {form.values.guest_psk_scheme === LegacyNetworkPSKSchemeType.Rotating && (
                  <FieldProvider name="guest_strategy">
                    <CompositeField
                      label="Rotation frequency"
                      element={
                        <BasicSelect>
                          <BasicSelectItem value={mboot.GuestPasswordStrategy.NEVER} disabled>
                            Never
                          </BasicSelectItem>
                          <BasicSelectItem value={mboot.GuestPasswordStrategy.DAILY} disabled>
                            Daily
                          </BasicSelectItem>
                          <BasicSelectItem value={mboot.GuestPasswordStrategy.MONTHLY} disabled>
                            Monthly
                          </BasicSelectItem>
                        </BasicSelect>
                      }
                    />
                  </FieldProvider>
                )}
              </HStack>
            }
          />
        </FieldContainer>
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            as={Link}
            to={makeDrawerLink(paths.drawers.LegacyNetworkInfo, { controllerName })}
          >
            Cancel
          </Button>
          <Button onClick={onClickReview} disabled={!form.isValid}>
            Review
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </>
  );
}

/* eslint-disable react/prop-types */
import type { SelectItemProps, SelectProps } from '@radix-ui/react-select';
import type { AriaLabelingProps, DOMProps } from '@react-types/shared';
import React, { forwardRef } from 'react';

import type { ButtonWidth } from '../Button/Button';
import type { ControlSize } from '../Control/types';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTriggerButton,
  SelectValue,
  SelectViewport,
} from './Select';

interface BasicSelectProps extends SelectProps, AriaLabelingProps, DOMProps {
  controlSize?: ControlSize;
  hasError?: boolean;
  width?: ButtonWidth;
}

export const BasicSelect = forwardRef<HTMLButtonElement, BasicSelectProps>(
  (
    {
      // SelectProps
      value,
      defaultValue,
      onValueChange,
      open,
      defaultOpen,
      onOpenChange,
      dir,
      name,
      autoComplete,

      children,

      // ButtonProps
      controlSize,
      hasError = false,
      width = 'auto',
      ...props
    },
    forwardedRef,
  ) => (
    <Select
      value={value}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      dir={dir}
      name={name}
      autoComplete={autoComplete}
    >
      <SelectTriggerButton
        size={controlSize}
        hasError={hasError}
        width={width}
        variant="secondary"
        ref={forwardedRef}
        {...props}
      >
        <SelectValue />
      </SelectTriggerButton>
      <SelectContent>
        <SelectScrollUpButton />
        <SelectViewport>{children}</SelectViewport>
        <SelectScrollDownButton />
      </SelectContent>
    </Select>
  ),
);

export const BasicSelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <SelectItem {...props} ref={forwardedRef}>
      <SelectItemText>{children}</SelectItemText>
    </SelectItem>
  ),
);

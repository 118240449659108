import React from 'react';

import type { TextComponent, TextProps } from '../utilities/Text/Text';
import { fonts, fontWeights, styled } from '../stitches.config';
import Text, { Text2 } from '../utilities/Text/Text';

export type CaptionProps<Tag extends React.ElementType = 'span'> = TextProps<Tag>;

export const Caption: TextComponent = <Tag extends React.ElementType = 'span'>({
  children,
  color = {
    light: 'gray-600',
    dark: 'gray-200',
  },
  family = 'regular',
  weight = 'regular',
  ...remaining
}: CaptionProps<Tag>) => (
  <Text
    {...remaining}
    color={{ light: color.light, dark: color.dark }}
    family={family}
    lineHeight={12}
    size={11}
    transform="uppercase"
    weight={weight}
  >
    {children}
  </Text>
);

export const Caption2 = styled('span', Text2, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$11',
  lineHeight: '$12',
  textTransform: 'uppercase',
});

export default Caption;

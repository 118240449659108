import 'regenerator-runtime/runtime';
import './sentry';

import { enableMapSet } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

enableMapSet();

declare global {
  interface Navigator {
    msSaveOrOpenBlob(blob: any, defaultName?: string): boolean;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

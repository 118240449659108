/* eslint-disable @typescript-eslint/no-use-before-define */
import type { Node } from '@react-types/shared';
import type { AriaListBoxOptions } from 'react-aria';
import type { ListState } from 'react-stately';
import * as React from 'react';
import { useListBox, useListBoxSection, useOption } from 'react-aria';

import Icon from '../../assets/Icon/Icon';
import { itemIndicatorStyles, itemStyles, labelStyles, separatorStyles } from '../../common/menus';
import { styled } from '../../stitches.config';
import { Small2 } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface SectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

const StyledContent = styled('ul', { height: '100%', overflow: 'auto' });
const StyledItem = styled('li', itemStyles);
const StyledItemIndicator = styled('div', itemIndicatorStyles);
const StyledLabel = styled('div', Small2, labelStyles);
const StyledSeparator = styled('div', separatorStyles);
const StyledSection = styled('li', {
  vStack: '$2',
  alignItems: 'stretch',
  '&:first-child': {
    [`& ${StyledSeparator}`]: {
      display: 'none',
    },
  },
});

const Option = ({ item, state }: OptionProps) => {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isDisabled, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  return (
    <StyledItem
      {...optionProps}
      ref={ref}
      data-focused={isFocused ? '' : undefined}
      data-disabled={isDisabled ? '' : undefined}
    >
      {isSelected && (
        <StyledItemIndicator>
          <Icon icon="checkmark" size={space(12)} />
        </StyledItemIndicator>
      )}
      {item.rendered}
    </StyledItem>
  );
};

const ListBoxSection = ({ section, state }: SectionProps) => {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <StyledSection {...itemProps}>
      <StyledSeparator />
      {section.rendered && <StyledLabel {...headingProps}>{section.rendered}</StyledLabel>}
      <ul {...groupProps}>
        {[...section.childNodes].map((node) => (
          <Option key={node.key} item={node} state={state} />
        ))}
      </ul>
    </StyledSection>
  );
};

export const ListBox = (props: ListBoxProps) => {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(
    {
      ...props,
    },
    state,
    listBoxRef,
  );

  return (
    <StyledContent {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) =>
        item.type === 'section' ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        ),
      )}
    </StyledContent>
  );
};

import React from 'react';
import { useId } from 'react-aria';

import { Background, Detail, Glyph } from '../common';

export const ProductIconVPN = () => {
  const mask = useId();
  return (
    <>
      <Background d="M0 8C0 5.09903 0 3.64855 0.595437 2.55452C1.04613 1.72642 1.72642 1.04613 2.55452 0.595437C3.64855 0 5.09903 0 8 0C10.901 0 12.3515 0 13.4455 0.595437C14.2736 1.04613 14.9539 1.72642 15.4046 2.55452C16 3.64855 16 5.09903 16 8C16 10.901 16 12.3515 15.4046 13.4455C14.9539 14.2736 14.2736 14.9539 13.4455 15.4046C12.3515 16 10.901 16 8 16C5.09903 16 3.64855 16 2.55452 15.4046C1.72642 14.9539 1.04613 14.2736 0.595437 13.4455C0 12.3515 0 10.901 0 8Z" />
      <mask id={mask} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <path
          fill="white"
          d="M0 8C0 5.09903 0 3.64855 0.595437 2.55452C1.04613 1.72642 1.72642 1.04613 2.55452 0.595437C3.64855 0 5.09903 0 8 0C10.901 0 12.3515 0 13.4455 0.595437C14.2736 1.04613 14.9539 1.72642 15.4046 2.55452C16 3.64855 16 5.09903 16 8C16 10.901 16 12.3515 15.4046 13.4455C14.9539 14.2736 14.2736 14.9539 13.4455 15.4046C12.3515 16 10.901 16 8 16C5.09903 16 3.64855 16 2.55452 15.4046C1.72642 14.9539 1.04613 14.2736 0.595437 13.4455C0 12.3515 0 10.901 0 8Z"
        />
      </mask>
      <g mask={`url(#${mask})`}>
        <Detail d="M4.8 9.6H11.2L16 17.6H0L4.8 9.6Z" />
        <Glyph d="M4.79987 6.4C4.79987 4.63269 6.23255 3.2 7.99987 3.2C9.76718 3.2 11.1999 4.63268 11.1999 6.4V9.6H4.79987V6.4Z" />
      </g>
    </>
  );
};

export default ProductIconVPN;

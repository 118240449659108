import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Skeleton } from '@meterup/metric';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { DrawerRoutes } from '../../components/DrawerRoutes';
import { MainAndDrawerLayout } from '../../layouts/MainAndDrawerLayout';

export const Meta: PagefileMetaFn = () => ({
  path: '/',
  layout: null,
});

export default function AppPage() {
  return (
    <Suspense
      fallback={
        <div style={{ padding: 16 }}>
          <Skeleton width="100%" height="240px" radius={8} />
        </div>
      }
    >
      <MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />
    </Suspense>
  );
}

import type { ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { isDefined } from '../../../../utilities/isDefined';
import { ErrorMessage } from './styles';

export interface ErrorMessageRegistry {
  registerMessage: (id: string, message: ReactNode) => void;
  unregisterMessage: (id: string) => void;
}

export const ErrorMessageRegistryContext = createContext<ErrorMessageRegistry>(null as any);

/**
 * Maintains a collection of error messages meant render validation errors
 * associated with nested form fields outside of their parent's DOM.
 */
export const useErrorMessages = () => {
  const [messages, setMessages] = useState<Record<string, ReactNode>>({});

  const registerMessage = useCallback(
    (id: string, message: ReactNode) => {
      setMessages((prev) => ({ ...prev, [id]: message }));
    },
    [setMessages],
  );

  const unregisterMessage = useCallback(
    (id: string) => {
      setMessages((prev) => ({ ...prev, [id]: undefined }));
    },
    [setMessages],
  );

  return { messages, registerMessage, unregisterMessage };
};

/**
 * Registers an error message with the ErrorMessageRegistry, to be rendered via
 * the ErrorMessages component. It's worth noting that this can cause the
 * ErrorMessage to render to DOM after the initial render and may lead to quirky
 * behavior.
 *
 * Note: Be careful with this component as it can create infinite render loops
 * if its `id` or `errorMessage` props change on each render!
 */
export const ErrorMessageFakePortal = ({
  errorMessage,
  id,
}: {
  id: string;
  errorMessage: ReactNode;
}) => {
  const { registerMessage, unregisterMessage } = useContext(ErrorMessageRegistryContext);

  useEffect(() => {
    registerMessage(id, <ErrorMessage id={id}>{errorMessage}</ErrorMessage>);

    return () => unregisterMessage(id);
  }, [id, errorMessage, registerMessage, unregisterMessage]);

  return null;
};

/**
 * Renders a list of error messages.
 */
export const ErrorMessages = ({ messages }: { messages: Record<string, ReactNode> }) => (
  <>
    {Object.values(messages)
      .filter(isDefined)
      .map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>{message}</React.Fragment>
      ))}
  </>
);

import type { ReactNode } from 'react';
import { Skeleton } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';

import { GenericErrorFallback } from '../components/error_fallbacks';
import { shadows, styled } from '../stitches';

const Container = styled('div', {
  width: '100%',
  height: '100%',
  display: 'grid',
  overflow: 'auto',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
});

export const HeaderContainer = styled('div', {
  padding: '$12 $20',
  boxShadow: shadows.fenceAllLight,
  overflow: 'auto',
});

export const ControllerPageLayout = ({
  header,
  footer,
  children,
}: {
  header: ReactNode;
  footer: ReactNode;
  children: ReactNode;
}) => (
  <Container>
    <ErrorBoundary fallback={GenericErrorFallback}>
      <Suspense
        fallback={
          <HeaderContainer>
            <Skeleton width="200px" height="28px" radius={8} />
          </HeaderContainer>
        }
      >
        {header}
      </Suspense>
    </ErrorBoundary>

    <ErrorBoundary fallback={GenericErrorFallback}>
      <Suspense fallback={<Skeleton width="100%" height="240px" radius={8} />}>{children}</Suspense>
    </ErrorBoundary>

    <ErrorBoundary fallback={GenericErrorFallback}>
      <Suspense fallback={<Skeleton width="100%" height="28px" radius={8} />}>{footer}</Suspense>
    </ErrorBoundary>
  </Container>
);

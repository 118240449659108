import type { HelpTextProps } from '@react-types/shared/src/inputs';
import type { ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { FieldPropsWithElement } from './common/types';
import { ControlSizeProvider } from '../../../common/control_size';
import { styled } from '../../../stitches.config';
import ListItem from '../../List/ListItem';
import { ErrorMessages, useErrorMessages } from './common/ErrorMessageRegistry';
import { FieldCompositeProvider } from './common/FieldCompositeProvider';
import { ErrorMessage, FieldDescription, PrimaryFieldLabel } from './common/styles';
import { useDescriptionAndErrorIds } from './common/useDescriptionAndErrorIds';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const Container = styled(ListItem, {
  vStack: '$8',
  alignItems: 'stretch',
  display: 'flex',
  paddingY: '$12',
});

export const Label = styled('div', {
  hStack: '$12',
});

export const Controls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});

interface PrimaryFieldProps extends FieldPropsWithElement {
  controls?: ReactNode;
}

export const PrimaryField = (props: PrimaryFieldProps) => {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    description = customFieldProps.description,
    errorMessage = customFieldProps.errorMessage,
    controls,
    element,
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  return (
    <MaybeFieldContainer>
      <Container>
        <Label>
          <PrimaryFieldLabel {...labelProps}>{label}</PrimaryFieldLabel>
          {controls && (
            <Controls>
              <ControlSizeProvider value="small">{controls}</ControlSizeProvider>
            </Controls>
          )}
        </Label>
        <ControlSizeProvider value="large">
          <Slot {...mergeProps(customInputProps, inputProps)}>{element}</Slot>
        </ControlSizeProvider>
        {description && <FieldDescription {...descriptionProps}>{description}</FieldDescription>}
      </Container>
      {errorMessage && <ErrorMessage {...errorMessageProps}>{errorMessage}</ErrorMessage>}
    </MaybeFieldContainer>
  );
};

export type PrimaryFieldCompositeProps = {
  label: ReactNode;
  fields: ReactNode;
  controls?: ReactNode;
} & HelpTextProps;

export const PrimaryFieldComposite = ({
  label,
  controls,
  fields,
  errorMessage,
  description,
}: PrimaryFieldCompositeProps) => {
  const { messages, registerMessage, unregisterMessage } = useErrorMessages();

  const { descriptionId, errorMessageId, describedBy } = useDescriptionAndErrorIds(
    errorMessage,
    description,
  );

  return (
    <FieldCompositeProvider
      registerMessage={registerMessage}
      unregisterMessage={unregisterMessage}
      describedBy={describedBy}
    >
      <MaybeFieldContainer>
        <Container>
          <Label>
            <PrimaryFieldLabel>{label}</PrimaryFieldLabel>
            {controls && (
              <ControlSizeProvider value="small">
                <Controls>{controls}</Controls>
              </ControlSizeProvider>
            )}
          </Label>
          <ControlSizeProvider value="large">{fields}</ControlSizeProvider>
          {description && <FieldDescription id={descriptionId}>{description}</FieldDescription>}
        </Container>

        <ErrorMessages messages={messages} />

        {errorMessage && <ErrorMessage id={errorMessageId}>{errorMessage}</ErrorMessage>}
      </MaybeFieldContainer>
    </FieldCompositeProvider>
  );
};

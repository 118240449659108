/* eslint-disable no-param-reassign,max-classes-per-file */
import type * as ct from '../../generated/config';

export const METER_V1_NETWORK_VLAN_PREFIX = 'meter.v1.network.vlan.';
export const METER_V2_WIRELESS_TAGS_PREFIX = 'meter.v2.wireless.tags.';
export const METER_V2_WIRELESS_ACCESS_POINTS_PREFIX = 'meter.v2.wireless.access-points.';
export const METER_V1_NETWORK_WAN_PREFIX = 'meter.v1.network.wan.';

export interface ControllerConfigObject {
  'meter.v1.meta'?: ct.MeterV1Meta;
  [k: `${typeof METER_V1_NETWORK_VLAN_PREFIX}${string}`]: ct.MeterV1NetworkVLAN | undefined;
  [k: `${typeof METER_V1_NETWORK_WAN_PREFIX}${string}`]: ct.MeterV1NetworkWAN | undefined;
  [k: `${typeof METER_V2_WIRELESS_ACCESS_POINTS_PREFIX}${string}`]:
    | ct.MeterV2WirelessAccessPoint
    | undefined;
  [k: `${typeof METER_V2_WIRELESS_TAGS_PREFIX}${string}`]: ct.MeterV2WirelessTag | undefined;
}

export { ct };

import { mboot, wiman } from '@meterup/proto';
import * as z from 'zod';

import type { NetworkInfoUpdateRequest } from '../../../../api/types';
import { LegacyNetworkPSKSchemeType } from '../../../../utils/legacy_network_info';

export const validLegacyNetworkInfoFormData = z.object({
  private_ssid: z.string(),
  private_2g_ssid: z.string(),
  private_password: z.string().min(8),
  guest_ssid: z.string(),
  guest_password: z.string().min(8).optional(),
  guest_strategy: z.nativeEnum(mboot.GuestPasswordStrategy),
  guest_psk_scheme: z.enum(['static', 'rotating']),
});

export type ValidLegacyNetworkInfoFormData = z.infer<typeof validLegacyNetworkInfoFormData>;

export const formDataToAPIData = (
  formData: ValidLegacyNetworkInfoFormData,
): NetworkInfoUpdateRequest => ({
  private_ssid: formData.private_ssid,
  private_2g_ssid: formData.private_2g_ssid,
  private_password: formData.private_password,
  private_auth_method: wiman.AuthMethod.psk2,
  guest_ssid: formData.guest_ssid,
  guest_password:
    formData.guest_psk_scheme === LegacyNetworkPSKSchemeType.Static
      ? formData.guest_password
      : undefined,
  guest_strategy:
    formData.guest_psk_scheme === LegacyNetworkPSKSchemeType.Rotating
      ? formData.guest_strategy
      : undefined,
});

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeCloseDrawerLink } from '../utils/makeLink';

export const useCloseDrawerCallback = () => {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(makeCloseDrawerLink());
  }, [navigate]);
};

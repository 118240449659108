import { freeze, immerable } from 'immer';

import type { ct } from '../types/config/config';

export class MeterV2WirelessAccessPoint {
  [immerable] = true;

  private constructor(public name: string, public json: ct.MeterV2WirelessAccessPoint) {}

  static fromJSON(name: string, json: ct.MeterV2WirelessAccessPoint): MeterV2WirelessAccessPoint {
    return freeze(new MeterV2WirelessAccessPoint(name, json), true);
  }

  toJSON(): ct.MeterV2WirelessAccessPoint {
    return this.json;
  }
}

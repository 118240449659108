import React from 'react';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const Container = styled('div', {
  position: 'relative',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxShadow: shadows.fenceRightLight,
  background: colors.white,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceRightDark,
    background: colors['gray-800'],
  },
});

export interface DesktopSidebarProps {}

export const DesktopSidebar: React.FunctionComponent<DesktopSidebarProps> = ({ children }) => (
  <Container>{children}</Container>
);

export default DesktopSidebar;

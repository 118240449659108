import {
  DesktopSidebar,
  MobileSidebar,
  MobileSidebarToggle,
  MobileSidebarToggleContainer,
  SidebarGroup,
  SidebarNavItem,
} from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { useIsPathActiveFn } from '../../hooks/useIsPathActiveFn';
import { makeLink } from '../../utils/makeLink';
import { Nav } from '../Nav';
import { MobileSidebarModal } from './MobileSidebarModal';

const SidebarEntries = ({ onClickEntry }: { onClickEntry?: () => void }) => {
  const isPathActive = useIsPathActiveFn();
  const location = Nav.useRegionLocation('root');
  return (
    <SidebarGroup>
      <SidebarNavItem
        as={Link}
        to={makeLink(paths.pages.ControllersList, {})}
        label="Controllers"
        isSelected={location.pathname === '/' || isPathActive(paths.pages.ControllerDetails)}
        icon="controller"
        onClick={onClickEntry}
      />
      <SidebarNavItem
        as={Link}
        to={makeLink(paths.pages.CompaniesList, {})}
        label="Companies"
        isSelected={isPathActive(paths.pages.CompaniesList)}
        icon="company"
        onClick={onClickEntry}
      />
      <SidebarNavItem
        as={Link}
        to={makeLink(paths.pages.GlobalStats, {})}
        label="Statistics"
        isSelected={isPathActive(paths.pages.GlobalStats)}
        icon="reporting"
        onClick={onClickEntry}
      />
    </SidebarGroup>
  );
};

export const NOCDesktopSidebar = () => (
  <DesktopSidebar>
    <SidebarEntries />
  </DesktopSidebar>
);

export const NOCMobileSidebar = ({ onClose }: { onClose: () => void }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <MobileSidebarToggle icon="cross" label="Navigation" onClick={onClose} />
      </MobileSidebarToggleContainer>
      <SidebarEntries onClickEntry={onClose} />
    </MobileSidebar>
  </MobileSidebarModal>
);

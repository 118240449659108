import { match } from 'ts-pattern';

import type { CSS } from '../../stitches.config';
import { gradients } from '../../common/gradients';
import { darkThemeSelector } from '../../stitches.config';

export type GradientTypes =
  | 'control-destructive'
  | 'control-primary'
  | 'control-secondary'
  | 'none';

export const gradientCSS = (gradient?: GradientTypes): CSS =>
  match(gradient)
    .with('control-destructive', () => ({
      backgroundImage: gradients.controlDestructive,
    }))
    .with('control-primary', () => ({
      backgroundImage: gradients.controlPrimary,
    }))
    .with('control-secondary', () => ({
      backgroundImage: gradients.controlSecondary,
      [darkThemeSelector]: {
        backgroundImage: gradients.controlSecondaryDark,
      },
    }))
    .with('none', () => ({
      backgroundImage: 'none',
    }))
    .with(undefined, () => ({}))
    .exhaustive();

import { isDisplayableError } from './isDisplayableError';

export const getErrorMessage = (error: unknown) => {
  if (isDisplayableError(error)) {
    return error.displayMessage;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};

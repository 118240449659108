import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/noc/src/routes/drawers/companies/CompanySummary.page.tsx";
import PagefilesImport1 from "/vercel/path0/apps/noc/src/routes/drawers/companies/CreateCompany.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/noc/src/routes/drawers/companies/users/UerRemove.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/noc/src/routes/drawers/companies/users/UserDetail.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/noc/src/routes/drawers/companies/users/UserEdit.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/noc/src/routes/drawers/companies/users/UsersAdd.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/clients/ClientDetail.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/config/SSIDEdit.page.tsx";
import PagefilesImport8 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/devices/DeviceDetail.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/devices/DeviceEditLocation.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/floorplans/FloorPlanDetail.page.tsx";
import PagefilesImport11 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/incidents/IncidentCreate.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/incidents/IncidentDetail.page.tsx";
import PagefilesImport13 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/incidents/IncidentEditDetails.page.tsx";
import PagefilesImport14 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/incidents/IncidentEditDuration.page.tsx";
import PagefilesImport15 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/legacy/EditLegacyNetworkInfo.page.tsx";
import PagefilesImport16 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/legacy/LegacyNetworkInfo.page.tsx";
import PagefilesImport17 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/metadata/EditControllerMetadata.page.tsx";
import PagefilesImport18 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/plans/ISPCreate.page.tsx";
import PagefilesImport19 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/plans/ISPDelete.page.tsx";
import PagefilesImport20 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/plans/ISPDetails.page.tsx";
import PagefilesImport21 from "/vercel/path0/apps/noc/src/routes/drawers/controllers/plans/ISPEdit.page.tsx";

const routes = [
{path: "/companies/:companyName",element: React.createElement(PagefilesImport0)},
{path: "/companies/:companyName/add-users",element: React.createElement(PagefilesImport5)},
{path: "/companies/:companyName/users/:userSid",element: React.createElement(PagefilesImport3)},
{path: "/companies/:companyName/users/:userSid/edit",element: React.createElement(PagefilesImport4)},
{path: "/companies/:companyName/users/:userSid/remove",element: React.createElement(PagefilesImport2)},
{path: "/companies/new",element: React.createElement(PagefilesImport1)},
{path: "/controllers/:controllerName/clients/:mac",element: React.createElement(PagefilesImport6)},
{path: "/controllers/:controllerName/config/ssids/:eid",element: React.createElement(PagefilesImport7)},
{path: "/controllers/:controllerName/devices/:id",element: React.createElement(PagefilesImport8)},
{path: "/controllers/:controllerName/devices/:id/edit",element: React.createElement(PagefilesImport9)},
{path: "/controllers/:controllerName/floorplans",element: React.createElement(PagefilesImport10)},
{path: "/controllers/:controllerName/incidents/:id",element: React.createElement(PagefilesImport12)},
{path: "/controllers/:controllerName/incidents/:id/edit-details",element: React.createElement(PagefilesImport13)},
{path: "/controllers/:controllerName/incidents/:id/edit-duration",element: React.createElement(PagefilesImport14)},
{path: "/controllers/:controllerName/incidents/new",element: React.createElement(PagefilesImport11)},
{path: "/controllers/:controllerName/legacy-ssids",element: React.createElement(PagefilesImport16)},
{path: "/controllers/:controllerName/legacy-ssids/edit",element: React.createElement(PagefilesImport15)},
{path: "/controllers/:controllerName/metadata/edit",element: React.createElement(PagefilesImport17)},
{path: "/controllers/:controllerName/plans/:planId",element: React.createElement(PagefilesImport20)},
{path: "/controllers/:controllerName/plans/:planId/delete",element: React.createElement(PagefilesImport19)},
{path: "/controllers/:controllerName/plans/:planId/edit",element: React.createElement(PagefilesImport21)},
{path: "/controllers/:controllerName/plans/new",element: React.createElement(PagefilesImport18)},
];

export default routes;
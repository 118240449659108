import type { HTMLAttributes } from 'react';
import React from 'react';

import Icon from '../../assets/Icon/Icon';
import { FocusRingSelf } from '../../common/focus_rings';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Subheading2 } from '../../text/Subheading';

const Container = styled('button', FocusRingSelf, {
  hStack: '$8',
  padding: '$12 $20',
  width: '100%',
});

const StyledIcon = styled(Icon, {
  color: colors['gray-400'],
  [darkThemeSelector]: {
    color: colors['gray-600'],
  },
});

export interface MobileSidebarToggleProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  icon: 'cross' | 'menu';
}

export const MobileSidebarToggle: React.FC<MobileSidebarToggleProps> = ({
  label,
  icon = 'menu',
  ...props
}) => (
  <Container {...props}>
    <StyledIcon icon={icon} />
    <Subheading2>{label}</Subheading2>
  </Container>
);

export default MobileSidebarToggle;

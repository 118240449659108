import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';

import Stats from '../../components/Stats/Stats';

export const Meta: PagefileMetaFn = () => ({
  path: '/stats',
});

export default function GlobalStats() {
  return <Stats />;
}

import React from 'react';

export const DeviceIconMedium: React.FunctionComponent<{}> = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="xMidYMid meet"
    style={{
      filter: `
        drop-shadow(-1px 0 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(1px 0 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 -1px 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 1px 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 1.6px 1px rgba(9, 11, 20, 0.08))
      `,
    }}
  >
    <path
      d="M28 14C28 9.22452 27.6745 3.83676 25.9245 2.08163C24.1745 0.326529 17.907 0 14.0407 0C10.1744 0 3.907 0.326529 2.15697 2.08163C0.406975 3.83676 0 9.22452 0 14C0 18.7755 0.40698 24.1633 2.15698 25.9184C3.907 27.6735 10.1744 28 14.0407 28C17.907 28 24.1744 27.6735 25.9244 25.9184C27.6745 24.1633 28 18.7755 28 14Z"
      fill="white"
    />
    <path
      d="M28 14C28 9.22452 27.6745 3.83676 25.9245 2.08163C24.1745 0.326529 17.907 0 14.0407 0C10.1744 0 3.907 0.326529 2.15697 2.08163C0.406975 3.83676 0 9.22452 0 14C0 18.7755 0.40698 24.1633 2.15698 25.9184C3.907 27.6735 10.1744 28 14.0407 28C17.907 28 24.1744 27.6735 25.9244 25.9184C27.6745 24.1633 28 18.7755 28 14Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M15.5155 11.3243C15.5149 11.3162 15.5143 11.3081 15.5138 11.3H15.5027C15.4979 11.3039 15.4933 11.308 15.4887 11.3121C15.4788 11.3209 15.4688 11.3297 15.4578 11.3366C15.4195 11.3606 15.3814 11.3846 15.3432 11.4087C15.2087 11.4935 15.0742 11.5784 14.9375 11.6596C14.886 11.6902 14.8706 11.7237 14.8711 11.7813C14.8733 12.0266 14.873 12.2719 14.8727 12.5172V12.5181C14.8726 12.6038 14.8725 12.6895 14.8725 12.7752V12.8427H14.2108V13.4122H14.8725V13.4827C14.8725 13.6571 14.8722 13.8316 14.872 14.006C14.8712 14.4779 14.8705 14.9498 14.875 15.4216C14.8766 15.5859 14.8931 15.7533 14.9301 15.9132C15.0117 16.2654 15.2269 16.5132 15.5777 16.6361C15.7994 16.7137 16.0275 16.7119 16.2565 16.677C16.3847 16.6574 16.5077 16.6218 16.6213 16.5518L16.5903 16.4411C16.55 16.2967 16.5097 16.1526 16.4682 16.0044C16.3386 16.086 16.201 16.1221 16.055 16.1276C15.8085 16.137 15.6276 16.017 15.5598 15.7838C15.5314 15.6858 15.5206 15.5798 15.52 15.4774C15.5174 14.9537 15.5178 14.4301 15.5182 13.9065C15.5183 13.7619 15.5184 13.6174 15.5184 13.4729C15.5184 13.4669 15.5186 13.4609 15.5189 13.4547C15.5196 13.441 15.5208 13.4264 15.5217 13.4088H16.529V12.8385H15.5183V12.7688C15.5183 12.3046 15.5183 11.8404 15.5181 11.3761C15.5181 11.359 15.5168 11.3414 15.5155 11.3243Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.896 14.8658H17.1165C17.1178 14.873 17.119 14.8802 17.1203 14.8874C17.1264 14.924 17.1322 14.9602 17.1378 14.996C17.1526 15.0894 17.1669 15.1805 17.1884 15.2697C17.2427 15.4951 17.3454 15.6972 17.5082 15.8639C17.6564 16.0156 17.8396 16.0922 18.0475 16.1158C18.2221 16.1357 18.3937 16.1212 18.5627 16.0752C18.8099 16.0079 18.9767 15.8379 19.1099 15.628C19.1419 15.5777 19.1705 15.5252 19.2 15.4711C19.2133 15.4466 19.2269 15.4217 19.241 15.3966C19.4222 15.4761 19.6038 15.5557 19.7921 15.6382C19.7856 15.6512 19.7793 15.664 19.7731 15.6767C19.7708 15.6814 19.7684 15.6862 19.7661 15.691L19.7635 15.6962C19.7597 15.7039 19.756 15.7116 19.7521 15.7192C19.747 15.7295 19.7417 15.7397 19.7364 15.7498C19.5992 16.011 19.4304 16.2455 19.1898 16.4206C19.0007 16.5581 18.7871 16.6326 18.5601 16.6686C18.3123 16.708 18.0633 16.7149 17.8162 16.6625C17.4051 16.5756 17.0756 16.3611 16.8412 16.0062C16.632 15.6897 16.5229 15.3375 16.4917 14.9594C16.4547 14.5135 16.5164 14.0848 16.7109 13.6799C16.9201 13.2444 17.2437 12.94 17.7137 12.8196C18.2049 12.6939 18.6785 12.7437 19.1188 13.0104C19.4311 13.1997 19.6271 13.4863 19.759 13.8226C19.8577 14.074 19.895 14.3365 19.896 14.6055C19.8961 14.6278 19.8961 14.65 19.8962 14.6724C19.8962 14.7057 19.8961 14.7393 19.8961 14.7739C19.8961 14.8037 19.896 14.8343 19.896 14.8658ZM19.2258 14.2859C19.1497 13.836 18.8388 13.2866 18.1079 13.3361C17.8479 13.3536 17.6247 13.4511 17.4527 13.6534C17.2979 13.8355 17.2085 14.0485 17.1651 14.2859H19.2258Z"
      fill="#5461C8"
    />
    <path
      d="M22.4586 13.0078C22.4439 13.0653 22.4292 13.1229 22.4146 13.1804C22.4073 13.209 22.4001 13.2376 22.3929 13.2662C22.3831 13.3053 22.3739 13.3446 22.3644 13.3852C22.3614 13.3982 22.3583 13.4114 22.3552 13.4247L22.3496 13.4482C22.3337 13.4435 22.3214 13.4408 22.3099 13.4362C21.8627 13.2566 21.4997 13.3449 21.1909 13.7063C21.0004 13.9293 20.9023 14.1918 20.8371 14.4686C20.7824 14.7005 20.7569 14.9361 20.7563 15.1737C20.7556 15.5012 20.7557 15.8288 20.7559 16.1563C20.7559 16.3107 20.756 16.4651 20.7559 16.6194V16.6897H20.0909V12.828H20.7546V13.4702C20.7705 13.4481 20.7852 13.4269 20.7992 13.4064C20.8283 13.3643 20.8549 13.3256 20.8856 13.2903C21.1701 12.9627 21.5208 12.7573 21.9768 12.7607C22.1545 12.7621 22.3277 12.7937 22.4857 12.8796V12.9018L22.4586 13.0078Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3961 14.8659H11.6277C11.6411 15.1313 11.6942 15.3803 11.8214 15.6093C11.9011 15.7527 11.9992 15.8802 12.1377 15.9717C12.3288 16.0979 12.541 16.1345 12.7642 16.1231C13.0184 16.11 13.2521 16.0447 13.4362 15.8519C13.5491 15.7337 13.639 15.6 13.7097 15.4526C13.7133 15.445 13.717 15.4374 13.7209 15.4295L13.7258 15.4194C13.7296 15.4118 13.7336 15.4037 13.7378 15.3949C13.8324 15.4363 13.926 15.4774 14.0205 15.5188L14.2955 15.6394C14.2854 15.6583 14.2755 15.6771 14.2658 15.6959C14.2627 15.7017 14.2597 15.7075 14.2567 15.7133C14.2289 15.7668 14.2015 15.8194 14.1715 15.8703C14.0211 16.1249 13.8356 16.346 13.5763 16.4948C13.3911 16.601 13.1894 16.6561 12.9801 16.681C12.7621 16.7069 12.5433 16.7105 12.3268 16.6653C11.8807 16.5719 11.5343 16.3329 11.2972 15.9369C11.12 15.6411 11.0229 15.3191 10.9929 14.9756C10.9535 14.5244 11.0136 14.09 11.211 13.6797C11.4206 13.2442 11.7443 12.9397 12.2146 12.8195C12.708 12.6935 13.1834 12.7441 13.6249 13.0135C13.9351 13.2027 14.13 13.4884 14.2608 13.8229C14.3577 14.0706 14.3975 14.3293 14.3961 14.5949C14.3957 14.6823 14.3961 14.7698 14.3961 14.8659ZM13.7303 14.2868C13.6143 13.7071 13.2514 13.2865 12.5884 13.3373C12.3329 13.3568 12.1142 13.4595 11.9468 13.6603C11.7961 13.8413 11.7079 14.0513 11.665 14.2868H13.7303Z"
      fill="#5461C8"
    />
    <path
      d="M5.5143 16.7H6.15324V16.6306C6.15324 16.4883 6.15291 16.3461 6.15258 16.2038C6.15174 15.8462 6.15091 15.4887 6.15534 15.1311C6.15776 14.9293 6.17309 14.7266 6.19966 14.5266C6.23537 14.2575 6.32275 14.0036 6.47545 13.7763C6.62573 13.5526 6.81466 13.3829 7.09475 13.3515C7.41611 13.3155 7.68518 13.4422 7.76363 13.7959C7.7923 13.9249 7.80112 14.0605 7.80165 14.1932C7.804 14.779 7.80377 15.3649 7.80355 15.9507C7.80346 16.1751 7.80337 16.3994 7.80343 16.6238C7.80343 16.6408 7.80456 16.6579 7.80567 16.6749C7.80619 16.6827 7.80671 16.6906 7.80711 16.6984H8.44868V16.6346C8.44868 16.4881 8.44835 16.3415 8.44801 16.195C8.44718 15.8268 8.44635 15.4587 8.45078 15.0906C8.45309 14.8945 8.46832 14.6971 8.49699 14.5031C8.54446 14.1828 8.65798 13.8869 8.87233 13.6374C9.0475 13.4335 9.267 13.3288 9.53722 13.3475C9.75986 13.3629 9.9259 13.4664 10.0168 13.6775C10.0807 13.8255 10.0932 13.9825 10.0933 14.1409C10.0938 14.7497 10.0938 15.3585 10.0937 15.9674C10.0936 16.1891 10.0936 16.4109 10.0936 16.6327V16.6993H10.7337C10.7347 16.6805 10.7362 16.6643 10.7362 16.648C10.7363 16.4401 10.7364 16.2322 10.7365 16.0243C10.7369 15.3384 10.7373 14.6525 10.7342 13.9667C10.7336 13.8286 10.7179 13.6878 10.6881 13.5531C10.6233 13.2591 10.4713 13.0197 10.197 12.8803C9.88935 12.7242 9.56599 12.7228 9.24253 12.8206C8.87895 12.9305 8.62238 13.1767 8.42967 13.4971C8.41812 13.5164 8.40646 13.5356 8.39039 13.5621C8.38517 13.5438 8.38133 13.53 8.37786 13.5179C8.37575 13.5106 8.37377 13.5039 8.3717 13.4972C8.2777 13.1919 8.0978 12.9604 7.79713 12.8389C7.56398 12.7447 7.32139 12.7353 7.07795 12.787C6.67635 12.8724 6.3909 13.1149 6.1901 13.4671C6.18452 13.4769 6.17897 13.4866 6.17344 13.4964C6.16971 13.503 6.16599 13.5096 6.16227 13.5162C6.1604 13.5155 6.15852 13.5147 6.15663 13.514C6.15492 13.5133 6.1532 13.5126 6.15146 13.5119V12.8278H5.5143V16.7Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.625 4.30911C17.0116 4.30911 17.325 3.99571 17.325 3.60912C17.325 3.22252 17.0116 2.90912 16.625 2.90912C16.2384 2.90912 15.925 3.22252 15.925 3.60912C15.925 3.99571 16.2384 4.30911 16.625 4.30911ZM11.375 4.30912C11.7616 4.30912 12.075 3.99572 12.075 3.60912C12.075 3.22252 11.7616 2.90912 11.375 2.90912C10.9884 2.90912 10.675 3.22252 10.675 3.60912C10.675 3.99572 10.9884 4.30912 11.375 4.30912Z"
      fill="#33C44A"
    />
    <path
      d="M9.44998 3.60912C9.44998 3.99572 9.13658 4.30912 8.74998 4.30912C8.36339 4.30912 8.04999 3.99572 8.04999 3.60912C8.04999 3.22253 8.36339 2.90913 8.74998 2.90913C9.13658 2.90913 9.44998 3.22253 9.44998 3.60912Z"
      fill="#6977E1"
    />
    <path
      d="M14.7 3.60912C14.7 3.99572 14.3866 4.30912 14 4.30912C13.6134 4.30912 13.3 3.99572 13.3 3.60912C13.3 3.22253 13.6134 2.90913 14 2.90913C14.3866 2.90913 14.7 3.22253 14.7 3.60912Z"
      fill="#6977E1"
    />
    <path
      d="M19.2499 4.30912C19.6365 4.30912 19.9499 3.99572 19.9499 3.60912C19.9499 3.22253 19.6365 2.90913 19.2499 2.90913C18.8633 2.90913 18.5499 3.22253 18.5499 3.60912C18.5499 3.99572 18.8633 4.30912 19.2499 4.30912Z"
      fill="#6977E1"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="25.5378"
        y1="1.06122"
        x2="10.6714"
        y2="31.4468"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.03" />
        <stop offset="0.500255" stopColor="#E1E4EC" stopOpacity="0.11" />
        <stop offset="1" stopColor="#D4DAE5" stopOpacity="0.56" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeviceIconMedium;

import * as d3 from 'd3';
import React from 'react';
import { useMeter } from 'react-aria';
import useMeasure from 'react-use-measure';

import { colors, styled } from '../../stitches.config';
import { circularArcPathData } from './arcs';

const Track = styled('path', {
  stroke: colors['gray-100'],
  strokeLinecap: 'round',
  fill: 'none',
});

const Progress = styled('path', {
  strokeLinecap: 'round',
  fill: 'none',
  variants: {
    color: {
      neutral: {
        stroke: colors['brand-600'],
      },
      positive: {
        stroke: colors['green-600'],
      },
    },
  },
});

const RadialGaugeSVG = React.forwardRef<
  SVGSVGElement,
  {
    minValue: number;
    maxValue: number;
    value: number;
    width?: number;
    variant?: 'neutral' | 'positive';
    strokeWidth?: number;
  }
>(
  // eslint-disable-next-line react/prop-types
  ({ minValue, maxValue, value, variant = 'neutral', width = 100, strokeWidth = 8 }, ref) => {
    const scale = d3.scaleLinear([minValue, maxValue], [0, Math.PI]).clamp(true);
    const height = width / 2 + strokeWidth / 2;
    const radius = width / 2 - strokeWidth / 2;
    return (
      <svg width={width} height={height} ref={ref}>
        <Track
          d={circularArcPathData(width / 2, height - strokeWidth / 2, radius, 0, Math.PI)}
          style={{ strokeWidth }}
        />
        <Progress
          d={circularArcPathData(width / 2, height - strokeWidth / 2, radius, 0, scale(value))}
          style={{ strokeWidth }}
          color={variant}
        />
      </svg>
    );
  },
);

export interface RadialGaugeProps {
  minValue: number;
  maxValue: number;
  value: number;

  /**
   * Describes what information this indicator is displaying
   */
  'aria-label'?: string;

  /**
   * Describes the quantity this indicator is displaying
   */
  valueLabel?: string;

  variant?: 'neutral' | 'positive';
  strokeWidth?: number;
  children?: React.ReactNode;
}

export const RadialGauge: React.FunctionComponent<RadialGaugeProps> = (props) => {
  const { value, children, minValue, maxValue } = props;
  const { meterProps } = useMeter({ ...props, showValueLabel: true });

  const [boxRef, { width }] = useMeasure();
  const [svgRef, { height }] = useMeasure();

  return (
    <div {...meterProps} ref={boxRef} style={{ flex: 1, height, position: 'relative' }}>
      <div style={{ position: 'absolute', inset: 0 }}>
        <RadialGaugeSVG
          ref={svgRef}
          width={width}
          minValue={minValue}
          maxValue={maxValue}
          value={value}
        />
      </div>
      {children && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            // NOTE: 10% chosen by eyeballing it
            paddingTop: '10%',
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default RadialGauge;

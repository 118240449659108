import { getErrorMessage, isDefined } from '@meterup/common';
import { Alert, Body2, BodyMono2, Button, space, TextInput, VStack } from '@meterup/metric';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { deleteAccessPoint } from '../../api/devices_api';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { fontWeights } from '../../stitches';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogCloseButton,
  DialogContent,
  DialogControls,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
} from '../Dialog/Dialog';

export function DeleteDeviceDialog({
  isOpen,
  onOpenChange,
  controllerName,
  deviceId,
}: {
  controllerName: string;
  deviceId: string;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
}) {
  const [confirmDeleteString, setConfirmDeleteString] = useState<string>('');

  const resetState = () => {
    setConfirmDeleteString('');
  };

  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();

  const doesConfirmDeleteStringMatch = confirmDeleteString === deviceId;

  const deleteAccessPointMutation = useMutation(
    async () => {
      await deleteAccessPoint(controllerName, deviceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        resetState();
        onOpenChange(false);
        closeDrawer();
      },
    },
  );

  const actionDelete = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (doesConfirmDeleteStringMatch) {
      deleteAccessPointMutation.mutate();
    }
  };

  const errorMessage = isDefined(deleteAccessPointMutation.error)
    ? getErrorMessage(deleteAccessPointMutation.error)
    : null;

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      resetState();
      deleteAccessPointMutation.reset();
    }
    onOpenChange(value);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent>
        <form onSubmit={actionDelete}>
          <DialogHeader>
            <DialogTitle>Remove access point</DialogTitle>
            <DialogControls>
              <DialogCloseButton />
            </DialogControls>
          </DialogHeader>
          <DialogBody>
            {isDefined(errorMessage) && (
              <Alert
                icon="warning"
                variant="negative"
                heading="Upload failed"
                copy={errorMessage}
                cornerStyle="square"
              />
            )}

            <DialogSection>
              <VStack align="stretch" spacing={space(20)}>
                <VStack align="stretch" spacing={space(8)}>
                  <Body2 style={{ fontWeight: fontWeights.medium.toString() }}>
                    Are you sure you want to remove: {deviceId}?
                  </Body2>
                  <Body2 as="p">
                    This action cannot be undone. This will permanently remove the device from the
                    network.
                  </Body2>
                  <Body2 as="p">
                    Please type{' '}
                    <BodyMono2 as="code" style={{ fontWeight: 600 }}>
                      {deviceId}
                    </BodyMono2>{' '}
                    to confirm.
                  </Body2>
                </VStack>
                <TextInput
                  controlSize="large"
                  aria-label="Confirm device removal"
                  onChange={(value) => setConfirmDeleteString(value)}
                />
              </VStack>
            </DialogSection>
          </DialogBody>
          <DialogFooter>
            <DialogControls>
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button
                variant="destructive"
                disabled={deleteAccessPointMutation.isLoading || !doesConfirmDeleteStringMatch}
              >
                Remove
              </Button>
            </DialogControls>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

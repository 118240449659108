import type { CSS } from '../../stitches.config';
import type { SizingScale } from './sizes';

export type WidthTypes = SizingScale;
export type WidthProps = { width?: WidthTypes };

export const widthCSS = (width?: WidthTypes): CSS => {
  if (
    width === 'auto' ||
    width === 'fit-content' ||
    width === 'max-content' ||
    width === 'min-content'
  ) {
    return { width };
  }

  if (width === 'full') {
    return { width: '100%' };
  }

  if (width === 'screen') {
    return { width: '100vw' };
  }

  if (width != null) {
    return {
      width: `$${width}`,
    };
  }

  return {};
};

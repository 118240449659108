import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { Alert } from '@meterup/metric';
import Editor from '@monaco-editor/react';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchControllerJSON, fetchControllerState } from '../../../api/controllers_api';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { styled } from '../../../stitches';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/state',
});

const Container = styled('div', { display: 'flex', flexDirection: 'column', height: '100%' });

const EditorContainer = styled('div', {
  height: '100%',
  flex: 1,
  flexShrink: 0,
  overflow: 'hidden',
});

export default function ControllerState() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerState),
  );

  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  return (
    <Container>
      {isDefinedAndNotEmpty(networkInfo.mboot_version) && (
        <Alert
          icon="attention"
          variant="negative"
          heading="Legacy controller"
          copy="This looks like a legacy controller. It may not report state to the backend."
          cornerStyle="square"
        />
      )}
      <EditorContainer>
        <Editor
          language="json"
          value={JSON.stringify(stateData?.state ?? {}, null, 2)}
          options={{ minimap: { enabled: false }, readOnly: true }}
        />
      </EditorContainer>
    </Container>
  );
}

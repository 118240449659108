import React from 'react';

import type { TextComponent, TextProps } from '../utilities/Text/Text';
import { colors, darkThemeSelector, fonts, fontWeights, styled } from '../stitches.config';
import Text, { Text2 } from '../utilities/Text/Text';

export type HeadingProps<Tag extends React.ElementType = 'h2'> = TextProps<Tag>;

export const Heading: TextComponent = <Tag extends React.ElementType = 'h2'>({
  as,
  children,
  color = {
    light: 'gray-800',
    dark: 'white',
  },
  family = 'regular',
  weight = 'medium',
  ...remaining
}: HeadingProps<Tag>) => (
  <Text
    {...remaining}
    as={(as ?? 'h2') as 'h2'}
    color={{ light: color.light, dark: color.dark }}
    family={family}
    lineHeight={28}
    size={20}
    weight={weight}
  >
    {children}
  </Text>
);

export const Heading2 = styled('h2', Text2, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.medium,
  fontSize: '$20',
  lineHeight: '$28',
  color: colors['gray-800'],
  [darkThemeSelector]: {
    color: colors.white,
  },
});

export default Heading;

import type { api } from '@meterup/proto';
import {
  Badge,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
} from '@meterup/metric';
import React from 'react';

import { isDefined } from '../helpers/isDefined';
import { DisabledBadge, EnabledBadge } from './badges';

export interface RadioDetailsProps {
  title: string;
  radio?: api.AccessPointRadioResponse | null;
}

export const EnabledRadioDetailsContent = ({ radio }: { radio: api.AccessPointRadioResponse }) => (
  <>
    <MinListItemPair>
      <MinListItemLabel>Status</MinListItemLabel>
      <MinListItemValue>
        <EnabledBadge arrangement="leading-icon" icon="checkmarkCircle" />
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Channel</MinListItemLabel>
      <MinListItemValue>
        <Badge size="small">{radio.channel}</Badge>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Width</MinListItemLabel>
      <MinListItemValue>
        <Badge size="small">{radio.channel_width}</Badge>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Power</MinListItemLabel>
      <MinListItemValue>
        <Badge size="small">{radio.power}</Badge>
      </MinListItemValue>
    </MinListItemPair>
  </>
);

export const DisabledRadioDetailsContent = () => (
  <MinListItemPair>
    <MinListItemLabel>Status</MinListItemLabel>
    <MinListItemValue>
      <DisabledBadge arrangement="leading-icon" icon="crossCircle" />
    </MinListItemValue>
  </MinListItemPair>
);

export const WirelessBandDetails = ({ title, radio }: RadioDetailsProps) => (
  <MinList>
    <MinListItemHeader icon="wifi">
      <MinListTitle>{title}</MinListTitle>
    </MinListItemHeader>
    {isDefined(radio) && !radio.disabled ? (
      <EnabledRadioDetailsContent radio={radio} />
    ) : (
      <DisabledRadioDetailsContent />
    )}
  </MinList>
);

import type { ComboBoxProps as AriaComboBoxProps } from '@react-types/combobox';
import * as React from 'react';
import {
  mergeProps,
  OverlayContainer,
  useComboBox,
  useFilter,
  useOverlayPosition,
} from 'react-aria';
import { mergeRefs } from 'react-merge-refs';
import { useComboBoxState } from 'react-stately';
import useMeasure from 'react-use-measure';

import type { IconName } from '../../assets/Icon/Icon';
import Icon from '../../assets/Icon/Icon';
import { styled } from '../../stitches.config';
import { BaseInput } from '../BaseInput/BaseInput';
import { ListBox } from './ListBox';
import { Popover } from './Popover';

const iconColor = '$$iconColor';
const iconSize = '$$iconSize';

const StyledIcon = styled(Icon, {
  width: iconSize,
  height: iconSize,
  color: iconColor,
});

type Placement =
  | 'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'bottom start'
  | 'bottom end'
  | 'top'
  | 'top left'
  | 'top right'
  | 'top start'
  | 'top end'
  | 'left'
  | 'left top'
  | 'left bottom'
  | 'start'
  | 'start top'
  | 'start bottom'
  | 'right'
  | 'right top'
  | 'right bottom'
  | 'end'
  | 'end top'
  | 'end bottom';

interface ComboBoxProps<T> extends AriaComboBoxProps<T> {
  icon?: IconName;
  placement?: Placement;
  hasError?: boolean;
}

const ComboBoxInner = <T extends object>(
  props: ComboBoxProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({ ...props, defaultFilter: contains });
  const [measureRef, { width: inputWidth }] = useMeasure();

  const inputRef = React.useRef(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: inputRef,
    overlayRef: popoverRef,
    placement: props.placement ?? 'bottom start',
    offset: 4,
    isOpen: state.isOpen,
  });

  const {
    inputProps: { defaultValue, ...inputProps },
    listBoxProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  return (
    <div ref={ref}>
      <BaseInput
        inputRef={mergeRefs([inputRef, measureRef])}
        inputProps={mergeProps(inputProps, {
          onClick: () => state.open(),
        })}
        icon={props.icon}
        disabled={props.isDisabled}
        hasError={props.hasError}
        suffix={<StyledIcon icon="chevronsVertical" />}
      />
      {state.isOpen && (
        <OverlayContainer>
          <Popover
            {...positionProps}
            popoverRef={popoverRef}
            isOpen={state.isOpen}
            onClose={state.close}
            style={{ ...positionProps.style, minWidth: inputWidth, maxWidth: '400px' }}
          >
            <ListBox
              {...listBoxProps}
              shouldUseVirtualFocus
              listBoxRef={listBoxRef}
              state={state}
            />
          </Popover>
        </OverlayContainer>
      )}
    </div>
  );
};

export const ComboBox = React.forwardRef(ComboBoxInner) as <T extends object>(
  props: ComboBoxProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof ComboBoxInner>;

export { Item as ComboBoxItem, Section as ComboBoxSection } from 'react-stately';
export type { ComboBoxProps };

import { useEffect, useRef } from 'react';

const useDocumentTitle = (
  title: string,
  controllerName: string,
  networkInfo?: any,
  concatTitle = true,
  retainOnUnmount = false,
): void => {
  const defaultTitle = useRef(document.title);
  const titleGlobal: string = 'Meter NOC';

  useEffect(() => {
    document.title = concatTitle ? titleGlobal.concat(title) : title;
  }, [title, titleGlobal, concatTitle]);

  useEffect(() => {
    if (controllerName && networkInfo) {
      let newConcatTitle = '';
      if (title) {
        newConcatTitle = ` - ${title} - ${networkInfo.companySlug || ''} (${controllerName})`;
      } else {
        newConcatTitle = ` - ${networkInfo.companySlug || ''} (${controllerName})`;
      }

      document.title = titleGlobal.concat(newConcatTitle);
    }
  }, [title, titleGlobal, controllerName, networkInfo]);

  useEffect(() => {
    const element: string = defaultTitle.current;

    return () => {
      if (!retainOnUnmount) {
        document.title = element;
      }
    };
  }, [retainOnUnmount]);
};

export default useDocumentTitle;

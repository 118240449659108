import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  Skeleton,
} from '@meterup/metric';
import React from 'react';

import { styled } from '../../stitches';

const Container = styled('div', { vStack: '$16', alignItems: 'stretch', height: '100%' });

export interface LoadingDrawerProps {
  title?: React.ReactNode;
}

export const LoadingDrawer = ({
  title = <Skeleton height="28px" width="200px" radius={4} />,
}: LoadingDrawerProps) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>{title}</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <Container>
        <Skeleton width="100%" height="100%" radius={4} />
      </Container>
    </DrawerContent>
  </Drawer>
);

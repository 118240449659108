import { isDisplayableError } from './isDisplayableError';

export const getErrorTitle = (error: unknown) => {
  if (isDisplayableError(error)) {
    return error.displayTitle;
  }

  if (error instanceof Error) {
    return error.name;
  }

  return 'Unknown error';
};

import React from 'react';

import { Background, Glyph } from '../common';

export const ProductIconNetwork = () => (
  <>
    <Background as="rect" width="16" height="16" rx="4" />
    <Glyph
      d="M12.6725 6.40461C12.8491 6.2166 12.8435 5.90863 12.6492 5.74154C11.3829 4.65228 9.77127 4 8.01654 4C6.24575 4 4.62069 4.66428 3.34919 5.77155C3.15664 5.93923 3.15187 6.24575 3.32776 6.43302L3.64065 6.76615C3.80624 6.94245 4.07175 6.94757 4.25531 6.79274C5.28882 5.92097 6.59558 5.40042 8.01654 5.40042C9.42341 5.40042 10.7183 5.9107 11.7469 6.7669C11.9305 6.9197 12.1944 6.91366 12.3592 6.73824L12.6725 6.40461Z"
      fill="#F5F6FA"
    />
    <Glyph
      d="M8.91434 9.66877C9.15936 9.78303 9.18248 10.1204 8.99333 10.3217L8.29685 11.0633C8.12563 11.2456 7.84802 11.2456 7.6768 11.0633L6.99921 10.3419C6.81242 10.143 6.83196 9.81041 7.07139 9.69158C7.35856 9.54906 7.67884 9.46948 8.01657 9.46948C8.33583 9.46948 8.63949 9.5406 8.91434 9.66877Z"
      fill="#F5F6FA"
    />
    <Glyph
      d="M8.01651 6.78211C9.04571 6.78211 9.99867 7.12959 10.7776 7.72037C10.9844 7.87723 10.9935 8.19214 10.814 8.3833L10.4949 8.72304C10.335 8.89328 10.0812 8.90319 9.89151 8.77212C9.35177 8.39914 8.7079 8.18252 8.01651 8.18252C7.31175 8.18252 6.65636 8.4076 6.11031 8.79393C5.92026 8.92839 5.66362 8.91999 5.50227 8.74821L5.18446 8.40984C5.00599 8.21983 5.01375 7.90718 5.21789 7.74914C6.00362 7.14089 6.97071 6.78211 8.01651 6.78211Z"
      fill="#F5F6FA"
    />
  </>
);

export default ProductIconNetwork;

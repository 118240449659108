import { styled } from '../stitches';

/**
 * Establishes a stacking context for the relationship between
 * OverlayableContainer and ModalContainer in this file.
 *
 * This CSS is meant to be applied to the top-level #root element.
 */
export const overlayableRootCSS = {
  position: 'relative',
};

/**
 * This component renders content that can have an overlay rendered over it. Not
 * to be confused with a container that actually renders the overlay! Its
 * z-index is set with relation to the ModalContainer to place the content below
 * that container.
 *
 * Expected to be rendered at the top level of the component tree as a
 * descendent of the #root element.
 */
export const OverlayableContainer = styled('div', {
  height: '100%',
  position: 'relative',
  zIndex: 1,
});

/**
 * This component can render a modal above the OverlayableContainer.
 *
 * Expected to be rendered (probably through a Portal) at the top level of
 * the component tree as a descendent of the #root element.
 */
export const ModalContainer = styled('div', {
  position: 'absolute',
  inset: 0,
  zIndex: 2,
});

import type { AriaLabelingProps } from '@react-types/shared';
import React from 'react';
import { useHover, useLabel } from 'react-aria';

import type { ColorProps } from '../../utilities/shared/Color';
import Body from '../../text/Body';
import Box from '../../utilities/Box/Box';
import { space } from '../../utilities/shared/sizes';
import HStack from '../../utilities/Stack/HStack';

export interface LabelProps extends AriaLabelingProps {
  /**
   * Displayed as the label of the component.
   */
  children: React.ReactNode;
  /**
   * Set the component to disabled.
   */
  disabled?: boolean;
  /**
   * Set the for property in HTMl.
   */
  htmlFor?: string;
  /**
   * Set the component to invalid.
   */
  invalid?: boolean;
}

const color = (disabled?: boolean, interactedWith?: boolean, invalid?: boolean): ColorProps => {
  if (disabled) {
    return { light: 'gray-500', dark: 'gray-300' };
  }
  if (invalid && interactedWith) {
    return { light: 'red-700', dark: 'red-200' };
  }
  if (invalid) {
    return { light: 'red-600', dark: 'red-300' };
  }
  if (interactedWith) {
    return { light: 'gray-700', dark: 'white' };
  }
  return { light: 'gray-600', dark: 'gray-50' };
};

const cursor = (disabled?: boolean, htmlFor?: string) => {
  if (htmlFor && disabled) {
    return 'not-allowed';
  }
  if (htmlFor) {
    return 'pointer';
  }
  return 'default';
};

export const Label: React.FunctionComponent<LabelProps> = ({
  children,
  disabled,
  htmlFor,
  invalid,
  ...rest
}) => {
  const { fieldProps, labelProps } = useLabel(rest);
  const { hoverProps, isHovered } = useHover({});
  const interactedWith = !!htmlFor && isHovered;
  return (
    <Box
      {...hoverProps}
      as="label"
      htmlFor={htmlFor}
      display="block"
      width="full"
      cursor={cursor(disabled, htmlFor)}
      {...fieldProps}
      {...labelProps}
    >
      <Body color={color(disabled, interactedWith, invalid)} weight="medium">
        <HStack width="full" align="center" spacing={space(8)}>
          {children}
        </HStack>
      </Body>
    </Box>
  );
};

export default Label;

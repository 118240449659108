import type { api } from '@meterup/proto';
import { isDefined } from '@meterup/common';
import { Body2, CopyCapsule, Heading2 } from '@meterup/metric';
import React from 'react';

import { UserProfilePicture } from '../../../../../components/UserProfilePicture';
import { styled } from '../../../../../stitches';
import { formatFullName } from '../../../../../utils/users';

const UserHeading = styled('div', {
  vStack: '$8',
  textAlign: 'center',
});

const UserNameAndEmail = styled('div', {
  vStack: 0,
});

export const UserNameAndEmailWidget = ({ user }: { user: api.UserResponse }) => {
  const formattedName = formatFullName(user);
  return (
    <UserHeading>
      <UserProfilePicture email={user.email} size="large" />
      {isDefined(formattedName) ? (
        <UserNameAndEmail>
          <Heading2>
            <CopyCapsule
              aria-label="Copy user's name"
              textValue={formattedName}
              arrangement="leading-icon"
            >
              {formattedName}
            </CopyCapsule>
          </Heading2>
          <Body2>
            <CopyCapsule
              aria-label="Copy user's email"
              textValue={user.email}
              arrangement="leading-icon"
            >
              {user.email}
            </CopyCapsule>
          </Body2>
        </UserNameAndEmail>
      ) : (
        <Heading2>
          <CopyCapsule
            aria-label="Copy user's email"
            textValue={user.email}
            arrangement="leading-icon"
          >
            {user.email}
          </CopyCapsule>
        </Heading2>
      )}
    </UserHeading>
  );
};

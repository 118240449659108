// (col, row): [m00, m01, m10, m11]
type Mat2 = [number, number, number, number];
type Vec2 = [number, number];

function mat2DotVec2([m00, m01, m10, m11]: Mat2, [vx, vy]: Vec2): Vec2 {
  return [m00 * vx + m01 * vy, m10 * vx + m11 * vy];
}

function vec2Add([ux, uy]: Vec2, [vx, vy]: Vec2) {
  return [ux + vx, uy + vy];
}

function radToDeg(rad: number) {
  return (rad * 180) / Math.PI;
}

interface SVGArcParameters {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  rx: number;
  ry: number;
  xAxisRotationRad: number;
  largeArcFlag: boolean;
  sweepFlag: boolean;
}

/**
 * Converts elliptical arc parameters from center to endpoint parameterizations. Useful for
 * constructing arcs in SVG path data where endpoint parameters are expected.
 *
 * Reference: https://www.w3.org/TR/SVG2/implnote.html#ArcConversionCenterToEndpoint
 */
const centerToEndpointParameterization = (
  cx: number,
  cy: number,
  rx: number,
  ry: number,
  xAxisRotationRad: number,
  startAngleRad: number,
  endAngleRad: number,
) => {
  const phi = xAxisRotationRad;
  const rotation: Mat2 = [Math.cos(phi), Math.sin(phi), -Math.sin(phi), Math.cos(phi)];
  const center: Vec2 = [cx, cy];

  const [x1, y1] = vec2Add(
    mat2DotVec2(rotation, [rx * Math.cos(startAngleRad), ry * Math.sin(startAngleRad)]),
    center,
  );

  const [x2, y2] = vec2Add(
    mat2DotVec2(rotation, [rx * Math.cos(endAngleRad), ry * Math.sin(endAngleRad)]),
    center,
  );

  const deltaAngle = endAngleRad - startAngleRad;
  const largeArcFlag = Math.abs(deltaAngle) > 180;
  const sweepFlag = deltaAngle > 0;

  return { x1, y1, x2, y2, rx, ry, xAxisRotationRad, largeArcFlag, sweepFlag } as SVGArcParameters;
};

const svgArcParametersToPathData = ({
  x1,
  y1,
  x2,
  y2,
  rx,
  ry,
  xAxisRotationRad,
  largeArcFlag,
  sweepFlag,
}: SVGArcParameters): string =>
  [
    `M ${x1} ${y1}`,
    `A ${rx} ${ry} ${radToDeg(xAxisRotationRad)} ${largeArcFlag ? 1 : 0} ${
      sweepFlag ? 1 : 0
    } ${x2} ${y2}`,
  ].join(' ');

/**
 * Constructs path data for a circular arc centered at cx, cy
 * By default the arc is rotated by -PI such that a startAngle of 0 points leftward
 */
export const circularArcPathData = (
  cx: number,
  cy: number,
  radius: number,
  startAngleRad: number,
  endAngleRad: number,
  xAxisRotationRad: number = -Math.PI,
) =>
  svgArcParametersToPathData(
    centerToEndpointParameterization(
      cx,
      cy,
      radius,
      radius,
      xAxisRotationRad,
      startAngleRad,
      endAngleRad,
    ),
  );

import type React from 'react';

import { styled } from '../../stitches.config';
import ListItem from './ListItem';

export const ListItemPair = styled('div', ListItem);

export type ListItemPairProps = React.ComponentProps<typeof ListItemPair>;

export default ListItemPair;

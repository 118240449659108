import type React from 'react';

import { darkThemeSelector, shadows, styled } from '../../stitches.config';
import ListItem from './ListItem';

export type ListProps = {
  children?: React.ReactNode;
};

export const List = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadiusTop: '$8',
  borderRadiusBottom: '$8',
  position: 'relative',
  boxShadow: shadows.fenceAllLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceAllDark,
  },
  [`& ${ListItem}:first-child`]: {
    borderRadiusTop: '$8',
  },
  [`& ${ListItem}:last-child`]: {
    borderRadiusBottom: '$8',
  },
});

export default List;

import React from 'react';

import { styled } from '../../../stitches.config';
import List from '../../List/List';

export const Base = styled('div', List);

const FieldContainerContext = React.createContext(false);

export function useFieldContainerContext() {
  return React.useContext(FieldContainerContext);
}

export const FieldContainer = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => (
  <FieldContainerContext.Provider value>
    <Base {...props} ref={ref} />
  </FieldContainerContext.Provider>
));

export const MaybeFieldContainer = ({ children }: { children: React.ReactNode }) => {
  const isInsideFieldContainer = useFieldContainerContext();
  const WrapperEl = isInsideFieldContainer ? React.Fragment : FieldContainer;
  return <WrapperEl>{children}</WrapperEl>;
};

import cx from 'classnames';
import React from 'react';

import type { PaddingProps } from '../../utilities/shared/Padding';
import Box from '../../utilities/Box/Box';
import { space } from '../../utilities/shared/sizes';

export type TextareaSize = 'small' | 'medium' | 'large' | 'x-large';

export interface TextareaProps {
  disabled?: boolean;
  hasError?: boolean;
  name?: string;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  placeholder?: string;
  rows?: number;
  size?: TextareaSize;
  value?: string;
}

const minHeight = (size: TextareaSize) => {
  // Small
  if (size === 'small') {
    return space(20);
  }
  // Large
  if (size === 'large') {
    return space(36);
  }
  // Extra large
  if (size === 'x-large') {
    return space(44);
  }
  // Medium, default
  return space(28);
};

const padding = (size: TextareaSize): PaddingProps => {
  if (size === 'small') {
    return { x: space(4), y: space(2) };
  }
  if (size === 'large') {
    return { x: space(12), y: space(8) };
  }
  if (size === 'x-large') {
    return { x: space(14), y: space(10) };
  }
  return { x: space(8), y: space(4) };
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      disabled = false,
      hasError = false,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      rows = 3,
      size = 'medium',
      value,
    },
    ref,
  ) => {
    const classes = cx(
      'm-transition-all m-duration-150 m-ease-linear',
      'm-outline-none',

      // Shadow
      'm-shadow-fieldInitialLight dark:m-shadow-fieldInitialDark',
      {
        'hover:m-shadow-fieldHoveredLight dark:hover:m-shadow-fieldHoveredDark': !disabled,
        'focus:m-shadow-fieldFocusedLight dark:focus:m-shadow-fieldFocusedDark': !disabled,
        'm-shadow-fieldErrorLight dark:m-shadow-fieldErrorDark': !disabled && hasError,
        'm-shadow-fieldDisabledLight dark:m-shadow-fieldDisabledDark': disabled,
      },

      // Text
      'm-font-sans',
      'm-text-gray-700 dark:m-text-white',
      'm-placeholder-gray-500 dark:m-placeholder-gray-200',
      {
        'm-leading-16 m-text-12': size === 'small',
        'm-leading-20 m-text-14': size === 'medium' || size === 'large',
        'm-leading-24 m-text-16': size === 'x-large',
      },
    );

    const handleOnBlur = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        event.preventDefault();
        onBlur(event);
      }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        event.preventDefault();
        onChange(event);
      }
    };

    const handleOnFocus = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onFocus) {
        event.preventDefault();
        onFocus(event);
      }
    };

    return (
      <Box
        ref={ref}
        as="textarea"
        className={classes}
        name={name}
        id={name}
        rows={rows}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        position="relative"
        display="flex"
        width="full"
        minHeight={minHeight(size)}
        padding={padding(size)}
        overflow={{ all: 'hidden' }}
        bg={{ light: 'white', dark: 'gray-700' }}
        cursor={disabled ? 'not-allowed' : 'text'}
        opacity={disabled ? 50 : 100}
        radius={{ all: size === 'small' ? 6 : 8 }}
      />
    );
  },
);

export default Textarea;

import { darkThemeSelector, shadows, styled } from '../../stitches.config';

const SidebarGroup = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
  padding: '$12',
  boxShadow: shadows.fenceTopLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceTopDark,
  },
});

export default SidebarGroup;

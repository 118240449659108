import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';

export const ListTitle = styled('div', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-800'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
});

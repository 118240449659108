import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { FocusRingSelf } from '../../common/focus_rings';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { space } from '../../utilities/shared/sizes';

const CloseButtonContainer = styled('button', FocusRingSelf, {
  borderRadius: 100,
  height: '$20',
  width: '$20',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: shadows.fenceAllLight,
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-200'],
    boxShadow: shadows.fenceAllDark,
  },
});

export interface CloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string;
}

export const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
  (props: CloseButtonProps, ref) => (
    <CloseButtonContainer {...props} ref={ref}>
      <Icon icon="cross" size={space(8)} />
    </CloseButtonContainer>
  ),
);

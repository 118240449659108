import React from 'react';

import type { TextComponent, TextProps } from '../utilities/Text/Text';
import { fonts, fontWeights, styled } from '../stitches.config';
import Text, { Text2 } from '../utilities/Text/Text';

export type TitleProps<Tag extends React.ElementType = 'h1'> = TextProps<Tag>;

export const Title: TextComponent = <Tag extends React.ElementType = 'h1'>({
  as,
  children,
  color = {
    light: 'gray-800',
    dark: 'white',
  },
  family = 'regular',
  weight = 'medium',
  ...remaining
}: TitleProps<Tag>) => (
  <Text
    {...remaining}
    as={(as ?? 'h1') as 'h1'}
    color={{ light: color.light, dark: color.dark }}
    family={family}
    lineHeight={32}
    size={24}
    weight={weight}
  >
    {children}
  </Text>
);

export const Title2 = styled('h1', Text2, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.medium,
  fontSize: '$24',
  lineHeight: '$32',
});

export default Title;

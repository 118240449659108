import { api } from '@meterup/proto';
import { match } from 'ts-pattern';
import * as z from 'zod';

export const formatISPStatus = (product: api.ISPStatus) =>
  match(product)
    .with(api.ISPStatus.IS_UNKNOWN, () => 'Unknown')
    .with(api.ISPStatus.IS_PRIMARY, () => 'Primary')
    .with(api.ISPStatus.IS_BACKUP, () => 'Backup')
    .exhaustive();

export const validISPStatus = z.nativeEnum(api.ISPStatus);

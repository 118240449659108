import React, { useRef } from 'react';
import { FocusScope, mergeProps, useModal, useOverlay } from 'react-aria';

import { ModalContainer } from '../overlays';
import { Portal } from '../Portal/Portal';

export interface MobileSidebarModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

export const MobileSidebarModal = ({ onClose, children }: MobileSidebarModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen: true,
      isDismissable: true,
    },
    ref,
  );

  const { modalProps } = useModal();

  return (
    <Portal>
      <FocusScope contain>
        <ModalContainer ref={ref} {...mergeProps(overlayProps, modalProps)}>
          {children}
        </ModalContainer>
      </FocusScope>
    </Portal>
  );
};

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  PrimaryField,
  TextInput,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { fetchDeviceJSON, updateAP } from '../../../../api/devices_api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices/:id/edit',
});

export const validEditLocationFormData = z.object({
  location: z.string().optional(),
});

interface EditLocationFormData extends z.TypeOf<typeof validEditLocationFormData> {}

const StyledForm = styled('form', {
  display: 'contents',
});

export default function DeviceEditLocation() {
  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.DeviceDetail),
  );

  const closeDrawer = useCloseDrawerCallback();

  const device = useQuery(
    ['controller', controllerName, 'devices', id],
    () => fetchDeviceJSON(controllerName, id),
    { suspense: true, enabled: isDefinedAndNotEmpty(id) },
  ).data;

  expectDefinedOrThrow(device, new ResourceNotFoundError('Access point not found'));

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateLocationMutation = useMutation(
    (location: string) =>
      updateAP(
        controllerName,
        id,
        location,
        (device.access_point?.status as api.AccessPointStatus) ??
          api.AccessPointStatus.access_point_status_unknown,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        navigate(makeDrawerLink(paths.drawers.DeviceDetail, { controllerName, id }));
      },
    },
  );

  const handleSubmit = (values: EditLocationFormData) => {
    updateLocationMutation.mutate(values.location ?? '');
  };

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Edit location</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <Formik<EditLocationFormData>
        validationSchema={toFormikValidationSchema(validEditLocationFormData)}
        initialValues={{ location: device.access_point?.location ?? '' }}
        onSubmit={handleSubmit}
      >
        {(form) => (
          <StyledForm onSubmit={form.handleSubmit}>
            <DrawerContent>
              <FieldProvider name="location">
                <PrimaryField element={<TextInput />} label="Location" />
              </FieldProvider>
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button variant="secondary" type="button" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!form.isValid}>
                  Save
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </StyledForm>
        )}
      </Formik>
    </Drawer>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import {
  Alert,
  Body2,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { deleteInternetServicePlan } from '../../../../api/controllers_api';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { fontWeights } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { PlanControls } from './common/PlanControls';
import PlanDetails from './common/PlanDetails';
import { usePlanDetails } from './common/usePlanDetails';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/:planId/delete',
});

export default function ISPDelete() {
  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();

  const { controllerName, planId } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPDelete),
  );

  const planDetails = usePlanDetails(controllerName, planId);

  const deletePlanMutation = useMutation(
    async () => {
      await deleteInternetServicePlan(controllerName, planId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controllers', controllerName, 'service-plans']);
        closeDrawer();
      },
    },
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Remove plan</DrawerTitle>
        <PlanControls controllerName={controllerName} planId={planId} />
      </DrawerHeader>

      <DrawerContent>
        <Alert
          heading="Confirmation required"
          copy={
            <>
              Are you sure you wish to delete this plan from the controller{' '}
              <Body2 css={{ fontWeight: fontWeights.medium }}>{planDetails.controller}</Body2>?
            </>
          }
          variant="negative"
        />

        <PlanDetails controllerName={controllerName} planId={planId} />
      </DrawerContent>

      <DrawerFooter>
        <DrawerControls>
          <Button
            type="button"
            variant="secondary"
            as={Link}
            to={makeDrawerLink(paths.drawers.ISPDetails, {
              controllerName,
              planId,
            })}
            loading={deletePlanMutation.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => deletePlanMutation.mutate()}
            loading={deletePlanMutation.isLoading}
          >
            Delete
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
}

import type { Location } from 'history';
import React from 'react';
import { matchPath, Navigate } from 'react-router-dom';

import type { RedirectEntry } from '../utils/createRedirect';
import { Nav } from './Nav';

export const Redirects = ({
  location,
  redirects,
}: {
  location: Location;
  redirects: RedirectEntry<any, any>[];
}) => {
  const rootLocation = Nav.useRegionLocation('root');
  const matchedRedirect = redirects
    .map(({ from, to, mapParams, makeTo }) => {
      const fromMatch = matchPath({ path: from, end: true }, location.pathname);
      return fromMatch
        ? makeTo(rootLocation, to, mapParams(fromMatch.params) as Record<string, string>)
        : false;
    })
    .find((val) => val);

  return matchedRedirect ? <Navigate replace to={matchedRedirect} /> : null;
};

import { match } from 'ts-pattern';

import type { CSS } from '../../stitches.config';

export type JustifyTypes = 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start';
export type JustifyProps = { justify?: JustifyTypes };

export const justifyCSS = (justify?: JustifyTypes): CSS =>
  match(justify)
    .with('start', () => ({ justifyContent: 'flex-start' }))
    .with('end', () => ({ justifyContent: 'flex-end' }))
    .with('center', () => ({ justifyContent: 'center' }))
    .with('around', () => ({ justifyContent: 'space-around' }))
    .with('between', () => ({ justifyContent: 'space-between' }))
    .with('evenly', () => ({ justifyContent: 'space-evenly' }))
    .with(undefined, () => ({}))
    .exhaustive();

import type {
  DropdownMenuCheckboxItemProps,
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuRadioItemProps,
  DropdownMenuTriggerItemProps,
} from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React, { createContext, forwardRef, useContext, useEffect, useMemo, useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ButtonProps } from '../Button/Button';
import Icon from '../../assets/Icon/Icon';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../common/animations';
import {
  contentStyles,
  itemIndicatorStyles,
  itemStyles,
  labelStyles,
  separatorStyles,
} from '../../common/menus';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Small2 } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';
import Button from '../Button/Button';

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  ...contentStyles,
  padding: '$4',
  minWidth: 180,
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '150ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideRightAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideLeftAndFade },
    },
  },
});

const hasIconStyles = {
  paddingLeft: '$6',
  '[data-hasicons="true"] &': {
    paddingLeft: '$24',
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  ...itemStyles,
  ...hasIconStyles,
});
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
  ...hasIconStyles,
});
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  ...itemStyles,
  ...hasIconStyles,
});
const StyledTriggerItem = styled(DropdownMenuPrimitive.TriggerItem, {
  '&[data-state="open"]:not(:focus)': {
    backgroundColor: colors['brand-400'],
    color: colors.white,
    [darkThemeSelector]: {
      backgroundColor: colors['brand-700'],
      color: colors['brand-200'],
    },
  },
  ...hasIconStyles,
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, Small2, labelStyles);

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, separatorStyles);

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, itemIndicatorStyles);

const StyledItemIcon = styled('div', itemIndicatorStyles);

const RightSlot = styled('div', {
  display: 'flex',
  marginLeft: 'auto',
  paddingLeft: '$16',
  color: colors['gray-600'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
  ':focus > &, [data-state="open"] > &': {
    color: 'inherit',
  },
  '[data-disabled] &': {
    color: colors['gray-500'],
  },
});

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;

const DropdownMenuIconContext = createContext<{
  incrementIcons: () => void;
  decrementIcons: () => void;
}>(null as any);

const useDropdownMenuIconContext = () => useContext(DropdownMenuIconContext);

export const DropdownMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => {
    const [iconCount, setIconCount] = useState<number>(0);

    const ctx = useMemo(
      () => ({
        incrementIcons: () => setIconCount((p) => p + 1),
        decrementIcons: () => setIconCount((p) => p - 1),
      }),
      [setIconCount],
    );

    return (
      <DropdownMenuIconContext.Provider value={ctx}>
        <StyledContent data-hasicons={iconCount > 0} sideOffset={4} {...props} ref={forwardedRef}>
          {children}
        </StyledContent>
      </DropdownMenuIconContext.Provider>
    );
  },
);

export const DropdownMenuSubMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <DropdownMenuContent sideOffset={2} alignOffset={-4} {...props} ref={forwardedRef}>
      {children}
    </DropdownMenuContent>
  ),
);

export const DropdownMenuTriggerButton = forwardRef<HTMLButtonElement, ButtonProps>(
  // eslint-disable-next-line react/prop-types
  ({ menuArrow = 'dropdown', ...props }, forwardedRef) => (
    <DropdownMenuTrigger asChild>
      <Button {...props} ref={forwardedRef} menuArrow={menuArrow} />
    </DropdownMenuTrigger>
  ),
);

interface DropdownMenuItemSharedProps {
  rightContent?: ReactNode;
}

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps & DropdownMenuItemSharedProps & { icon?: IconName }
>(
  // eslint-disable-next-line react/prop-types
  ({ children, rightContent, icon, ...props }, forwardedRef) => {
    const ctx = useDropdownMenuIconContext();

    useEffect(() => {
      if (icon) {
        ctx.incrementIcons();
        return () => ctx.decrementIcons();
      }

      return () => {};
    }, [ctx, icon]);

    return (
      <StyledItem {...props} ref={forwardedRef}>
        {icon && (
          <StyledItemIcon>
            <Icon icon={icon} size={space(12)} />
          </StyledItemIcon>
        )}
        {children}
        {rightContent && <RightSlot>{rightContent}</RightSlot>}
      </StyledItem>
    );
  },
);

export const DropdownMenuSubMenuIndicator = () => (
  <RightSlot>
    <Icon icon="chevronRight" size={space(10)} />
  </RightSlot>
);

export const DropdownMenuTriggerItem = forwardRef<HTMLDivElement, DropdownMenuTriggerItemProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <StyledTriggerItem {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuSubMenuIndicator />
    </StyledTriggerItem>
  ),
);

export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  DropdownMenuCheckboxItemProps & DropdownMenuItemSharedProps
>(
  // eslint-disable-next-line react/prop-types
  ({ children, rightContent, ...props }, forwardedRef) => {
    const ctx = useDropdownMenuIconContext();
    useEffect(() => {
      ctx.incrementIcons();
      return () => ctx.decrementIcons();
    }, [ctx]);
    return (
      <StyledCheckboxItem {...props} ref={forwardedRef}>
        {children}
        <DropdownMenuItemIndicator>
          <Icon icon="checkmark" size={space(12)} />
        </DropdownMenuItemIndicator>
        {rightContent && <RightSlot>{rightContent}</RightSlot>}
      </StyledCheckboxItem>
    );
  },
);

export const DropdownMenuRadioItem = forwardRef<
  HTMLDivElement,
  DropdownMenuRadioItemProps & DropdownMenuItemSharedProps
>(
  // eslint-disable-next-line react/prop-types
  ({ children, rightContent, ...props }, forwardedRef) => {
    const ctx = useDropdownMenuIconContext();
    useEffect(() => {
      ctx.incrementIcons();
      return () => ctx.decrementIcons();
    }, [ctx]);
    return (
      <StyledRadioItem {...props} ref={forwardedRef}>
        {children}
        <DropdownMenuItemIndicator>
          <Icon icon="checkmarkCircle" size={space(12)} />
        </DropdownMenuItemIndicator>
        {rightContent && <RightSlot>{rightContent}</RightSlot>}
      </StyledRadioItem>
    );
  },
);

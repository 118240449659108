import type { SliderProps } from '@radix-ui/react-slider';
import * as SliderPrimitive from '@radix-ui/react-slider';
import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';
import {
  focusSelector,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
  sharedTransition,
} from '../Button/button_styles';

const StyledSlider = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',

  '&[data-orientation="horizontal"]': {
    height: 20,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    width: 20,
    height: '100%',
  },
});

const StyledTrack = styled(SliderPrimitive.Track, {
  position: 'relative',
  flexGrow: 1,
  borderRadius: '9999px',
  backgroundColor: colors['gray-100'],
  [darkThemeSelector]: {
    backgroundColor: colors['gray-700'],
  },

  '&[data-orientation="horizontal"]': { height: 3 },
  '&[data-orientation="vertical"]': { width: 3 },
});

const StyledRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: colors['brand-600'],
  borderRadius: '9999px',
  '&[data-orientation="horizontal"]': { height: '100%' },
  '&[data-orientation="vertical"]': { width: '100%' },
  '&[data-disabled]': {
    backgroundColor: colors['gray-300'],
  },
});

const StyledThumb = styled(SliderPrimitive.Thumb, secondaryEnabledStyles, {
  display: 'block',
  width: 16,
  height: 16,
  transition: sharedTransition,
  outline: 'none',
  userSelect: 'none',
  borderRadius: '$4',
  $$dotColor: colors['gray-200'],
  [darkThemeSelector]: {
    $$dotColor: colors['gray-800'],
  },
  [focusSelector]: {
    $$dotColor: colors['brand-600'],
    [darkThemeSelector]: {
      $$dotColor: colors['brand-200'],
    },
  },
  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    width: 6,
    height: 6,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '9999px',
    backgroundColor: '$$dotColor',
  },
  '&[data-disabled]': secondaryDisabledStyles,
});

export const Slider = React.forwardRef<HTMLSpanElement, SliderProps>((props, forwardedRef) => {
  const value = props.value ?? props.defaultValue ?? [];

  return (
    <StyledSlider {...props} ref={forwardedRef}>
      <StyledTrack>
        <StyledRange />
      </StyledTrack>
      {value.map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledThumb key={i} />
      ))}
    </StyledSlider>
  );
});

import type { ReactNode } from 'react';
import { useId } from 'react-aria';

import { isDefined } from '../../../../utilities/isDefined';

export const useDescriptionAndErrorIds = (errorMessage: ReactNode, description: ReactNode) => {
  const descriptionId = useId();
  const errorMessageId = useId();

  const describedBy = [
    isDefined(errorMessage) ? errorMessageId : null,
    isDefined(description) ? descriptionId : null,
  ]
    .filter(isDefined)
    .join(' ');

  return { descriptionId, errorMessageId, describedBy };
};

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type MinWidthTypes = 'full' | 'screen' | SpacingScale;
export type MinWidthProps = { minWidth?: MinWidthTypes };

export const minWidthCSS = (minWidth?: MinWidthTypes): CSS => {
  if (minWidth === 'full') {
    return { minWidth: '100%' };
  }

  if (minWidth === 'screen') {
    return { minWidth: '100vw' };
  }

  if (minWidth != null) {
    return {
      minWidth: `$${minWidth}`,
    };
  }

  return {};
};

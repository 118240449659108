import type React from 'react';

import { FocusRingSelfIndexed } from '../../common/focus_rings';
import { darkThemeSelector, shadows, styled } from '../../stitches.config';

export const ListItem = styled('div', FocusRingSelfIndexed, {
  alignItems: 'center',
  hStack: '$8',
  padding: '$8 $16',
  minHeight: '$40',
  boxShadow: shadows.fenceAllLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceAllDark,
  },
  '&:focus': {
    // KLUDGE: CollapsibleListContent applies border radii with higher
    // specificity than this &:focus selector. !important is a last-resort fix
    // to override that in lieu of a broader rework.
    borderRadius: '$8 !important',
  },
});

export type ListItemProps = React.ComponentProps<typeof ListItem>;

export default ListItem;

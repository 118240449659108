import type { ReactNode } from 'react';
import { List, ListItem, ListItemHeader, ListTitle, Skeleton } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense, useCallback } from 'react';
import { QueryErrorResetBoundary } from 'react-query';

import { ErrorFallbackWidget } from './error_fallbacks';

const LoadingWidget = ({
  title,
  skeletonHeight = '160px',
}: {
  title: ReactNode;
  skeletonHeight?: string;
}) => (
  <List>
    <ListItemHeader>
      <ListTitle>{title}</ListTitle>
    </ListItemHeader>
    <ListItem style={{ paddingTop: 16, paddingBottom: 16 }}>
      <Skeleton radius={4} height={skeletonHeight} width="100%" />
    </ListItem>
  </List>
);

export const WidgetSuspenseAndErrorBoundary = ({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) => {
  const fallback = useCallback(
    (props) => (
      <ErrorFallbackWidget title={title} error={props.error} resetError={props.resetError} />
    ),
    [title],
  );

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallback={fallback}>
          <Suspense fallback={<LoadingWidget title={title} />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import { Caption2 } from '../../text/Caption';
import { Small2 } from '../../text/Small';

export const MinList = styled('div', {
  position: 'relative',
  vStack: 0,
  alignItems: 'stretch',
  padding: '$8 $16 $12',
  borderRadius: '$8',
  gap: '$8',
  boxShadow: shadows.fenceAllLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceAllDark,
  },
});

export const MinListItem = styled('div', {
  hStack: '$8',
  minHeight: '$20',
  zIndex: 'auto',
});

const StyledMinListItemHeader = styled(MinListItem, {
  paddingRight: '$2',
});

const StyledHeaderIcon = styled(Icon, {
  width: '$12',
  height: '$12',
  color: colors['gray-500'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
});

const StyledHeaderChevronIcon = styled(Icon, {
  marginLeft: 'auto',
  width: '$8',
  height: '$8',
  color: colors['gray-500'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
});

interface MinListItemHeaderProps {
  children: React.ReactNode;
  icon?: IconName;
  hasArrow?: boolean;
}

export const MinListTitle = styled('div', Caption2, {
  color: colors['gray-600'],
  fontWeight: fontWeights.bold,
  [darkThemeSelector]: {
    color: colors['gray-50'],
  },
});

export const MinListControls = styled('div', {
  marginLeft: 'auto',
});

export const MinListItemHeader = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      icon,
      children,
      hasArrow = false,
      ...props
    }: PolymorphicComponentProps<Tag, MinListItemHeaderProps>,
    ref: PolymorphicRef<Tag>,
  ) => (
    <StyledMinListItemHeader {...props} ref={ref}>
      {icon && <StyledHeaderIcon icon={icon} />}
      {children}
      {hasArrow && <StyledHeaderChevronIcon icon="chevronRight" />}
    </StyledMinListItemHeader>
  ),
) as Polymorphic.ForwardRefComponent<'a', MinListItemHeaderProps>;

export const MinListItemPair = styled(MinListItem, {});

export const MinListItemLabel = styled('div', Small2, {
  color: colors['gray-600'],
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

export const MinListItemValue = styled('div', Body2, {
  marginLeft: 'auto',
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-50'],
  },
});

const StyledFooterLinkIcon = styled(Icon, {
  width: '$8',
  height: '$8',
});

const StyledMinListItemFooterLink = styled('a', MinListItem, Caption2, {
  hStack: '$4',
  fontWeight: fontWeights.bold,
  alignItems: 'center',
  justifyContent: 'center',
  background: colors['gray-50'],
  borderRadius: '$8',
  padding: '$6 $8',
  color: colors['blue-600'],
});

interface MinListItemFooterLinkProps {
  hasArrow?: boolean;
}

export const MinListItemFooterLink = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      hasArrow = true,
      children,
      ...props
    }: PolymorphicComponentProps<Tag, MinListItemFooterLinkProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <StyledMinListItemFooterLink {...props} ref={forwardedRef}>
      {children}
      {hasArrow && <StyledFooterLinkIcon icon="chevronRight" />}
    </StyledMinListItemFooterLink>
  ),
) as Polymorphic.ForwardRefComponent<'a', MinListItemFooterLinkProps>;

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  getErrorMessage,
  isDefined,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import {
  Alert,
  BasicSelect,
  BasicSelectItem,
  Button,
  ComboBox,
  ComboBoxItem,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  PrimaryField,
  SecondaryField,
  Textarea,
  TextInput,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { Form, Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import {
  fetchCompanies,
  fetchControllerJSON,
  updateControllerMetadataJSON,
} from '../../../../api/controllers_api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { formatLifecycleStatus, validLifecycleStatus } from '../../../../utils/lifecycle_status';

const StyledForm = styled(Form, {
  display: 'contents',
});

const INTEGERS_ONLY_REGEX = /^\d+$/;

const validNetworkMetadata = z.object({
  address: z.string().optional(),
  companySlug: z.string().optional().nullable(),
  lifecycleStatus: validLifecycleStatus.optional(),
  notes: z.string().optional(),
  patchPanelDiagramUrl: z.string().url().optional(),
  squareFeet: z
    .string()
    .refine((v) => INTEGERS_ONLY_REGEX.test(v), 'Must be an integer')
    .optional(),
});

interface NetworkMetadata extends z.TypeOf<typeof validNetworkMetadata> {}

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/metadata/edit',
});

export default function EditControllerMetadata() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditControllerMetadata),
  );

  const { data: networkInfo } = useQuery(
    ['controller', controllerName, 'network-info'],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  );

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const result = useQuery(['companies'], fetchCompanies, { suspense: true });
  const companies = (result.data?.filter((c) => isDefinedAndNotEmpty(c.slug)) ??
    []) as api.CompanyResponse[];

  const closeDrawer = useCloseDrawerCallback();

  const queryClient = useQueryClient();

  const updateNetworkMetadataMutation = useMutation(
    ['update_network_metadata', networkInfo.name],
    async (values: NetworkMetadata) => {
      // TRICKY: The form deals with a company slug because that's what's
      // returned from the controller info endpoint, but the API call expects a
      // company sid.
      const companySid = companies.find((c) => c.slug === values.companySlug)?.sid;
      await updateControllerMetadataJSON(networkInfo.name, {
        address: values.address,
        company_sid: companySid ?? '',
        lifecycle_status: isDefined(values.lifecycleStatus) ? values.lifecycleStatus : undefined,
        noc_metadata: {
          patch_panel_diagram_url: values.patchPanelDiagramUrl?.trim(),
          notes: values.notes?.trim(),
        },
        square_feet: isDefined(values.squareFeet) ? parseFloat(values.squareFeet) : undefined,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        closeDrawer();
      },
    },
  );

  const hasCompany = isDefinedAndNotEmpty(networkInfo.company_slug);

  const isActiveAndHasCompany =
    hasCompany &&
    (networkInfo.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY ||
      networkInfo.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP);

  return (
    <Formik<NetworkMetadata>
      initialValues={{
        address: networkInfo.address,
        companySlug: networkInfo.company_slug,
        lifecycleStatus: networkInfo.lifecycle_status,
        notes: networkInfo.noc_metadata?.notes ?? '',
        patchPanelDiagramUrl: networkInfo.noc_metadata?.patch_panel_diagram_url ?? '',
        squareFeet: networkInfo.square_feet.toString(),
      }}
      validationSchema={toFormikValidationSchema(validNetworkMetadata)}
      onSubmit={(values) => updateNetworkMetadataMutation.mutate(values)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Edit controller metadata</DrawerTitle>
            <DrawerControls>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>

          <DrawerContent>
            {isDefined(updateNetworkMetadataMutation.error) && (
              <Alert
                heading="Error while submitting"
                copy={getErrorMessage(updateNetworkMetadataMutation.error)}
              />
            )}
            <FieldProvider name="companySlug">
              <PrimaryField
                label="Company"
                description={(() => {
                  if (isActiveAndHasCompany) {
                    return 'Reassigning primary/backup controllers is not supported.';
                  }
                  if (hasCompany) {
                    return 'Reassigning a controller can have widespread effects! Please be careful.';
                  }
                  return null;
                })()}
                element={
                  <ComboBox
                    placeholder="Select a company"
                    isDisabled={isActiveAndHasCompany}
                    defaultItems={companies}
                    icon="company"
                  >
                    {(company) => (
                      <ComboBoxItem
                        key={company.slug}
                        textValue={`${company.name} (${company.slug})`}
                      >
                        {company.name} ({company.slug})
                      </ComboBoxItem>
                    )}
                  </ComboBox>
                }
              />
            </FieldProvider>
            <FieldProvider name="lifecycleStatus">
              <SecondaryField
                label="Lifecycle status"
                element={
                  <BasicSelect>
                    {Object.values(api.LifecycleStatus).map((lifecycleStatus) => (
                      <BasicSelectItem key={lifecycleStatus} value={lifecycleStatus}>
                        {formatLifecycleStatus(lifecycleStatus)}
                      </BasicSelectItem>
                    ))}
                  </BasicSelect>
                }
              />
            </FieldProvider>
            <FieldProvider name="address">
              <PrimaryField label="Address" element={<TextInput />} />
            </FieldProvider>
            <FieldProvider name="squareFeet">
              <PrimaryField label="Square footage" element={<TextInput suffix="sq ft" />} />
            </FieldProvider>
            <FieldProvider name="patchPanelDiagramUrl">
              <PrimaryField label="Link to patch panel diagram" element={<TextInput />} />
            </FieldProvider>
            <FieldProvider name="notes">
              <PrimaryField label="Notes" element={<Textarea />} />
            </FieldProvider>
          </DrawerContent>

          <DrawerFooter>
            <DrawerControls>
              <Button type="submit">Save</Button>
            </DrawerControls>
          </DrawerFooter>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

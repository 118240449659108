import React from 'react';

import Logo from '../../assets/Logo/Logo';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const Container = styled('div', {
  display: 'inline-block',
  padding: '$4 $8',
  cursor: 'pointer',
  borderRadius: '$8',
  transition: 'all 150ms ease-out',
  '&:hover': {
    backgroundColor: colors['gray-50'],
  },
  [darkThemeSelector]: {
    '&:hover': {
      backgroundColor: colors['gray-900'],
    },
  },
});

const MobileLogo = styled('div', {
  '@maxSm': {
    display: 'none',
  },
});

const DesktopLogo = styled('div', {
  '@sm': {
    display: 'none',
  },
});

export interface HeaderLogoProps {
  /** Link of the item. */
  href?: string;
  /** OnClick event of the item. */
  onClick?: (event: any) => void;
}

export const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = ({ href, onClick }) => (
  <Container as={href ? 'a' : 'button'} href={href} onClick={onClick}>
    <MobileLogo>
      <Logo variant="full" height={4} />
    </MobileLogo>
    <DesktopLogo>
      <Logo variant="initial" height={3} />
    </DesktopLogo>
  </Container>
);

export default HeaderLogo;

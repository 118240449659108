import type { Path } from 'history';
import { parsePath } from 'history';

import { isDefined } from './isDefined';

/**
 * Given a search params string, if the region's name is present in searchParams
 * returns a Location object for the region. Otherwise returns null.
 *
 * @example
 *  parseRegionLocation('drawer', 'drawer=/clients/123%3Ffoo%3Dbar');
 *  // { pathname: '/client/123', search: 'foo=bar', hash: '' }
 */
export const parseRegionLocation = (
  regionName: string,
  searchParams: string,
): Partial<Path> | null => {
  const possiblePath = new URLSearchParams(searchParams).get(regionName);

  if (isDefined(possiblePath)) {
    return parsePath(possiblePath);
  }

  return null;
};

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, isDefined } from '@meterup/common';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidPlanData } from './common/form_data';
import { createInternetServicePlan } from '../../../../api/controllers_api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { styled } from '../../../../stitches';
import { makeCloseDrawerLink, makeDrawerLink } from '../../../../utils/makeLink';
import { NO_PROVIDER, toISPCreateRequest, validPlanData } from './common/form_data';
import { FormContent } from './common/FormContent';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/new',
});

const StyledForm = styled('form', {
  height: '100%',
});

export default function ISPCreate() {
  const navigate = useNavigate();

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPCreate),
  );

  const queryClient = useQueryClient();

  const createDrawerMutation = useMutation(
    async (data: ValidPlanData) => {
      const apiData = toISPCreateRequest(data);
      const plan = await createInternetServicePlan(controllerName, apiData.planData);
      return plan;
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['controllers', controllerName, 'service-plans']);
        navigate(
          makeDrawerLink(paths.drawers.ISPDetails, {
            controllerName: result.controller,
            planId: result.sid,
          }),
        );
      },
    },
  );

  return (
    <Formik<ValidPlanData>
      initialValues={{
        provider: NO_PROVIDER,
        product: api.ISPProduct.IR_UNKNOWN,
        status: api.ISPStatus.IS_UNKNOWN,
        ip_type: 'dynamic',
      }}
      validationSchema={toFormikValidationSchema(validPlanData)}
      onSubmit={(values) => createDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Create plan</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>

            <DrawerContent>
              {isDefined(createDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={JSON.stringify(createDrawerMutation.error)}
                />
              )}
              <FormContent controllerName={controllerName} />
            </DrawerContent>

            <DrawerFooter>
              <DrawerControls>
                <Button
                  type="button"
                  variant="secondary"
                  as={Link}
                  to={makeCloseDrawerLink()}
                  loading={createDrawerMutation.isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" loading={createDrawerMutation.isLoading}>
                  Save
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}

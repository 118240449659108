import React from 'react';

import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Caption2 } from '../../text/Caption';

const Label = styled('h5', Caption2, {
  padding: '0 $8',
  marginBottom: '$4',
  fontWeight: fontWeights.medium,
  color: colors['gray-500'],
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

export interface SidebarGroupLabelProps {
  children: React.ReactNode;
}

export const SidebarGroupLabel: React.FunctionComponent<SidebarGroupLabelProps> = ({
  children,
}) => <Label>{children}</Label>;

export default SidebarGroupLabel;

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { api } from '@meterup/proto';
import React, { useState } from 'react';

import type { InviteUsersFormData } from './common/InviteUsersFormData';
import { ConfirmationView } from './common/ConfirmationView';
import { EmailsAndRoleForm } from './common/EmailsAndRoleForm';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/add-users',
});

export default function UsersAdd() {
  const [isConfirming, setIsConfirming] = useState(false);
  const [formData, setFormData] = useState<InviteUsersFormData>({
    emailListRawText: '',
    company_role: api.CompanyMembershipRole.admin,
  });

  return isConfirming ? (
    <ConfirmationView values={formData} onClickBack={() => setIsConfirming(false)} />
  ) : (
    <EmailsAndRoleForm
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        setIsConfirming(true);
      }}
    />
  );
}

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type MaxWidthTypes = 'full' | 'screen' | SpacingScale;
export type MaxWidthProps = { maxWidth?: MaxWidthTypes };

export const maxWidthCSS = (maxWidth?: MaxWidthTypes): CSS => {
  if (maxWidth === 'full') {
    return { maxWidth: '100%' };
  }

  if (maxWidth === 'screen') {
    return { maxWidth: '100vw' };
  }

  if (maxWidth != null) {
    return {
      maxWidth: `$${maxWidth}`,
    };
  }

  return {};
};

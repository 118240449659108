import type { api } from '@meterup/proto';
import { isDefined, isGuest } from '@meterup/common';
import oui from 'oui';
import { match } from 'ts-pattern';

import { isTimestampKnown } from '../components/timestamps';
import { METER_OUI_IDENTIFIER } from '../constants';

export const isWired = (c: api.ControllerClient) => Number(c.signal) === 0;
export const isWireless = (c: api.ControllerClient) => Number(c.signal) !== 0;

export const isMeterSwitch = (
  c: api.ControllerClient,
): c is api.ControllerClient & { lease: api.Lease } =>
  c.lease?.name?.startsWith('meter-ms') ?? false;

export const isMeterAccessPoint = (
  c: api.ControllerClient,
): c is api.ControllerClient & { lease: api.Lease } =>
  (c.lease?.name?.startsWith('meter-ap') || c.lease?.name?.startsWith('meter-mw')) ?? false;

export const isMeterHardware = (
  c: api.ControllerClient,
): c is api.ControllerClient & { lease: api.Lease } =>
  (isDefined(c.lease) && oui(c.lease.mac) === METER_OUI_IDENTIFIER) ||
  isMeterSwitch(c) ||
  isMeterAccessPoint(c);

export const isLastSeenKnown = (
  c: api.ControllerClient,
): c is api.ControllerClient & { last_seen: string } => isTimestampKnown(c.last_seen);

export enum FilterStrategy {
  All = 'all',
  Wired = 'wired',
  Wireless = 'wireless',
  Guest = 'guest',
  Meter = 'meter',
  MeterSwitch = 'meter-switch',
  MeterAccessPoint = 'meter-access-point',
}

export const getPredicateFromStrategy = (strategy: FilterStrategy) =>
  match(strategy)
    .with(FilterStrategy.All, () => () => true)
    .with(FilterStrategy.Wired, () => isWired)
    .with(FilterStrategy.Wireless, () => isWireless)
    .with(FilterStrategy.Guest, () => isGuest)
    .with(FilterStrategy.Meter, () => isMeterHardware)
    .with(FilterStrategy.MeterSwitch, () => isMeterSwitch)
    .with(FilterStrategy.MeterAccessPoint, () => isMeterAccessPoint)
    .exhaustive();

import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Text2 } from '../../utilities/Text/Text';

const BaseAnchor = styled('a', Text2, {
  color: colors['blue-700'],
  textDecoration: 'none',

  '&:hover': {
    color: colors['blue-800'],
    textDecoration: 'underline',
  },

  [darkThemeSelector]: {
    color: colors['blue-300'],

    '&:hover': {
      color: colors['blue-200'],
    },
  },

  variants: {
    muted: {
      true: {
        color: colors['gray-500'],
        [darkThemeSelector]: {
          color: colors['gray-300'],
        },
      },
      false: {},
    },
    invalid: {
      true: {
        color: colors['red-600'],
        [darkThemeSelector]: {
          color: colors['red-300'],
        },
      },
      false: {},
    },
  },
});

export interface LinkProps {
  /**
   * Mark the component as having an issue.
   */
  invalid?: boolean;
  /**
   * Mark the component as muted.
   */
  muted?: boolean;
}

const Link = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      invalid = false,
      muted = false,
      children,
      ...props
    }: PolymorphicComponentProps<Tag, LinkProps>,
    ref: PolymorphicRef<Tag>,
  ) => (
    <BaseAnchor muted={muted} invalid={invalid} {...props} ref={ref}>
      {children}
    </BaseAnchor>
  ),
) as Polymorphic.ForwardRefComponent<'a', LinkProps>;

export default Link;

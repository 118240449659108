import type { ReactNode } from 'react';
import React from 'react';
import useMeasure from 'react-use-measure';

import type { IconName } from '../../assets/Icon/Icon';
import type { ControlSize } from '../Control/types';
import Icon from '../../assets/Icon/Icon';
import { useControlSize } from '../../common/control_size';
import {
  colors,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';
import { sharedTransition } from '../Button/button_styles';

const iconColor = '$$iconColor';
const iconSize = '$$iconSize';
const inputColor = '$$inputColor';
const inputPadding = '$$inputPadding';
const placeholderColor = '$$placeholderColor';
const sidecarColor = '$$sidecarColor';
const sidecarGap = '$$sidecarGap';
const Container = styled('label', {
  display: 'flex',
  background: colors.white,
  boxShadow: shadows.fieldInitialLight,
  position: 'relative',
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  [inputColor]: colors['gray-600'],
  [placeholderColor]: colors['gray-500'],
  [sidecarColor]: colors['gray-500'],
  transition: sharedTransition,
  '&:focus-within': {
    boxShadow: shadows.fieldFocusedLight,
  },
  [darkThemeSelector]: {
    background: colors['gray-800'],
    boxShadow: shadows.fieldInitialDark,
    [inputColor]: colors['gray-50'],
    [placeholderColor]: colors['gray-300'],
    [sidecarColor]: colors['gray-100'],
    '&:focus-within': {
      boxShadow: shadows.fieldFocusedDark,
    },
  },
  variants: {
    size: {
      small: {
        [inputPadding]: '$space$2 $space$4',
        [sidecarGap]: '$space$4',
        [iconSize]: '$space$12',
        fontSize: '$12',
        lineHeight: '$16',
        borderRadius: '$4',
      },
      medium: {
        [inputPadding]: '$space$4 $space$8',
        [sidecarGap]: '$space$8',
        [iconSize]: '$space$14',
        fontSize: '$14',
        lineHeight: '$20',
        borderRadius: '$8',
      },
      large: {
        [inputPadding]: '$space$8 $space$12',
        [sidecarGap]: '$space$8',
        [iconSize]: '$space$16',
        fontSize: '$14',
        lineHeight: '$20',
        borderRadius: '$8',
      },
      'x-large': {
        [inputPadding]: '$space$10 $space$14',
        [sidecarGap]: '$space$8',
        [iconSize]: '$space$16',
        fontSize: '$16',
        lineHeight: '$24',
        borderRadius: '$8',
      },
    },
    hasError: {
      true: {
        boxShadow: shadows.fieldErrorLight,
        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,
        },
      },
      false: {
        '&:not(:focus-within):hover': {
          boxShadow: shadows.fieldHoveredLight,
          [darkThemeSelector]: {
            boxShadow: shadows.fieldHoveredDark,
          },
        },
      },
    },
    isDisabled: {
      true: {
        background: colors['gray-50'],
        boxShadow: shadows.fieldDisabledLight,
        opacity: 0.5,
        pointerEvents: 'none',
        [darkThemeSelector]: {
          background: colors['gray-800'],
          boxShadow: shadows.fieldDisabledDark,
        },
      },
      false: {},
    },
  },
});

const Input = styled('input', {
  outline: 'none',
  border: 'none',
  width: '100%',
  background: 'none',
  padding: inputPadding,
  color: inputColor,
  '&::placeholder': {
    color: placeholderColor,
  },
});

const Sidecar = styled('div', {
  hStack: sidecarGap,
  position: 'absolute',
  top: 0,
  bottom: 0,
  padding: inputPadding,
  color: sidecarColor,
});

const Prefix = styled(Sidecar, {
  left: 0,
});

const Suffix = styled(Sidecar, {
  right: 0,
});

const StyledIcon = styled(Icon, {
  width: iconSize,
  height: iconSize,
  color: iconColor,
});

export interface InputSharedProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  icon?: IconName;
}

export interface BaseInputProps extends InputSharedProps {
  disabled?: boolean;
  hasError?: boolean;
  controlSize?: ControlSize;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const BaseInput = React.forwardRef<HTMLLabelElement, BaseInputProps>(
  (
    { disabled = false, hasError = false, icon, prefix, suffix, controlSize, inputProps, inputRef },
    ref,
  ) => {
    const size = useControlSize(controlSize, 'medium');

    const [prefixContainerRef, { width: iconContainerWidth = 0 }] = useMeasure();
    const [suffixContainerRef, { width: sidecarContainerWidth = 0 }] = useMeasure();

    return (
      <Container
        ref={ref}
        role="presentation"
        size={size}
        hasError={hasError}
        isDisabled={disabled}
      >
        {(icon || prefix) && (
          <Prefix ref={prefixContainerRef}>
            {icon && <StyledIcon icon={icon} />}
            {prefix}
          </Prefix>
        )}
        <Input
          {...inputProps}
          ref={inputRef}
          style={{
            paddingLeft: iconContainerWidth > 0 ? iconContainerWidth : undefined,
            paddingRight: sidecarContainerWidth > 0 ? sidecarContainerWidth : undefined,
          }}
        />
        {suffix && <Suffix ref={suffixContainerRef}>{suffix}</Suffix>}
      </Container>
    );
  },
);

import { drawers } from './generated/drawers';
import { pages } from './generated/pages';

export const API_BASE_URL = `${import.meta.env.REACT_APP_API_URL}/api-proxy`;

export const METER_OUI_IDENTIFIER = 'Meter Inc.';

export const paths = {
  pages,
  drawers,
};

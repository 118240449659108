import type { api } from '@meterup/proto';
import { getMany, getOne } from '@meterup/common';

import { axiosInstanceJSON } from './api_clients';

export const fetchCompaniesJSON = async (hasController?: boolean) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.CompaniesListResponse>(`/v1/companies`, {
      params: { 'has-controller': hasController, limit: 500 },
    });
    return result.data.companies.sort((a, b) => a.name.localeCompare(b.name));
  });

export const fetchCompanyJSON = async (sid: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyResponse>(`/v1/companies/${sid}`);
    return result.data;
  });

export const fetchCompanyControllersJSON = async (companySlug: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyControllersResponse>(
      `/v1/companies/${companySlug}/controllers`,
    );
    return result.data.controllers;
  });

export const createCompanyJSON = async (data: api.CompanyCreateRequest) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.post<api.CompanyResponse>(`/v1/companies`, data);
    return result.data;
  });

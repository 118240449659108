import React from 'react';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { SidebarFormFactorContext } from '../Sidebars/SidebarFormFactorContext';

const Container = styled('div', {
  position: 'relative',
  background: colors.white,
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  boxShadow: shadows.paneBottomLight,
  vStack: 0,
  alignItems: 'stretch',
  [darkThemeSelector]: {
    background: colors['gray-800'],
    boxShadow: shadows.paneBottomDark,
  },
  variants: {
    isOpen: {
      false: {
        height: 'min-content',
      },
      true: {
        bottom: 0,
      },
    },
  },
});

export interface MobileSidebarProps extends React.HTMLProps<HTMLDivElement> {}

export const MobileSidebar: React.FC<MobileSidebarProps> = ({ children }) => (
  <SidebarFormFactorContext.Provider value="mobile">
    <Container>{children}</Container>
  </SidebarFormFactorContext.Provider>
);

export default MobileSidebar;

import { isDefined, NeutralBadge } from '@meterup/common';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
  HStack,
  space,
  Tab,
  Tabs,
} from '@meterup/metric';
import React from 'react';

export interface TabFilter<Key extends React.Key = React.Key> {
  key: Key;
  label: React.ReactNode;
  count?: number;
}

export interface TabFiltersProps<Key extends React.Key = React.Key> {
  filters: TabFilter<Key>[];
  activeFilterKey: Key;
  onActivateFilter: (filter: TabFilter<Key>) => void;
}

export const TabFilters = <Key extends React.Key = React.Key>({
  filters,
  activeFilterKey,
  onActivateFilter,
}: TabFiltersProps<Key>) => {
  const firstTabs = filters.slice(0, 3);
  const otherTabs = filters.slice(3);

  return (
    <HStack spacing={space(8)}>
      <Tabs>
        {firstTabs.map((filter) => (
          <Tab
            key={filter.key}
            annotation={filter.count}
            active={filter.key === activeFilterKey}
            onClick={() => onActivateFilter(filter)}
          >
            {filter.label}
          </Tab>
        ))}
      </Tabs>
      {otherTabs.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTriggerButton
            icon="overflowVertical"
            arrangement="hidden-label"
            variant={
              otherTabs.some((filter) => filter.key === activeFilterKey) ? 'primary' : 'tertiary'
            }
          >
            More
          </DropdownMenuTriggerButton>
          <DropdownMenuContent>
            {otherTabs.map((filter) => (
              <DropdownMenuItem
                key={filter.key}
                icon={activeFilterKey === filter.key ? 'checkmark' : undefined}
                onSelect={() => {
                  onActivateFilter(filter);
                }}
                rightContent={
                  isDefined(filter.count) && <NeutralBadge ends="card">{filter.count}</NeutralBadge>
                }
              >
                {filter.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </HStack>
  );
};

import React from 'react';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const Container = styled('div', {
  position: 'sticky',
  top: 0,
  boxShadow: shadows.fenceBottomLight,
  background: colors.white,
  [darkThemeSelector]: {
    background: colors['gray-800'],
    boxShadow: shadows.fenceBottomDark,
  },
});

export interface MobileSidebarToggleContainerProps {}

export const MobileSidebarToggleContainer: React.FC<MobileSidebarToggleContainerProps> = ({
  children,
}) => <Container>{children}</Container>;

export default MobileSidebarToggleContainer;

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type TranslateYTypes = SpacingScale;

export type TranslateYProps = {
  translateY?: TranslateYTypes;
};

export const translateYCSS = (translateY?: TranslateYTypes): CSS =>
  translateY != null
    ? {
        translateY: `$space$${translateY}`,
      }
    : {};

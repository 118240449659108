import { colors, darkThemeSelector, shadows } from '../../stitches.config';

export const labelColor = '$$labelColor';
export const iconColor = '$$iconColor';
export const loadingIconColor = '$$loadingIconColor';

export const focusSelector = '&:focus, &:focus-within';

export const sharedTransition = 'all 150ms ease-out';

export const secondaryEnabledStyles = {
  backgroundColor: colors['gray-50'],
  backgroundImage: colors.controlSecondary,
  boxShadow: shadows.controlSecondaryInitialLight,
  [labelColor]: colors['gray-700'],
  [iconColor]: colors['gray-600'],
  '&:hover': {
    boxShadow: shadows.controlSecondaryHoveredLight,
    [labelColor]: colors['gray-800'],
    [iconColor]: colors['gray-700'],
  },
  [focusSelector]: {
    boxShadow: shadows.controlSecondaryFocusedLight,
  },
  [darkThemeSelector]: {
    backgroundColor: colors['gray-700'],
    backgroundImage: colors.controlSecondaryDark,
    boxShadow: shadows.controlSecondaryInitialDark,
    [labelColor]: colors['gray-50'],
    [iconColor]: colors['gray-100'],
    '&:hover': {
      boxShadow: shadows.controlSecondaryHoveredDark,
      [labelColor]: colors.white,
      [iconColor]: colors['gray-50'],
    },
    [focusSelector]: {
      boxShadow: shadows.controlSecondaryFocusedDark,
    },
  },
};

export const primaryEnabledStyles = {
  backgroundColor: colors['brand-600'],
  backgroundImage: colors.controlPrimary,
  boxShadow: shadows.controlPrimaryInitialLight,
  [labelColor]: colors['brand-50'],
  [iconColor]: colors['brand-100'],
  '&:hover': {
    boxShadow: shadows.controlPrimaryHoveredLight,
    [labelColor]: colors.white,
    [iconColor]: colors['brand-50'],
  },
  [focusSelector]: {
    boxShadow: shadows.controlPrimaryFocusedLight,
  },
  [darkThemeSelector]: {
    backgroundColor: colors['brand-600'],
    boxShadow: shadows.controlPrimaryInitialDark,
    '&:hover': {
      boxShadow: shadows.controlPrimaryHoveredDark,
    },
    [focusSelector]: {
      boxShadow: shadows.controlPrimaryFocusedDark,
    },
  },
};

export const primaryDisabledStyles = {
  backgroundColor: colors['brand-800'],
  boxShadow: shadows.controlPrimaryDisabledLight,
  [labelColor]: colors['brand-200'],
  [iconColor]: colors['brand-300'],
  [loadingIconColor]: colors['brand-50'],
  [darkThemeSelector]: {
    boxShadow: shadows.controlPrimaryDisabledDark,
  },
};

export const secondaryDisabledStyles = {
  backgroundColor: colors['gray-100'],
  boxShadow: shadows.controlSecondaryDisabledLight,
  [labelColor]: colors['gray-600'],
  [iconColor]: colors['gray-500'],
  [loadingIconColor]: colors['gray-700'],
  [darkThemeSelector]: {
    backgroundColor: colors['gray-800'],
    boxShadow: shadows.controlSecondaryDisabledDark,
    [labelColor]: colors['gray-200'],
    [iconColor]: colors['gray-300'],
    [loadingIconColor]: colors['gray-50'],
  },
};

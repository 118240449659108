import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { styled } from '../../stitches.config';
import ListItem from '../List/ListItem';

const AccordionItem = styled(AccordionPrimitive.Item, ListItem, {
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '$0',

  '&[data-state=open]': { gap: '$10' },
});

export default AccordionItem;

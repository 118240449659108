import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  CountBadge,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import {
  Body2,
  Button,
  CloseDrawerButton,
  CopyCapsule,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  Heading2,
  Icon,
  MinList,
  MinListItemFooterLink,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  space,
  VStack,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { fetchCompanyControllersJSON, fetchCompanyJSON } from '../../../api/company_api';
import { addNOCUserToCompany, fetchCompanyUsersJSON } from '../../../api/users_api';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { colors } from '../../../stitches';
import { makeLink } from '../../../utils/makeLink';

const SET_COMPANY_PARAM_KEY = 'current_company';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName',
});

export default function CompanySummary() {
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.CompanySummary),
  );

  const closeDrawer = useCloseDrawerCallback();

  const companyData = useQuery(['companies', companyName], () => fetchCompanyJSON(companyName), {
    suspense: true,
  }).data;

  expectDefinedOrThrow(companyData, new ResourceNotFoundError('Company not found'));

  const users =
    useQuery(['companies', companyName, 'users'], () => fetchCompanyUsersJSON(companyName), {
      suspense: true,
    }).data ?? [];

  const controllers =
    useQuery(
      ['companies', companyName, 'controllers'],
      () => fetchCompanyControllersJSON(companyName),
      {
        suspense: true,
      },
    ).data ?? [];

  const openDashboardMutation = useMutation(
    ['open_dashboard', companyName],
    async () => {
      await addNOCUserToCompany(companyName);
    },
    {
      onSuccess: () => {
        window.location.href = `${
          import.meta.env.DASHBOARD_URL
        }?${SET_COMPANY_PARAM_KEY}=${companyName}`;
      },
    },
  );

  const adminUsers = users.filter((u) => u.company_role === api.CompanyMembershipRole.admin);
  const memberUsers = users.filter((u) => u.company_role === api.CompanyMembershipRole.member);
  const guestUsers = users.filter((u) => u.company_role === api.CompanyMembershipRole.guest);

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Company</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton onClick={closeDrawer} />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <VStack align="center">
          <Heading2>
            <CopyCapsule
              aria-label="Copy company name"
              textValue={companyData.name}
              arrangement="leading-icon"
            >
              {companyData.name}
            </CopyCapsule>
          </Heading2>
          <Body2>
            <CopyCapsule
              aria-label="Copy company slug"
              textValue={companyData.slug}
              arrangement="leading-icon"
            >
              {companyData.slug}
            </CopyCapsule>
          </Body2>
        </VStack>
        <Button
          variant="tertiary"
          width="full"
          size="large"
          onClick={() => openDashboardMutation.mutate()}
        >
          Open Dashboard
        </Button>
        <MinList>
          <MinListItemHeader icon="controller">
            <MinListTitle>Controllers</MinListTitle>
          </MinListItemHeader>
          {controllers.map((controller) => (
            <MinListItemPair
              key={controller.name}
              as={Link}
              to={makeLink(paths.pages.ControllerDetails, { controllerName: controller.name })}
              css={{
                paddingY: 4,
                paddingX: 8,
                margin: '0 -8px',
                borderRadius: '$6',
                whiteSpace: 'nowrap',
                '&:hover': { background: colors['gray-50'] },
              }}
            >
              <MinListItemLabel>{controller.name}</MinListItemLabel>
              <MinListItemValue css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {controller.address}
              </MinListItemValue>
              <Icon icon="chevronRight" size={space(8)} color={{ light: 'gray-400' }} />
            </MinListItemPair>
          ))}
        </MinList>
        <MinList
          as={Link}
          to={makeLink(paths.pages.CompanyUsers, {
            companyName,
          })}
        >
          <MinListItemHeader icon="user">
            <MinListTitle>Users</MinListTitle>
          </MinListItemHeader>
          <MinListItemPair>
            <MinListItemLabel>All</MinListItemLabel>
            <MinListItemValue>
              <CountBadge value={users.length} />
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Admins</MinListItemLabel>
            <MinListItemValue>
              <CountBadge value={adminUsers.length} />
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Members</MinListItemLabel>
            <MinListItemValue>
              <CountBadge value={memberUsers.length} />
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Guests</MinListItemLabel>
            <MinListItemValue>
              <CountBadge value={guestUsers.length} />
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemFooterLink as="div">View users</MinListItemFooterLink>
        </MinList>
      </DrawerContent>
    </Drawer>
  );
}

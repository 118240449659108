import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  ControllerRequestFailedError,
  expectDefinedOrThrow,
} from '@meterup/common';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { fetchNetworkInfoJSON } from '../../../../api/controllers_api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { LegacyNetworkInfoCards } from './LegacyNetworkInfoCards';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/legacy-ssids',
});

export default function LegacyNetworkInfo() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacyNetworkInfo),
  );

  const networkPass = useQuery(
    ['controllers', controllerName, 'legacy-network-info'],
    async () => fetchNetworkInfoJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(
    networkPass,
    new ControllerRequestFailedError(controllerName, 'network_info'),
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Wireless networks</DrawerTitle>
        <DrawerControls>
          <Button
            variant="tertiary"
            as={Link}
            to={makeDrawerLink(paths.drawers.EditLegacyNetworkInfo, { controllerName })}
          >
            Edit
          </Button>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <LegacyNetworkInfoCards networkPass={networkPass} />
      </DrawerContent>
    </Drawer>
  );
}

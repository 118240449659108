import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';

import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import Icon from '../../assets/Icon/Icon';
import {
  colors,
  css,
  darkThemeSelector,
  focusVisibleSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';
import { useCanScrollX } from './useCanScrollX';

const TableContainer = styled('div', {
  width: '100%',
});

const TableBase = styled('div', {
  position: 'relative',
  display: 'table',
  width: '100%',
});

const Table = React.forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement>>>(
  ({ children, ...props }, ref) => {
    const [scrollRef, canScrollX] = useCanScrollX();
    return (
      <TableContainer {...props} ref={mergeRefs([ref, scrollRef])} data-can-scroll-x={canScrollX}>
        <TableBase>{children}</TableBase>
      </TableContainer>
    );
  },
);

const Head = styled('div', {
  display: 'table-header-group',
});

const Body = styled('div', {
  display: 'table-row-group',
});

const CellBase = css({
  display: 'table-cell',
  verticalAlign: 'middle',
  padding: '$8',
  position: 'relative',
  color: '$$rowTextColor',
  boxShadow: '$$rowShadow',
  zIndex: '$$rowZIndex',

  variants: {
    sizingMode: {
      'fit-min': {
        width: '0%',
        whiteSpace: 'nowrap',
      },
      'fit-max': {
        whiteSpace: 'nowrap',
      },
      wrap: {
        maxWidth: 1,
      },
      truncate: {
        maxWidth: 1,
        truncate: true,
      },
    },
    alignment: {
      start: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      end: {
        textAlign: 'right',
      },
    },
  },
});

const RowBase = styled('div', {
  $$rowBackgroundColor: colors.white,
  $$rowIconColor: colors['gray-400'],
  $$rowTextColor: colors['gray-800'],
  $$leadingColumnTextColor: colors['gray-900'],
  $$rowShadow: shadows.fenceTopBottomLight,
  $$rowZIndex: 0,
  $$iconColor: colors['gray-700'],

  [focusVisibleSelector]: {
    '&:focus': {
      $$rowZIndex: 2,
      $$rowShadow: shadows.rowFocusedTopBottomLight,
    },
  },

  [darkThemeSelector]: {
    $$rowBackgroundColor: colors['gray-800'],
    $$rowIconColor: colors['gray-300'],
    $$rowTextColor: colors['gray-50'],
    $$leadingColumnTextColor: colors.white,
    $$rowShadow: shadows.fenceTopBottomDark,
    $$iconColor: colors['gray-200'],

    [focusVisibleSelector]: {
      '&:focus': {
        $$rowShadow: shadows.rowFocusedTopBottomDark,
      },
    },
  },

  position: 'relative',
  width: '100%',
  outline: 'none',
  display: 'table-row',
  backgroundColor: '$$rowBackgroundColor',

  variants: {
    isSelected: {
      true: {
        $$rowBackgroundColor: colors['brand-50'],
        $$rowTextColor: colors['brand-800'],
        $$iconColor: colors['brand-700'],
        $$leadingColumnTextColor: colors['brand-900'],
        $$rowIconColor: colors['brand-600'],
        $$rowShadow: shadows.rowActiveTopBottomLight,
        $$rowZIndex: 1,

        [darkThemeSelector]: {
          $$rowBackgroundColor: colors['brand-900'],
          $$rowTextColor: colors['brand-50'],
          $$iconColor: colors['brand-200'],
          $$leadingColumnTextColor: colors['brand-50'],
          $$rowIconColor: colors['brand-500'],
          $$rowShadow: shadows.rowActiveTopBottomDark,
        },
      },
      false: {},
    },
  },
});

interface TableRowProps {
  isSelected?: boolean;
  children?: React.ReactNode | undefined;
}

const RowInner = <Tag extends React.ElementType>(
  { isSelected, ...props }: PolymorphicComponentProps<Tag, TableRowProps>,
  ref: PolymorphicRef<Tag>,
) => <RowBase ref={ref} {...props} isSelected={isSelected} />;

const Row = React.forwardRef(RowInner) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  TableRowProps
>;

const BaseHeadRow = styled(RowBase, {
  position: 'sticky',
  top: -1,
  zIndex: 0,
  $$leadingColumnTextColor: colors['gray-600'],
  $$rowTextColor: colors['gray-600'],

  [darkThemeSelector]: {
    $$leadingColumnTextColor: colors['gray-100'],
    $$rowTextColor: colors['gray-100'],
  },

  '&[data-is-pinned="true"]': {
    zIndex: 3,
  },
});

type HeadRowProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const HeadRowInner = (props: HeadRowProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
      threshold: [1],
    });

    if (innerRef.current) {
      observer.observe(innerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return <BaseHeadRow {...props} ref={mergeRefs([ref, innerRef])} data-is-pinned={isPinned} />;
};

const HeadRow = React.forwardRef<HTMLDivElement, HeadCellProps>(HeadRowInner);

const HeadCellBase = styled('div', CellBase, {
  padding: '$6 $8',
  fontWeight: fontWeights.medium,
  fontFamily: fonts.sans,
  fontSize: '$12',
  lineHeight: '$16',
});

const HeadCellContent = styled('div', {
  truncate: true,
});

const TableHeadCellSortIcon = styled(Icon, {
  marginTop: -3,
  marginLeft: '$4',
  width: '$10',
  height: '$10',
  variants: {
    isVisible: {
      true: {
        // TRICKY: Toggle visibility rather than display to always reserve
        // space in the layout for the icon.
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
});

type HeadCellProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & { sortDirection?: 'ascending' | 'descending' }
>;

const HeadCellInner = (
  { children, sortDirection, ...props }: HeadCellProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <HeadCellBase {...props} ref={ref}>
    <HeadCellContent>
      {children}
      <TableHeadCellSortIcon
        isVisible={sortDirection !== undefined}
        icon={sortDirection === 'ascending' ? 'chevronUp' : 'chevronDown'}
      />
    </HeadCellContent>
  </HeadCellBase>
);

const HeadCell = React.forwardRef<HTMLDivElement, HeadCellProps>(HeadCellInner);

const CellInnerBase = styled('div', CellBase, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$14',
  lineHeight: '$16',
  truncate: true,

  variants: {
    isLeading: {
      true: {
        color: '$$leadingColumnTextColor',
        fontWeight: fontWeights.medium,
      },
      false: {},
    },
  },
});

type CellProps = PropsWithChildren<HTMLAttributes<HTMLDivElement> & { isLeading?: boolean }>;

const CellInner = (
  { children, isLeading, ...props }: CellProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <CellInnerBase {...props} isLeading={isLeading} ref={ref}>
    {children}
  </CellInnerBase>
);

const Cell = React.forwardRef<HTMLDivElement, CellProps>(CellInner);

const NavigableIcon = styled(Icon, {
  color: '$$rowIconColor',
  // Nudge icon to center it vertically
  marginTop: -1,
  width: '$8',
  height: '$8',
});

const SelectedRowIndicatorHeadCell = () => <HeadCellBase style={{ padding: 0 }} />;

const BaseSelectedRowIndicatorCell = styled(Cell, {
  boxSizing: 'content-box',
  maxWidth: '0',
  minWidth: '0',
  width: '0%',
});

const SelectedRowIndicator = styled('div', {
  position: 'absolute',
  left: '$12',
  top: '$6',
  bottom: '$6',
  width: '$4',
  borderRadius: '$2',
  variants: {
    isSelected: {
      true: {
        backgroundColor: colors['brand-500'],
      },
    },
  },
});

const SelectedRowIndicatorCell = ({ isSelected = false }: { isSelected?: boolean }) => (
  <BaseSelectedRowIndicatorCell>
    <SelectedRowIndicator isSelected={isSelected} />
  </BaseSelectedRowIndicatorCell>
);

const BaseSelectRowCell = styled(Cell, {
  paddingRight: '$20',
  width: '1%',
  whiteSpace: 'nowrap',
});

const SelectRowCell = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <BaseSelectRowCell {...props}>
    <NavigableIcon icon="chevronRight" />
  </BaseSelectRowCell>
);

const DeselectRowCell = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <BaseSelectRowCell {...props}>
    <NavigableIcon icon="cross" />
  </BaseSelectRowCell>
);

export const Table2 = {
  Table,
  Head,
  HeadRow,
  HeadCell,
  Body,
  Row,
  Cell,
  SelectedRowIndicatorHeadCell,
  SelectedRowIndicatorCell,
  SelectRowCell,
  DeselectRowCell,
};

import type { ReactNode } from 'react';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import React from 'react';

import Icon from '../../assets/Icon/Icon';
import { colors, darkThemeSelector, keyframes, styled } from '../../stitches.config';
import { List } from './List';
import { ListContent } from './ListContent';
import { ListItemHeader } from './ListItemHeader';

const BaseCollapsibleListArrow = styled(Icon, {
  width: 8,
  height: 8,
  color: colors['gray-600'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
});

export const CollapsibleListArrow = () => <BaseCollapsibleListArrow icon="chevronDown" />;

const BaseCollapsibleListItemHeader = styled(CollapsiblePrimitive.Trigger, ListItemHeader, {
  '[^data-disabled] &': {
    cursor: 'pointer',
  },
  userSelect: 'none',
  [`& > ${BaseCollapsibleListArrow}`]: {
    transition: 'transform 75ms ease-out',
    transform: 'rotate(-90deg)',
  },
  '&[data-state="open"]': {
    [`& > ${BaseCollapsibleListArrow}`]: {
      transform: 'rotate(0)',
    },
  },
});

export const CollapsibleListItemHeader = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.Trigger>,
  React.ComponentProps<typeof CollapsiblePrimitive.Trigger>
>(({ children, ...props }, forwardedRef) => (
  <BaseCollapsibleListItemHeader {...props} ref={forwardedRef}>
    <CollapsibleListArrow />
    {children}
  </BaseCollapsibleListItemHeader>
));

const openKeyframes = keyframes({
  '0%': {
    height: 0,
    clipPath: 'inset(0)',
  },
  // TRICKY: Use clip-path to hide the content until it is fully expanded.
  '99.99%': {
    clipPath: 'inset(0)',
  },
  '100%': {
    height: 'var(--radix-collapsible-content-height)',
    clipPath: 'inset(-100vh -100vw)',
  },
});

const closeKeyframes = keyframes({
  from: {
    height: 'var(--radix-collapsible-content-height)',
  },
  to: {
    height: 0,
  },
});

const BaseCollapsibleListContent = styled(ListContent, {
  '&[data-state="open"]': { animation: `${openKeyframes} 150ms ease-out forwards` },
  '&[data-state="closed"]': {
    animation: `${closeKeyframes} 150ms ease-out forwards`,
    clipPath: 'inset(0)',
  },
});

export const CollapsibleListContent = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentProps<'div'>
>((props, forwardedRef) => (
  // TRICKY: We could combine BaseCollapsibleListContent and
  // CollapsiblePrimitive.Content as one styled() component rather than use the
  // asChild prop. However, in that configuration, Radix doesn't remove the
  // rendered element from the DOM in the closed state, causing :last-child
  // selectors to not apply to the last visible item.
  <CollapsiblePrimitive.Content asChild>
    <BaseCollapsibleListContent {...props} ref={forwardedRef} />
  </CollapsiblePrimitive.Content>
));

interface CollapsibleListProps {
  children: ReactNode;
  defaultOpen?: boolean;
  disabled?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
}

const BaseCollapsibleList = styled(CollapsiblePrimitive.Root, List);

export const CollapsibleList = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.Root>,
  CollapsibleListProps
>((props, forwardedRef) => <BaseCollapsibleList {...props} ref={forwardedRef} />);

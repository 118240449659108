import type { api } from '@meterup/proto';
import { checkDefinedOrThrow } from '@meterup/common';
import {
  Button,
  MinList,
  MinListControls,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  Small2,
} from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { useCurrentTimezone } from '../../providers/CurrentTimezoneProvider';
import { makeDrawerLink } from '../../utils/makeLink';
import { TimestampWithTimezone, TimezoneAbbreviation } from '../timestamps';
import { IncidentCategoryBadge } from './IncidentCategoryBadge';

export default function IncidentDetailContent({
  controllerName,
  incident,
}: {
  incident: api.ControllerIncident;
  controllerName: string;
}) {
  const sid = checkDefinedOrThrow(incident.sid);
  const timezone = useCurrentTimezone();

  const tz = TimezoneAbbreviation(timezone);

  return (
    <>
      <MinList>
        <MinListItemHeader icon="clock">
          <MinListTitle>Duration</MinListTitle>
          <MinListControls>
            <Button
              as={Link}
              to={makeDrawerLink(paths.drawers.IncidentEditDuration, {
                controllerName,
                id: sid,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          </MinListControls>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Start time ({tz})</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <TimestampWithTimezone value={incident.start_time || ''} timezone={timezone} />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>End time ({tz})</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <TimestampWithTimezone value={incident.end_time || ''} timezone={timezone} />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>

      <MinList>
        <MinListItemHeader icon="information">
          <MinListTitle>Details</MinListTitle>
          <MinListControls>
            <Button
              as={Link}
              to={makeDrawerLink(paths.drawers.IncidentEditDetails, {
                controllerName,
                id: sid,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          </MinListControls>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Category</MinListItemLabel>
          <MinListItemValue>
            <IncidentCategoryBadge category={incident.category || ''} />
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Notes</MinListItemLabel>
          <MinListItemValue>
            <Small2>{incident.notes}</Small2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>

      <MinList>
        <MinListItemHeader>
          <MinListTitle>History</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Created at ({tz})</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <TimestampWithTimezone value={incident.created_at || ''} timezone={timezone} />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Updated at ({tz})</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <TimestampWithTimezone value={incident.updated_at || ''} timezone={timezone} />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Created by</MinListItemLabel>
          <MinListItemValue>
            <Small2>{incident.created_by_email}</Small2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>
    </>
  );
}

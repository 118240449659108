import { styled } from '../../stitches.config';
import ListItem from './ListItem';

export const ListContent = styled('div', {
  [`&:not(:first-child) ${ListItem}:first-child`]: {
    borderRadiusTop: 0,
  },
  [`&:not(:last-child) ${ListItem}:last-child`]: {
    borderRadiusBottom: 0,
  },
});

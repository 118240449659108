import type { ReactNode } from 'react';
import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { ToggleInputProps } from '../../ToggleInput/ToggleInput';
import type { BaseFieldProps } from './common/types';
import { styled } from '../../../stitches.config';
import ListItem from '../../List/ListItem';
import ToggleInput from '../../ToggleInput/ToggleInput';
import { ErrorMessage, FieldDescription, PrimaryFieldLabel } from './common/styles';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const Container = styled(ListItem, {
  display: 'grid',
  paddingY: '$12',
  gap: 0,
  columnGap: '$8',
  gridTemplateColumns: 'min-content auto',
  gridTemplateRows: 'fit-content fit-content fit-content',
  gridTemplateAreas: '"control label" "none help-text" "none sub-fields"',
});

const Control = styled('div', {
  display: 'flex',
  gridArea: 'control',
});

const Label = styled('div', {
  vStack: 0,
  width: '100%',
  alignItems: 'stretch',
  gridArea: 'label',
});

const HelpText = styled('div', {
  gridArea: 'help-text',
  vStack: 0,
  alignItems: 'stretch',
});

const SubFieldsContainer = styled('div', {
  gridArea: 'sub-fields',
  marginTop: '$8',
  vStack: '$8',
  alignItems: 'stretch',
  width: '100%',
});

export interface PrimaryToggleFieldProps extends BaseFieldProps, ToggleInputProps {
  subFields?: ReactNode;
}

export const PrimaryToggleField = (props: PrimaryToggleFieldProps) => {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    errorMessage = customFieldProps.errorMessage,
    description,
    subFields,
    ...extraInputProps
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  return (
    <MaybeFieldContainer>
      <Container>
        <Control>
          <ToggleInput
            controlSize="small"
            {...mergeProps(customInputProps, inputProps, extraInputProps)}
          />
        </Control>

        <Label>
          <PrimaryFieldLabel {...labelProps}>{label}</PrimaryFieldLabel>
        </Label>

        <HelpText>
          {description && <FieldDescription {...descriptionProps}>{description}</FieldDescription>}
        </HelpText>

        {subFields && <SubFieldsContainer>{subFields}</SubFieldsContainer>}
      </Container>

      {errorMessage && <ErrorMessage {...errorMessageProps}>{errorMessage}</ErrorMessage>}
    </MaybeFieldContainer>
  );
};

import type { HTMLAttributes } from 'react';
import React from 'react';

import Icon from '../../assets/Icon/Icon';
import { styled } from '../../stitches.config';
import { TableDataCell, TableHeadCell } from './Table';

const NavigableIcon = styled(Icon, {
  color: '$$rowIconColor',
  // Nudge icon to center it vertically
  marginTop: -3,
  width: '$12',
  height: '$12',
});

export const NavigableTablePlaceholderCell = () => <TableHeadCell aria-hidden />;

export const NavigableTableArrowCell = () => (
  <TableDataCell style={{ width: 0 }} aria-hidden>
    <NavigableIcon icon="chevronRight" />
  </TableDataCell>
);

export const NavigableTableDeselectCell = (props: HTMLAttributes<HTMLDivElement>) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    props.onClick?.(e);
  };

  return (
    <TableDataCell {...props} onClick={handleClick} style={{ width: 0 }} aria-hidden>
      <NavigableIcon icon="crossCircle" />
    </TableDataCell>
  );
};

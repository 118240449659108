import React from 'react';

import { space } from '../../utilities/shared/sizes';
import HStack from '../../utilities/Stack/HStack';

export type TabsProps = {
  /**
   * Pass `Tab` components into this for automatically laid out tabs.
   * */
  children: React.ReactNode;
};

export const Tabs: React.FunctionComponent<TabsProps> = ({ children }) => (
  <HStack role="tablist" align="center" spacing={space(6)}>
    {children}
  </HStack>
);

export default Tabs;

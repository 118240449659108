import React from 'react';

import type { TextComponent, TextProps } from '../utilities/Text/Text';
import { fonts, fontWeights, styled } from '../stitches.config';
import Text, { Text2 } from '../utilities/Text/Text';

export type SubheadingProps<Tag extends React.ElementType = 'h3'> = TextProps<Tag>;

export const Subheading: TextComponent = <Tag extends React.ElementType = 'span'>({
  as,
  children,
  color = {
    light: 'gray-800',
    dark: 'white',
  },
  family = 'regular',
  weight = 'medium',
  ...remaining
}: SubheadingProps<Tag>) => (
  <Text
    {...remaining}
    as={(as || 'h3') as 'h3'}
    color={{ light: color.light, dark: color.dark }}
    family={family}
    lineHeight={24}
    size={16}
    weight={weight}
  >
    {children}
  </Text>
);

export const Subheading2 = styled('h3', Text2, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.medium,
  fontSize: '$16',
  lineHeight: '$24',
});

export default Subheading;

import type { SelectItemProps } from '@radix-ui/react-select';
import * as SelectPrimitive from '@radix-ui/react-select';
import React, { forwardRef } from 'react';

import type { ButtonProps } from '../Button/Button';
import Icon from '../../assets/Icon/Icon';
import {
  contentStyles,
  itemIndicatorStyles,
  itemStyles,
  labelStyles,
  scrollButtonStyles,
  separatorStyles,
} from '../../common/menus';
import { styled } from '../../stitches.config';
import { Small2 } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';
import Button from '../Button/Button';

const StyledContent = styled(SelectPrimitive.Content, contentStyles, {
  minWidth: 140,
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: '$4',
});

const StyledItem = styled(SelectPrimitive.Item, itemStyles);

const StyledLabel = styled(SelectPrimitive.Label, Small2, labelStyles);

const StyledSeparator = styled(SelectPrimitive.Separator, separatorStyles);

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, itemIndicatorStyles);

const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton, scrollButtonStyles);

const StyledScrollDownButton = styled(SelectPrimitive.ScrollDownButton, scrollButtonStyles);

export const Select = SelectPrimitive.Root;
export const SelectTrigger = SelectPrimitive.Trigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = SelectPrimitive.Icon;
export const SelectContent = StyledContent;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <StyledItem {...props} ref={forwardedRef}>
      {children}
      <SelectItemIndicator>
        <Icon icon="checkmark" size={space(12)} />
      </SelectItemIndicator>
    </StyledItem>
  ),
);

export const SelectTriggerButton = forwardRef<HTMLButtonElement, ButtonProps>(
  // eslint-disable-next-line react/prop-types
  ({ menuArrow = 'select', ...props }, forwardedRef) => (
    <SelectTrigger asChild>
      <Button {...props} ref={forwardedRef} menuArrow={menuArrow} />
    </SelectTrigger>
  ),
);

export const SelectScrollUpButton = () => (
  <StyledScrollUpButton>
    <Icon icon="chevronUp" size={space(10)} />
  </StyledScrollUpButton>
);

export const SelectScrollDownButton = () => (
  <StyledScrollDownButton>
    <Icon icon="chevronDown" size={space(10)} />
  </StyledScrollDownButton>
);

import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  ResourceNotFoundError,
  UserRoleBadge,
} from '@meterup/common';
import { Button, HStack, Link, space } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import { fetchCompanyUsersJSON } from '../../../api/users_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { Page, PageControls, PageHeader, PageTitle } from '../../../components/Page';
import { TimestampWithTimezone } from '../../../components/timestamps';
import { UserProfilePicture } from '../../../components/UserProfilePicture';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useCurrentTimezone } from '../../../providers/CurrentTimezoneProvider';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';
import { formatFullName } from '../../../utils/users';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users',
});

const CellUserEmail = ({ value, row }: CellProps<api.UserResponse, string | null>) => (
  <HStack spacing={space(8)}>
    <UserProfilePicture email={row.original.email} /> {value}
  </HStack>
);

export default function CompanyUsers() {
  const timezone = useCurrentTimezone();

  const columns: Column<api.UserResponse>[] = [
    {
      Header: 'Email',
      accessor: (row) => row.email,
      Cell: CellUserEmail,
    },
    {
      Header: 'Role',
      accessor: (row) => row.company_role,
      Cell: UserRoleBadge,
    },
    {
      Header: 'Name',
      accessor: (row) => formatFullName(row),
    },
    {
      Header: 'Created at',
      accessor: (row) => row.created_at,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: CellProps<api.UserResponse, string>) => (
        <TimestampWithTimezone value={props.value} timezone={timezone} />
      ),
    },
  ];

  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.CompanyUsers),
  );

  const users = useQuery(
    ['company', companyName, 'users'],
    () => fetchCompanyUsersJSON(companyName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    users,
    new ResourceNotFoundError(`Users for company ${companyName} not found`),
  );

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.UserDetail);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          Users on company{' '}
          <Link
            as={RouterLink}
            to={Nav.makeTo({
              root: makeLink(paths.pages.CompaniesList, {}),
              drawer: makeLink(paths.drawers.CompanySummary, { companyName }),
            })}
          >
            {companyName}
          </Link>
        </PageTitle>
        <PageControls>
          <Button
            as={RouterLink}
            variant="secondary"
            to={makeDrawerLink(paths.drawers.UsersAdd, { companyName })}
          >
            Add users
          </Button>
        </PageControls>
      </PageHeader>
      <AutoTable
        columns={columns}
        data={users}
        onRowDeselect={closeDrawer}
        isRowSelected={(row) => isDefined(drawerParams) && row.sid === drawerParams.userSid}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.UserDetail, {
            companyName,
            userSid: row.sid,
          }),
        })}
      />
    </Page>
  );
}

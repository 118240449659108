import React from 'react';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const Container = styled('div', {
  hStack: '$2',
  background: colors.white,
  boxShadow: shadows.fenceTopLight,
  padding: '$6 $12',
  [darkThemeSelector]: {
    background: colors['gray-800'],
    boxShadow: shadows.fenceTopDark,
  },
});

export interface TabBarProps {}

export const TabBar: React.FunctionComponent<TabBarProps> = ({ children }) => (
  <Container>{children}</Container>
);

export default TabBar;

import {
  isDefinedAndNotEmpty,
  NetworkInterfaceBadge,
  ServicePlanProductBadge,
  ServicePlanStatusBadge,
} from '@meterup/common';
import {
  Badge,
  Body2,
  CollapsibleList,
  CollapsibleListContent,
  CollapsibleListItemHeader,
  List,
  ListControls,
  ListItem,
  ListItemHeader,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { getProvider } from '../../../../../api/controllers_api';
import { styled } from '../../../../../stitches';
import { formatUSD, usdCents } from '../../../../../utils/currencies';
import {
  LEGACY_formatDataRateBits,
  LEGACY_kilobitsPerSecond,
} from '../../../../../utils/data_rates';
import { FirstAndLastAddresses } from './FirstAndLastAddresses';
import { usePlanDetails } from './usePlanDetails';

const Notes = styled('div', Body2, { whiteSpace: 'pre-line' });

export default function PlanDetails({
  controllerName,
  planId,
}: {
  controllerName: string;
  planId: string;
}) {
  const planDetails = usePlanDetails(controllerName, planId);

  const provider = useQuery(
    ['provider', planDetails.provider],
    () => getProvider(planDetails.provider),
    { suspense: true },
  ).data;

  const isStaticIP = isDefinedAndNotEmpty(planDetails.static_ip_range);

  return (
    <>
      <CollapsibleList defaultOpen>
        <CollapsibleListItemHeader>
          <ListTitle>Plan details</ListTitle>
        </CollapsibleListItemHeader>
        <CollapsibleListContent>
          <ListItemPair>
            <ListItemLabel>Provider</ListItemLabel>
            <ListItemValue>
              {provider?.name ?? (
                <Badge variant="negative" size="small">
                  Provider not found
                </Badge>
              )}
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Product</ListItemLabel>
            <ListItemValue>
              <ServicePlanProductBadge value={planDetails.product} />
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Status</ListItemLabel>
            <ListItemValue>
              <ServicePlanStatusBadge value={planDetails.status} />
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Account #</ListItemLabel>
            <ListItemValue>{planDetails.account_number}</ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Download speed</ListItemLabel>
            <ListItemValue>
              {LEGACY_formatDataRateBits(planDetails.download_kbps, LEGACY_kilobitsPerSecond)}
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Upload speed</ListItemLabel>
            <ListItemValue>
              {LEGACY_formatDataRateBits(planDetails.upload_kbps, LEGACY_kilobitsPerSecond)}
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Monthly price</ListItemLabel>
            <ListItemValue>{formatUSD(planDetails.monthly_cost_cents, usdCents)}</ListItemValue>
          </ListItemPair>
        </CollapsibleListContent>
      </CollapsibleList>

      {isDefinedAndNotEmpty(planDetails.notes) && (
        <CollapsibleList defaultOpen>
          <CollapsibleListItemHeader>
            <ListTitle>Notes</ListTitle>
          </CollapsibleListItemHeader>
          <CollapsibleListContent>
            <ListItem>
              <Notes>{planDetails.notes}</Notes>
            </ListItem>
          </CollapsibleListContent>
        </CollapsibleList>
      )}

      {isStaticIP ? (
        <CollapsibleList>
          <CollapsibleListItemHeader>
            <ListTitle>IP assignment</ListTitle>
          </CollapsibleListItemHeader>
          <CollapsibleListContent>
            <ListItemPair>
              <ListItemLabel>Range</ListItemLabel>
              <ListItemValue>{planDetails.static_ip_range}</ListItemValue>
            </ListItemPair>
            <ListItemPair>
              <ListItemLabel>Gateway address</ListItemLabel>
              <ListItemValue>{planDetails.gateway_addr}</ListItemValue>
            </ListItemPair>
            <ListItemPair>
              <ListItemLabel>Controller address</ListItemLabel>
              <ListItemValue>{planDetails.controller_ip}</ListItemValue>
            </ListItemPair>
            <FirstAndLastAddresses ipRange={planDetails.static_ip_range} />
          </CollapsibleListContent>
        </CollapsibleList>
      ) : (
        <List>
          <ListItemHeader>
            <ListTitle>IP assignment</ListTitle>
            <ListControls>
              <Badge size="small">Dynamic</Badge>
            </ListControls>
          </ListItemHeader>
        </List>
      )}

      <List>
        <ListItemHeader>
          <ListTitle>Network interface</ListTitle>
          <ListControls>
            <NetworkInterfaceBadge value={planDetails.network_interface} />
          </ListControls>
        </ListItemHeader>
      </List>
    </>
  );
}

import React from 'react';

import type { StackProps } from './Stack';
import Stack from './Stack';

export type VStackProps = StackProps<'div'> & {
  children: React.ReactNode;
  reverse?: boolean;
};

export const VStack: React.FunctionComponent<VStackProps> = ({
  children,
  reverse,
  ...remaining
}) => (
  <Stack {...remaining} direction={reverse ? 'column-reverse' : 'column'}>
    {children}
  </Stack>
);

export default VStack;

import React from 'react';

import type { StackProps } from './Stack';
import Stack from './Stack';

export type HStackProps = StackProps<'div'> & {
  children: React.ReactNode;
  reverse?: boolean;
};

export const HStack: React.FunctionComponent<HStackProps> = ({
  children,
  reverse,
  ...remaining
}) => (
  <Stack {...remaining} direction={reverse ? 'row-reverse' : 'row'}>
    {children}
  </Stack>
);

export default HStack;

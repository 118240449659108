import { paths } from './constants';
import { createDrawerRedirect, createRedirect } from './utils/createRedirect';

export const rootRedirects = [
  createRedirect('/networks/:controllerName', paths.pages.ControllerDetails),
  createRedirect('/networks/:controllerName/clients', paths.pages.ControllerClientsList),
  createRedirect('/networks/:controllerName/config', paths.pages.ControllerConfig),
  createRedirect('/networks/:controllerName/devices', paths.pages.ControllerDevicesList),
  createRedirect(
    '/networks/:controllerName/devices/:deviceName/clients',
    paths.pages.ControllerDeviceClients,
  ),
  createRedirect('/networks/:controllerName/logs', paths.pages.ControllerLogs),
  createRedirect('/networks/:controllerName/plans', paths.pages.ControllerServicePlans),
  createRedirect('/networks/:controllerName/stats', paths.pages.ControllerStats),
];

export const drawerRedirects = [
  createDrawerRedirect('/networks/:controllerName/clients/:mac', paths.drawers.ClientDetail),
  createDrawerRedirect('/networks/:controllerName/plans/:planId', paths.drawers.ISPDetails),
  createDrawerRedirect('/networks/:controllerName/plans/:planId/delete', paths.drawers.ISPDelete),
  createDrawerRedirect('/networks/:controllerName/plans/:planId/edit', paths.drawers.ISPEdit),
  createDrawerRedirect('/networks/:controllerName/plans/new', paths.drawers.ISPCreate),
];

import React from 'react';

import DeviceIconLarge from './icons/DeviceIconLarge';
import DeviceIconMedium from './icons/DeviceIconMedium';
import DeviceIconSmall from './icons/DeviceIconSmall';

export enum DeviceIconSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export interface DeviceIconProps {
  /**
   * Set a size of the component.
   */
  size: 'large' | 'medium' | 'small';
}

export const DeviceIcon = ({ size }: DeviceIconProps) =>
  ({
    large: <DeviceIconLarge />,
    medium: <DeviceIconMedium />,
    small: <DeviceIconSmall />,
  }[size]);

export default DeviceIcon;

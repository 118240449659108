import { match } from 'ts-pattern';

import { darkThemeSelector, shadows } from '../../stitches.config';

export type ShadowTypes =
  | 'surface-top'
  | 'surface-right'
  | 'surface-bottom'
  | 'surface-left'
  | 'button-destructive-initial'
  | 'button-destructive-hovered'
  | 'button-destructive-disabled'
  | 'button-primary-initial'
  | 'button-primary-hovered'
  | 'button-primary-disabled'
  | 'button-secondary-initial'
  | 'button-secondary-hovered'
  | 'button-secondary-disabled'
  | 'button-tertiary-initial'
  | 'button-tertiary-hovered'
  | 'button-tertiary-disabled'
  | 'pane-top'
  | 'pane-top-inset'
  | 'pane-right'
  | 'pane-right-inset'
  | 'pane-bottom'
  | 'pane-bottom-inset'
  | 'pane-left'
  | 'pane-left-inset'
  | 'overlay'
  | 'modal';
export type ShadowProps = { shadow?: ShadowTypes };

export const shadowCSS = (shadow?: ShadowTypes) =>
  match(shadow)
    .with('surface-top', () => ({
      boxShadow: shadows.surfaceTopLight,
      [darkThemeSelector]: { boxShadow: shadows.surfaceTopDark },
    }))
    .with('surface-right', () => ({
      boxShadow: shadows.surfaceRightLight,
      [darkThemeSelector]: { boxShadow: shadows.surfaceRightDark },
    }))
    .with('surface-bottom', () => ({
      boxShadow: shadows.surfaceBottomLight,
      [darkThemeSelector]: { boxShadow: shadows.surfaceBottomDark },
    }))
    .with('surface-left', () => ({
      boxShadow: shadows.surfaceLeftLight,
      [darkThemeSelector]: { boxShadow: shadows.surfaceLeftDark },
    }))
    .with('button-destructive-initial', () => ({
      boxShadow: shadows.controlDestructiveInitialLight,
      [darkThemeSelector]: { boxShadow: shadows.controlDestructiveInitialDark },
    }))
    .with('button-destructive-hovered', () => ({
      boxShadow: shadows.controlDestructiveHoveredLight,
      [darkThemeSelector]: { boxShadow: shadows.controlDestructiveHoveredDark },
    }))
    .with('button-destructive-disabled', () => ({
      boxShadow: shadows.controlDestructiveDisabledLight,
      [darkThemeSelector]: { boxShadow: shadows.controlDestructiveDisabledDark },
    }))
    .with('button-primary-initial', () => ({
      boxShadow: shadows.controlPrimaryInitialLight,
      [darkThemeSelector]: { boxShadow: shadows.controlPrimaryInitialDark },
    }))
    .with('button-primary-hovered', () => ({
      boxShadow: shadows.controlPrimaryHoveredLight,
      [darkThemeSelector]: { boxShadow: shadows.controlPrimaryHoveredDark },
    }))
    .with('button-primary-disabled', () => ({
      boxShadow: shadows.controlPrimaryDisabledLight,
      [darkThemeSelector]: { boxShadow: shadows.controlPrimaryDisabledDark },
    }))
    .with('button-secondary-initial', () => ({
      boxShadow: shadows.controlSecondaryInitialLight,
      [darkThemeSelector]: { boxShadow: shadows.controlSecondaryInitialDark },
    }))
    .with('button-secondary-hovered', () => ({
      boxShadow: shadows.controlSecondaryHoveredLight,
      [darkThemeSelector]: { boxShadow: shadows.controlSecondaryHoveredDark },
    }))
    .with('button-secondary-disabled', () => ({
      boxShadow: shadows.controlSecondaryDisabledLight,
      [darkThemeSelector]: { boxShadow: shadows.controlSecondaryDisabledDark },
    }))
    .with('button-tertiary-initial', () => ({
      boxShadow: shadows.controlTertiaryInitialLight,
      [darkThemeSelector]: { boxShadow: shadows.controlTertiaryInitialDark },
    }))
    .with('button-tertiary-hovered', () => ({
      boxShadow: shadows.controlTertiaryHoveredLight,
      [darkThemeSelector]: { boxShadow: shadows.controlTertiaryHoveredDark },
    }))
    .with('button-tertiary-disabled', () => ({
      boxShadow: shadows.controlTertiaryDisabledLight,
      [darkThemeSelector]: { boxShadow: shadows.controlTertiaryDisabledDark },
    }))
    .with('pane-top', () => ({
      boxShadow: shadows.paneTopLight,
      [darkThemeSelector]: { boxShadow: shadows.paneTopDark },
    }))
    .with('pane-top-inset', () => ({
      boxShadow: shadows.paneTopInsetLight,
      [darkThemeSelector]: { boxShadow: shadows.paneTopInsetDark },
    }))
    .with('pane-right', () => ({
      boxShadow: shadows.paneRightLight,
      [darkThemeSelector]: { boxShadow: shadows.paneRightDark },
    }))
    .with('pane-right-inset', () => ({
      boxShadow: shadows.paneRightInsetLight,
      [darkThemeSelector]: { boxShadow: shadows.paneRightInsetDark },
    }))
    .with('pane-bottom', () => ({
      boxShadow: shadows.paneBottomLight,
      [darkThemeSelector]: { boxShadow: shadows.paneBottomDark },
    }))
    .with('pane-bottom-inset', () => ({
      boxShadow: shadows.paneBottomInsetLight,
      [darkThemeSelector]: { boxShadow: shadows.paneBottomInsetDark },
    }))
    .with('pane-left', () => ({
      boxShadow: shadows.paneLeftLight,
      [darkThemeSelector]: { boxShadow: shadows.paneLeftDark },
    }))
    .with('pane-left-inset', () => ({
      boxShadow: shadows.paneLeftInsetLight,
      [darkThemeSelector]: { boxShadow: shadows.paneLeftInsetDark },
    }))
    .with('overlay', () => ({
      boxShadow: shadows.overlayLight,
      [darkThemeSelector]: { boxShadow: shadows.overlayDark },
    }))
    .with('modal', () => ({
      boxShadow: shadows.modalLight,
      [darkThemeSelector]: { boxShadow: shadows.modalDark },
    }))
    .with(undefined, () => ({}))
    .exhaustive();

import type { DOMAttributes } from '@react-types/shared';
import * as React from 'react';
import { DismissButton, FocusScope, mergeProps, useOverlay } from 'react-aria';

import { contentStyles } from '../../common/menus';
import { styled } from '../../stitches.config';

const Container = styled('div', contentStyles, {
  padding: '$4',
  overflow: 'auto',
});

interface PopoverProps {
  popoverRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  isOpen?: boolean;
  onClose: () => void;
}

export const Popover = (props: PopoverProps & DOMAttributes<HTMLDivElement>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { popoverRef = ref, isOpen, onClose, children, ...domProps } = props;

  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: false,
    },
    popoverRef,
  );

  return (
    <FocusScope restoreFocus>
      <Container {...mergeProps(overlayProps, domProps)} ref={popoverRef}>
        {children}
        <DismissButton onDismiss={onClose} />
      </Container>
    </FocusScope>
  );
};

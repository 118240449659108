import React from 'react';

import type { TextComponent, TextProps } from '../utilities/Text/Text';
import { fonts, fontWeights, styled } from '../stitches.config';
import Text, { Text2 } from '../utilities/Text/Text';

export type SmallProps<Tag extends React.ElementType = 'span'> = TextProps<Tag>;

export const Small: TextComponent = <Tag extends React.ElementType = 'span'>({
  children,
  color = {
    light: 'gray-600',
    dark: 'gray-200',
  },
  family = 'regular',
  weight = 'regular',
  ...remaining
}: SmallProps<Tag>) => (
  <Text
    {...remaining}
    color={{ light: color.light, dark: color.dark }}
    family={family}
    lineHeight={16}
    size={12}
    weight={weight}
  >
    {children}
  </Text>
);

export const Small2 = styled('span', Text2, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$12',
  lineHeight: '$16',
});

export default Small;

import type { MatchPathOptions } from '@meterup/react-router-extensions';
import { isDefined } from '@meterup/common';
import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';

import { Nav } from '../components/Nav';

export function useIsPathActiveFn() {
  const location = Nav.useRegionLocation('root');

  return useCallback(
    (path: string, options: MatchPathOptions = { caseSensitive: false, end: false }) =>
      isDefined(matchPath({ ...options, path }, location.pathname)),
    [location.pathname],
  );
}

import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';

import type { ErrorMessageRegistry } from './ErrorMessageRegistry';
import { CompositeAriaContext } from './CompositeAria';
import { ErrorMessageRegistryContext } from './ErrorMessageRegistry';

export const FieldCompositeProvider = ({
  children,
  registerMessage,
  unregisterMessage,
  describedBy,
}: PropsWithChildren<
  {
    describedBy: string;
  } & ErrorMessageRegistry
>) => {
  const describedByPropsValue = useMemo(() => ({ 'aria-describedby': describedBy }), [describedBy]);
  const registryValue = useMemo(
    () => ({ registerMessage, unregisterMessage }),
    [registerMessage, unregisterMessage],
  );

  return (
    <CompositeAriaContext.Provider value={describedByPropsValue}>
      <ErrorMessageRegistryContext.Provider value={registryValue}>
        {children}
      </ErrorMessageRegistryContext.Provider>
    </CompositeAriaContext.Provider>
  );
};

import type { HelpTextProps } from '@react-types/shared/src/inputs';
import type { ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { FieldPropsWithElement } from './common/types';
import { ControlSizeProvider } from '../../../common/control_size';
import { styled } from '../../../stitches.config';
import ListItemPair from '../../List/ListItemPair';
import ListItemValue from '../../List/ListItemValue';
import { ErrorMessages, useErrorMessages } from './common/ErrorMessageRegistry';
import { FieldCompositeProvider } from './common/FieldCompositeProvider';
import { ErrorMessage, FieldDescription, SecondaryFieldLabel } from './common/styles';
import { useDescriptionAndErrorIds } from './common/useDescriptionAndErrorIds';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const Container = styled(ListItemPair, {});

const Label = styled('div', {
  vStack: 0,
  alignItems: 'start',
});

const Control = styled('div', ListItemValue, {
  hStack: '$8',
});

interface SecondaryFieldProps extends FieldPropsWithElement {}

export const SecondaryField = (props: SecondaryFieldProps) => {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    description = customFieldProps.description,
    errorMessage = customFieldProps.errorMessage,
    element,
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  return (
    <MaybeFieldContainer>
      <Container>
        <Label>
          <SecondaryFieldLabel {...labelProps}>{label}</SecondaryFieldLabel>
          {description && <FieldDescription {...descriptionProps}>{description}</FieldDescription>}
        </Label>

        <Control>
          <ControlSizeProvider value="small">
            <Slot {...mergeProps(inputProps, customInputProps)}>{element}</Slot>
          </ControlSizeProvider>
        </Control>
      </Container>

      {errorMessage && <ErrorMessage {...errorMessageProps}>{errorMessage}</ErrorMessage>}
    </MaybeFieldContainer>
  );
};

export type SecondaryFieldCompositeProps = {
  label: ReactNode;
  fields: ReactNode;
} & HelpTextProps;

export const SecondaryFieldComposite = ({
  label,
  fields,
  errorMessage,
  description,
}: SecondaryFieldCompositeProps) => {
  const { messages, registerMessage, unregisterMessage } = useErrorMessages();

  const { descriptionId, errorMessageId, describedBy } = useDescriptionAndErrorIds(
    errorMessage,
    description,
  );

  return (
    <FieldCompositeProvider
      registerMessage={registerMessage}
      unregisterMessage={unregisterMessage}
      describedBy={describedBy}
    >
      <Container>
        <Label>
          <SecondaryFieldLabel>{label}</SecondaryFieldLabel>
          {description && <FieldDescription id={descriptionId}>{description}</FieldDescription>}
        </Label>
        <Control>
          <ControlSizeProvider value="small">{fields}</ControlSizeProvider>
        </Control>
      </Container>

      <ErrorMessages messages={messages} />

      {errorMessage && <ErrorMessage id={errorMessageId}>{errorMessage}</ErrorMessage>}
    </FieldCompositeProvider>
  );
};

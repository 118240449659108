import React from 'react';

export const DeviceIconLarge: React.FunctionComponent<{}> = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="xMidYMid meet"
    style={{
      filter: `
        drop-shadow(-1px 0 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(1px 0 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 -1px 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 1px 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 2px 1px rgba(9, 11, 20, 0.08))
      `,
    }}
  >
    <path
      d="M40 20C40 13.1779 39.5349 5.48108 37.0349 2.97376C34.5349 0.46647 25.5814 0 20.0581 0C14.5349 0 5.58143 0.46647 3.08139 2.97376C0.581393 5.48108 0 13.1779 0 20C0 26.8222 0.5814 34.519 3.0814 37.0263C5.58143 39.5336 14.5349 40 20.0581 40C25.5814 40 34.5349 39.5336 37.0349 37.0263C39.5349 34.519 40 26.8222 40 20Z"
      fill="white"
    />
    <path
      d="M40 20C40 13.1779 39.5349 5.48108 37.0349 2.97376C34.5349 0.46647 25.5814 0 20.0581 0C14.5349 0 5.58143 0.46647 3.08139 2.97376C0.581393 5.48108 0 13.1779 0 20C0 26.8222 0.5814 34.519 3.0814 37.0263C5.58143 39.5336 14.5349 40 20.0581 40C25.5814 40 34.5349 39.5336 37.0349 37.0263C39.5349 34.519 40 26.8222 40 20Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M21.9645 16.5315C21.9638 16.521 21.963 16.5105 21.9624 16.5H21.9479C21.9418 16.5051 21.9358 16.5104 21.9298 16.5157C21.9169 16.527 21.904 16.5384 21.8897 16.5474C21.8401 16.5785 21.7907 16.6097 21.7412 16.6409C21.5668 16.7508 21.3924 16.8608 21.2152 16.9661C21.1485 17.0058 21.1285 17.0493 21.1292 17.1239C21.132 17.4418 21.1316 17.7598 21.1312 18.0778V18.079C21.1311 18.1901 21.131 18.3012 21.131 18.4123V18.4997H20.2733V19.238H21.131V19.3294C21.131 19.5555 21.1306 19.7817 21.1303 20.0078C21.1294 20.6195 21.1285 21.2312 21.1342 21.8428C21.1363 22.0557 21.1576 22.2728 21.2057 22.48C21.3115 22.9366 21.5904 23.2579 22.0451 23.4171C22.3325 23.5177 22.6282 23.5153 22.9251 23.4702C23.0912 23.4448 23.2508 23.3986 23.3979 23.3078L23.3578 23.1644C23.3055 22.9772 23.2532 22.7904 23.1994 22.5983C23.0314 22.7041 22.8531 22.7508 22.6639 22.758C22.3444 22.7701 22.1098 22.6146 22.022 22.3124C21.9851 22.1852 21.9711 22.0479 21.9704 21.9151C21.967 21.2363 21.9675 20.5575 21.968 19.8787C21.9681 19.6914 21.9683 19.5041 21.9683 19.3167C21.9683 19.3089 21.9686 19.3012 21.969 19.2932C21.9698 19.2754 21.9713 19.2564 21.9726 19.2337H23.2783V18.4944H21.9682V18.404C21.9682 17.8022 21.9682 17.2005 21.9679 16.5987C21.9679 16.5765 21.9662 16.5537 21.9645 16.5315Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.643 21.1223H24.04C24.0416 21.1317 24.0432 21.141 24.0448 21.1504C24.0528 21.1978 24.0602 21.2446 24.0675 21.2911C24.0866 21.4121 24.1053 21.5303 24.1331 21.6458C24.2035 21.9381 24.3366 22.2 24.5476 22.4161C24.7397 22.6128 24.9773 22.7121 25.2467 22.7427C25.4731 22.7684 25.6955 22.7497 25.9146 22.69C26.235 22.6028 26.4512 22.3825 26.624 22.1104C26.6654 22.0451 26.7025 21.977 26.7407 21.907C26.758 21.8752 26.7756 21.8429 26.7939 21.8104C27.0287 21.9134 27.2641 22.0166 27.5082 22.1236C27.4998 22.1404 27.4917 22.157 27.4837 22.1734C27.4806 22.1796 27.4776 22.1858 27.4746 22.192L27.4712 22.1988C27.4663 22.2088 27.4614 22.2187 27.4565 22.2285C27.4498 22.2419 27.443 22.2551 27.4361 22.2683C27.2583 22.6068 27.0393 22.9108 26.7275 23.1378C26.4824 23.316 26.2055 23.4126 25.9112 23.4593C25.59 23.5104 25.2672 23.5193 24.9469 23.4514C24.4141 23.3387 23.9869 23.0607 23.683 22.6006C23.4118 22.1903 23.2704 21.7338 23.2299 21.2437C23.182 20.6657 23.2619 20.1099 23.5141 19.585C23.7852 19.0206 24.2048 18.6258 24.814 18.4699C25.4508 18.3069 26.0647 18.3715 26.6354 18.7172C27.0403 18.9626 27.2943 19.334 27.4653 19.77C27.5933 20.0959 27.6416 20.4362 27.643 20.7849C27.6431 20.8137 27.6431 20.8426 27.6432 20.8716C27.6432 20.9147 27.6431 20.9583 27.6431 21.0031C27.643 21.0418 27.643 21.0814 27.643 21.1223ZM26.7741 20.3706C26.6756 19.7874 26.2725 19.0752 25.3251 19.1394C24.988 19.162 24.6987 19.2884 24.4757 19.5507C24.2751 19.7867 24.1592 20.0628 24.1029 20.3706H26.7741Z"
      fill="#5461C8"
    />
    <path
      d="M30.9649 18.7138C30.9458 18.7884 30.9267 18.8629 30.9078 18.9376C30.8983 18.9746 30.889 19.0116 30.8797 19.0487C30.8669 19.0995 30.855 19.1504 30.8428 19.203C30.8388 19.2199 30.8348 19.2369 30.8308 19.2542L30.8236 19.2846C30.8029 19.2786 30.7869 19.2751 30.7721 19.2691C30.1924 19.0363 29.7218 19.1508 29.3216 19.6192C29.0746 19.9084 28.9475 20.2486 28.8629 20.6074C28.792 20.9081 28.7589 21.2134 28.7582 21.5214C28.7573 21.946 28.7574 22.3706 28.7576 22.7952C28.7576 22.9953 28.7577 23.1954 28.7577 23.3955V23.4866H27.8956V18.4807H28.7559V19.3132C28.7766 19.2846 28.7956 19.257 28.8138 19.2305C28.8515 19.1759 28.886 19.1257 28.9258 19.08C29.2946 18.6553 29.7492 18.3891 30.3403 18.3934C30.5706 18.3953 30.7951 18.4362 31 18.5476V18.5764L30.9649 18.7138Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5135 21.1225H16.9247C16.9422 21.4665 17.011 21.7893 17.1759 22.0861C17.2792 22.272 17.4064 22.4372 17.5859 22.5559C17.8336 22.7194 18.1087 22.7669 18.398 22.7521C18.7276 22.7352 19.0305 22.6505 19.2692 22.4006C19.4155 22.2473 19.532 22.074 19.6237 21.883C19.6284 21.8731 19.6332 21.8633 19.6382 21.853L19.6446 21.84C19.6495 21.83 19.6546 21.8195 19.6601 21.8081C19.7827 21.8619 19.9041 21.9151 20.0265 21.9688L20.383 22.1251C20.3699 22.1496 20.3572 22.1741 20.3445 22.1983C20.3406 22.2059 20.3367 22.2134 20.3328 22.2209C20.2967 22.2903 20.2612 22.3584 20.2223 22.4244C20.0273 22.7545 19.7869 23.0411 19.4508 23.2339C19.2106 23.3716 18.9492 23.4431 18.6779 23.4754C18.3953 23.5089 18.1117 23.5136 17.831 23.455C17.2527 23.3339 16.8037 23.0241 16.4963 22.5108C16.2666 22.1274 16.1408 21.7099 16.1019 21.2646C16.0509 20.6798 16.1287 20.1167 16.3847 19.5847C16.6563 19.0203 17.076 18.6256 17.6856 18.4697C18.3252 18.3063 18.9415 18.3719 19.5138 18.7211C19.9158 18.9665 20.1685 19.3368 20.3381 19.7704C20.4636 20.0915 20.5152 20.4268 20.5135 20.7711C20.5129 20.8844 20.5135 20.9979 20.5135 21.1225ZM19.6503 20.3718C19.5 19.6204 19.0296 19.075 18.1701 19.1409C17.8389 19.1662 17.5555 19.2993 17.3384 19.5597C17.1431 19.7942 17.0287 20.0664 16.9732 20.3718H19.6503Z"
      fill="#5461C8"
    />
    <path
      d="M9 23.5H9.82826V23.41C9.82826 23.2256 9.82783 23.0412 9.8274 22.8567C9.82632 22.3932 9.82524 21.9297 9.83099 21.4662C9.83412 21.2047 9.85399 20.9419 9.88844 20.6826C9.93472 20.3338 10.048 20.0046 10.2459 19.71C10.4407 19.42 10.6857 19.2 11.0487 19.1593C11.4653 19.1126 11.8141 19.2769 11.9158 19.7354C11.953 19.9026 11.9644 20.0784 11.9651 20.2504C11.9681 21.0098 11.9678 21.7692 11.9675 22.5286C11.9674 22.8195 11.9673 23.1103 11.9674 23.4012C11.9674 23.4233 11.9689 23.4454 11.9703 23.4674C11.971 23.4776 11.9716 23.4878 11.9722 23.4979H12.8038V23.4153C12.8038 23.2253 12.8034 23.0353 12.803 22.8453C12.8019 22.3681 12.8008 21.8909 12.8065 21.4137C12.8095 21.1595 12.8293 20.9036 12.8664 20.6522C12.928 20.2369 13.0751 19.8533 13.353 19.53C13.5801 19.2656 13.8646 19.1299 14.2149 19.1541C14.5035 19.1741 14.7187 19.3083 14.8366 19.5819C14.9194 19.7738 14.9356 19.9773 14.9357 20.1826C14.9364 20.9718 14.9363 21.761 14.9362 22.5503C14.9362 22.8377 14.9362 23.1252 14.9362 23.4127V23.499H15.7659C15.7671 23.4747 15.7692 23.4537 15.7692 23.4326C15.7692 23.1631 15.7694 22.8936 15.7695 22.624C15.77 21.735 15.7706 20.8458 15.7666 19.9568C15.7658 19.7777 15.7454 19.5953 15.7068 19.4206C15.6228 19.0396 15.4257 18.7292 15.0701 18.5486C14.6714 18.3461 14.2522 18.3443 13.8329 18.4711C13.3616 18.6136 13.029 18.9327 12.7792 19.3481C12.7642 19.373 12.7491 19.3979 12.7283 19.4323C12.7215 19.4086 12.7165 19.3907 12.712 19.3751C12.7093 19.3656 12.7067 19.3569 12.704 19.3483C12.5822 18.9524 12.349 18.6523 11.9592 18.4948C11.657 18.3727 11.3425 18.3605 11.027 18.4275C10.5064 18.5383 10.1363 18.8526 9.87605 19.3091C9.86881 19.3218 9.86162 19.3345 9.85444 19.3472C9.84961 19.3558 9.84479 19.3643 9.83997 19.3729C9.83754 19.3719 9.8351 19.3709 9.83265 19.3699C9.83044 19.369 9.82821 19.3681 9.82595 19.3673V18.4804H9V23.5Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7273 4.18184C23.1289 4.18184 23.4546 3.85623 23.4546 3.45457C23.4546 3.05291 23.1289 2.72729 22.7273 2.72729C22.3256 2.72729 22 3.05291 22 3.45457C22 3.85623 22.3256 4.18184 22.7273 4.18184ZM17.2727 4.18185C17.6744 4.18185 18 3.85624 18 3.45457C18 3.05291 17.6744 2.7273 17.2727 2.7273C16.8711 2.7273 16.5455 3.05291 16.5455 3.45457C16.5455 3.85624 16.8711 4.18185 17.2727 4.18185Z"
      fill="#33C44A"
    />
    <path
      d="M15.2727 3.45457C15.2727 3.85623 14.9471 4.18184 14.5454 4.18184C14.1438 4.18184 13.8182 3.85623 13.8182 3.45457C13.8182 3.05291 14.1438 2.72729 14.5454 2.72729C14.9471 2.72729 15.2727 3.05291 15.2727 3.45457Z"
      fill="#6977E1"
    />
    <path
      d="M20.7273 3.45457C20.7273 3.85623 20.4017 4.18184 20 4.18184C19.5983 4.18184 19.2727 3.85623 19.2727 3.45457C19.2727 3.05291 19.5983 2.72729 20 2.72729C20.4017 2.72729 20.7273 3.05291 20.7273 3.45457Z"
      fill="#6977E1"
    />
    <path
      d="M25.4545 4.18184C25.8562 4.18184 26.1818 3.85623 26.1818 3.45457C26.1818 3.05291 25.8562 2.72729 25.4545 2.72729C25.0529 2.72729 24.7273 3.05291 24.7273 3.45457C24.7273 3.85623 25.0529 4.18184 25.4545 4.18184Z"
      fill="#6977E1"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="36.4825"
        y1="1.51603"
        x2="15.2449"
        y2="44.924"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.03" />
        <stop offset="0.500255" stopColor="#E1E4EC" stopOpacity="0.11" />
        <stop offset="1" stopColor="#D4DAE5" stopOpacity="0.56" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeviceIconLarge;

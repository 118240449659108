import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';

import { keyframes, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled('div', Body2, {
  marginLeft: '$16',
});

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement>
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));

export default AccordionContent;

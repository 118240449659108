import type { api } from '@meterup/proto';
import { makeAPICall, NoConnectionError } from '@meterup/common';
import axios from 'axios';

import { logError } from '../utils/logError';
import { axiosInstanceJSON } from './api_clients';

export const getIdentity = async () =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<api.IdentityResponse>('/v1/identity');
    return result.data;
  });

export const logoutUser = async (): Promise<void> => {
  try {
    await axiosInstanceJSON.post(`/v1/logout`);
  } catch (e) {
    logError(e);

    throw e;
  }
};

export const createSession = async (
  identity: api.IdentityResponse,
): Promise<api.SessionCreateResponse> => {
  const req: api.SessionCreateRequest = {
    username: identity.username,
    permission: ['noc'],
    name: identity.username.split('@')[0], // this will be an email
    user_first_name: identity.first_name!,
    user_last_name: identity.last_name!,
    user_profile_picture_url: '',
  };
  return makeAPICall(async () => {
    const result = await axiosInstanceJSON.post<api.SessionCreateResponse>('/v1/sessions', req);
    return result.data;
  });
};

export const createCLISession = async () => {
  const identity = await getIdentity();
  const session = await createSession(identity);
  try {
    await makeAPICall(async () => axios.post('http://localhost:33736/v1/sessions', session));
  } catch (error) {
    // this is the expected error for calling localhost like this
    if (!(error instanceof NoConnectionError)) {
      throw error;
    }
  }
};

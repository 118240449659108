import { breakpoints, darkThemeSelector, theme } from '@meterup/metric';

export const {
  css,
  styled,
  keyframes,
  globalCss,
  theme: { shadows, colors, fonts, fontWeights },
} = theme;

export { breakpoints, darkThemeSelector };

export const focusVisibleClassName = 'focus-visible';
export const focusVisibleSelector = '.focus-visible &';

import React from 'react';

import type { TextComponent, TextProps } from '../utilities/Text/Text';
import { fonts, fontWeights, styled } from '../stitches.config';
import Text, { Text2 } from '../utilities/Text/Text';

export type BodyMonoProps<Tag extends React.ElementType = 'span'> = TextProps<Tag>;

export const BodyMono: TextComponent = <Tag extends React.ElementType = 'span'>({
  children,
  color = {
    light: 'gray-600',
    dark: 'gray-200',
  },
  weight = 'regular',
  ...remaining
}: BodyMonoProps<Tag>) => (
  <Text
    {...remaining}
    color={{ light: color.light, dark: color.dark }}
    family="monospace"
    lineHeight={20}
    size={13}
    weight={weight}
  >
    {children}
  </Text>
);

export const BodyMono2 = styled('span', Text2, {
  fontFamily: fonts.mono,
  fontWeight: fontWeights.regular,
  fontSize: '$13',
  lineHeight: '$20',
});

export default BodyMono;

import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

const Base = styled('div', {
  flexShrink: 0,
  boxSizing: 'border-box',
  borderStyle: 'solid',
  borderRadius: 100,
  variants: {
    variant: {
      alternative: {
        background: colors['blue-50'],
        boxShadow:
          'inset 0px 0px 0px 2px rgba(82, 143, 235, 0.3), inset 0px -8px 12px rgba(255, 255, 255, 0.5), inset 0px 3px 5px rgba(82, 143, 235, 0.3), inset 0px 8px 12px rgba(82, 143, 235, 0.26)',
        [darkThemeSelector]: {
          boxShadow: 'unset',
          background:
            'linear-gradient(180deg, rgba(66, 128, 222, 0) 0%, rgba(66, 128, 222, 0.5) 100%), radial-gradient(50% 50% at 50% 50%, rgba(66, 128, 222, 0.1) 57.29%, rgba(23, 84, 177, 0.5) 100%)',
          borderColor: colors['blue-700'],
        },
      },
      brand: {
        background: colors['brand-50'],
        boxShadow:
          'inset 0px 0px 0px 2px rgba(105, 119, 225, 0.3), inset 0px -8px 12px rgba(255, 255, 255, 0.5), inset 0px 3px 5px rgba(105, 119, 225, 0.3), inset 0px 8px 12px rgba(105, 119, 225, 0.26)',
        [darkThemeSelector]: {
          boxShadow: 'unset',
          background:
            'linear-gradient(180deg, rgba(105, 119, 225, 0) 0%, rgba(105, 119, 225, 0.5) 100%), radial-gradient(50% 50% at 50% 50%, rgba(105, 119, 225, 0.1) 57.29%, rgba(69, 81, 175, 0.5) 100%)',
          borderColor: colors['brand-700'],
        },
      },
      negative: {
        background: colors['red-50'],
        boxShadow:
          'inset 0px 0px 0px 2px rgba(213, 33, 33, 0.3), inset 0px -8px 12px rgba(255, 255, 255, 0.5), inset 0px 3px 5px rgba(212, 33, 33, 0.3), inset 0px 8px 12px rgba(212, 33, 33, 0.26)',
        [darkThemeSelector]: {
          boxShadow: 'unset',
          background:
            'linear-gradient(180deg, rgba(213, 33, 33, 0) 0%, rgba(212, 33, 33, 0.5) 100%), radial-gradient(50% 50% at 50% 50%, rgba(213, 33, 33, 0.1) 57.29%, rgba(158, 7, 7, 0.5) 100%)',
          borderColor: colors['red-700'],
        },
      },
      neutral: {
        background: colors['gray-100'],
        boxShadow:
          'inset 0px 0px 0px 2px rgba(129, 131, 148, 0.3), inset 0px -8px 12px rgba(255, 255, 255, 0.5), inset 0px 3px 5px rgba(129, 131, 148, 0.3), inset 0px 8px 12px rgba(129, 131, 148, 0.26)',
        [darkThemeSelector]: {
          boxShadow: 'unset',
          background:
            'linear-gradient(180deg, rgba(102, 104, 122, 0) 0%, rgba(102, 104, 122, 0.5) 100%), radial-gradient(50% 50% at 50% 50%, rgba(102, 104, 122, 0.1) 57.29%, rgba(52, 54, 71, 0.5) 100%)',
          borderColor: colors['gray-600'],
        },
      },
      positive: {
        background: colors['green-50'],
        boxShadow:
          'inset 0px 0px 0px 2px rgba(15, 137, 34, 0.3), inset 0px -8px 12px rgba(255, 255, 255, 0.5), inset 0px 3px 5px rgba(15, 137, 34, 0.3), inset 0px 8px 12px rgba(15, 137, 34, 0.26)',
        [darkThemeSelector]: {
          boxShadow: 'unset',
          background:
            'linear-gradient(180deg, rgba(51, 195, 74, 0) 0%, rgba(51, 195, 74, 0.5) 100%), radial-gradient(50% 50% at 50% 50%, rgba(51, 195, 74, 0.1) 57.29%, rgba(15, 137, 34, 0.5) 100%)',
          borderColor: colors['green-700'],
        },
      },
    },
    size: {
      large: {
        width: 68,
        height: 68,
        [darkThemeSelector]: {
          borderWidth: 2,
        },
      },
      medium: {
        width: 48,
        height: 48,
        [darkThemeSelector]: {
          borderWidth: 1.6,
        },
      },
      small: {
        width: 32,
        height: 32,
        [darkThemeSelector]: {
          borderWidth: 1,
        },
      },
    },
  },
});

export type BandVariant = 'alternative' | 'brand' | 'negative' | 'neutral' | 'positive';

export interface BandProps {
  size?: 'large' | 'medium' | 'small';
  variant?: BandVariant;
}

const Band = ({ size = 'large', variant = 'alternative' }: BandProps) => (
  <Base size={size} variant={variant} />
);

export default Band;

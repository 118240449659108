import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import Clients from '../../../components/Client/Clients';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/clients',
});

export default function ControllerClientsList() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerClientsList),
  );

  return <Clients controllerName={controllerName} />;
}

import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import type { Location, To } from 'history';
import { identity } from 'lodash';

import { Nav } from '../components/Nav';
import { makeLink } from './makeLink';

type MapParamsFn<FromValue extends string, ToValue extends string> = (
  params: ExtractRouteParams<FromValue, string>,
) => ExtractRouteParams<ToValue, string>;

type MakeToFn = (location: Location, path: string, params: Record<string, string>) => To | string;

export interface RedirectEntry<FromValue extends string, ToValue extends string> {
  from: FromValue;
  to: ToValue;
  mapParams: (params: ExtractRouteParams<FromValue, string>) => ExtractRouteParams<ToValue, string>;
  makeTo: MakeToFn;
}

type RedirectOptions<ToValue extends string, From extends string> = ExtractRouteParams<
  ToValue,
  string
> extends ExtractRouteParams<From, string>
  ? [
      {
        mapParams?: MapParamsFn<From, ToValue>;
        makeTo?: MakeToFn;
      }?,
    ]
  : [
      {
        mapParams: MapParamsFn<From, ToValue>;
        makeTo?: MakeToFn;
      },
    ];

const defaultMakeTo = (location: Location, path: string, params: Record<string, string>) =>
  Nav.makeTo({ ...location, pathname: makeLink(path, params) });

/**
 * This function creates a type-safe RedirectEntry object that can map from one
 * path to another. By default, the search params are preserved between URLs.
 */
export function createRedirect<FromValue extends string, ToValue extends string>(
  from: FromValue,
  to: ToValue,
  ...options: RedirectOptions<ToValue, FromValue>
): RedirectEntry<FromValue, ToValue> {
  const mapParams = options[0]?.mapParams ?? identity;
  const makeTo = options[0]?.makeTo ?? defaultMakeTo;
  return { from, to, mapParams, makeTo };
}

const defaultDrawerMakeTo = (location: Location, path: string, params: Record<string, string>) =>
  Nav.makeTo({ root: location.pathname, drawer: makeLink(path, params) });

/**
 * This function creates a type-safe RedirectEntry object that can map from one
 * drawer path to another. This is merely a convenience function that wraps
 * createRedirect.
 */
export function createDrawerRedirect<FromValue extends string, ToValue extends string>(
  from: FromValue,
  to: ToValue,
  ...options: RedirectOptions<ToValue, FromValue>
): RedirectEntry<FromValue, ToValue> {
  const mapParams = options[0]?.mapParams ?? identity;
  const makeTo = options[0]?.makeTo ?? defaultDrawerMakeTo;
  return createRedirect(from, to, { mapParams, makeTo });
}

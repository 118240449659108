import { ControllerIconSVG } from 'atto-svgs';
import React from 'react';

export interface ControllerIconProps {
  /**
   * Sets the size of the component.
   */
  size?: 'large' | 'medium' | 'small';
}

const ControllerIcon = ({ size = 'large' }: ControllerIconProps) =>
  ({
    large: <ControllerIconSVG.Large />,
    medium: <ControllerIconSVG.Medium />,
    small: <ControllerIconSVG.Small />,
  }[size]);

export default ControllerIcon;

import type { GenericMeasure } from 'safe-units';
import { Measure } from 'safe-units';

export const usdCents = Measure.dimension('currency', 'cents');
export const usdDollars = Measure.of(100, usdCents, 'USD');

export interface Currency extends GenericMeasure<number, { currency: '1' }> {}

export const formatUSD = (value: number, quantity: Currency = usdDollars): string =>
  `$${Measure.of(value, quantity).over(usdDollars).value.toFixed(2)}`;

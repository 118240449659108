import type { Brand } from 'ts-brand';
import { make } from 'ts-brand';

type InnerSpacingScale =
  | 0
  | 1
  | 2
  | 4
  | 6
  | 8
  | 10
  | 12
  | 14
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 68
  | 72
  | 76
  | 80
  | 84
  | 88
  | 92
  | 96
  | 100
  | 104
  | 108
  | 112;

export type SpacingScale = 0 | Brand<InnerSpacingScale, 'space'>;
export const SpacingValue = [
  0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80,
  84, 88, 92, 96, 100, 104, 108, 112,
];

export type SizingScale =
  | 'auto'
  | 'fit-content'
  | 'full'
  | 'max-content'
  | 'min-content'
  | 'screen'
  | SpacingScale;

const markSpace = make<SpacingScale>();
export const space = <V extends InnerSpacingScale>(val: V): SpacingScale & V =>
  markSpace(val) as any;

import type { api } from '@meterup/proto';
import { isDefined, isDefinedAndNotEmpty } from '@meterup/common';

export interface ControllerVersion {
  isCOS: boolean;
  isLegacy: boolean;
  versionString: string;
}

export function getControllerVersion(info: api.ControllerResponse): ControllerVersion {
  if (isDefined(info) && isDefinedAndNotEmpty(info.mboot_version)) {
    return {
      isCOS: false,
      isLegacy: true,
      versionString: `Legacy ${info.mboot_version}`,
    };
  }

  return {
    isCOS: true,
    isLegacy: false,
    versionString: 'COS',
  };
}

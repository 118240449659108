import { SwitchIconSVG } from 'atto-svgs';
import React from 'react';

export interface SwitchIconProps {
  /**
   * Sets the size of the component.
   */
  size?: 'large' | 'medium' | 'small';
}

const SwitchIcon = ({ size = 'large' }: SwitchIconProps) =>
  ({
    large: <SwitchIconSVG.Large />,
    medium: <SwitchIconSVG.Medium />,
    small: <SwitchIconSVG.Small />,
  }[size]);

export default SwitchIcon;

import * as Sentry from '@sentry/react';

export const logError = (error: any) => {
  if (import.meta.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  Sentry.captureException(error);
};

import { fade, palette } from './colors';

export const gradients = {
  controlDestructive: `
    linear-gradient(180deg, ${fade(palette.white, 0.05)} 0%, ${fade(palette.white, 0)} 100%)
  `,
  controlPrimary: `
    linear-gradient(180deg, ${fade(palette.white, 0.05)}, 0%, ${fade(palette.white, 0)} 100%)
  `,
  controlSecondary: `
    linear-gradient(180deg, ${fade(palette.white, 0.5)} 0%, ${fade(palette.white, 0)} 100%)
  `,
  controlSecondaryDark: `
    linear-gradient(180deg, ${fade(palette.white, 0.08)} 0%, ${fade(palette.white, 0)} 100%)
  `,
  captiveChecker: `
    url("data:image/svg+xml,%3Csvg width='2' height='2' viewBox='0 0 4 4' fill='none' opacity='0.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='black'/%3E%3Crect x='2' width='2' height='2' fill='white'/%3E%3Crect y='2' width='2' height='2' fill='white'/%3E%3Crect x='2' y='2' width='2' height='2' fill='black'/%3E%3C/svg%3E"),
    linear-gradient(23.75deg, #FEF8FF 1.79%, #F0EDFE 82.18%)
  `,
  captiveCheckerDark: `
    url("data:image/svg+xml,%3Csvg width='2' height='2' viewBox='0 0 4 4' fill='none' opacity='0.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='black'/%3E%3Crect x='2' width='2' height='2' fill='white'/%3E%3Crect y='2' width='2' height='2' fill='white'/%3E%3Crect x='2' y='2' width='2' height='2' fill='black'/%3E%3C/svg%3E"),
    linear-gradient(28.16deg, #735572 -1.29%, #272B4D 72.41%)
  `,
};

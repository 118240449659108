import { isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import * as z from 'zod';

export const formatFullName = (user: api.UserResponse) => {
  const values = [user.first_name, user.last_name].filter(isDefined);

  if (values.length > 0) {
    return values.join(' ');
  }

  return null;
};

export const validEmail = z.string().email();

export const validUserRole = z.union([
  z.literal(api.CompanyMembershipRole.guest),
  z.literal(api.CompanyMembershipRole.member),
  z.literal(api.CompanyMembershipRole.admin),
]);

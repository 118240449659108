import type { HTTPErrorConstructor } from './createHTTPErrorClass';
import { createHTTPErrorClass } from './createHTTPErrorClass';
import { HTTPStatusCode } from './HTTPStatusCode';

/* eslint-disable prettier/prettier */
export const BadRequestError                    = createHTTPErrorClass('BadRequestError',                    HTTPStatusCode.BadRequest,                    'Bad request');
export const UnauthorizedError                  = createHTTPErrorClass('UnauthorizedError',                  HTTPStatusCode.Unauthorized,                  'Unauthorized');
export const PaymentRequiredError               = createHTTPErrorClass('PaymentRequiredError',               HTTPStatusCode.PaymentRequired,               'Payment required');
export const ForbiddenError                     = createHTTPErrorClass('ForbiddenError',                     HTTPStatusCode.Forbidden,                     'Forbidden');
export const NotFoundError                      = createHTTPErrorClass('NotFoundError',                      HTTPStatusCode.NotFound,                      'Not found');
export const MethodNotAllowedError              = createHTTPErrorClass('MethodNotAllowedError',              HTTPStatusCode.MethodNotAllowed,              'Method not allowed');
export const NotAcceptableError                 = createHTTPErrorClass('NotAcceptableError',                 HTTPStatusCode.NotAcceptable,                 'Not acceptable');
export const ProxyAuthenticationRequiredError   = createHTTPErrorClass('ProxyAuthenticationRequiredError',   HTTPStatusCode.ProxyAuthenticationRequired,   'Proxy authentication required');
export const RequestTimeoutError                = createHTTPErrorClass('RequestTimeoutError',                HTTPStatusCode.RequestTimeout,                'Request timeout');
export const ConflictError                      = createHTTPErrorClass('ConflictError',                      HTTPStatusCode.Conflict,                      'Conflict');
export const GoneError                          = createHTTPErrorClass('GoneError',                          HTTPStatusCode.Gone,                          'Gone');
export const LengthRequiredError                = createHTTPErrorClass('LengthRequiredError',                HTTPStatusCode.LengthRequired,                'Length required');
export const PreconditionFailedError            = createHTTPErrorClass('PreconditionFailedError',            HTTPStatusCode.PreconditionFailed,            'Precondition failed');
export const PayloadTooLargeError               = createHTTPErrorClass('PayloadTooLargeError',               HTTPStatusCode.PayloadTooLarge,               'Payload too large');
export const URITooLongError                    = createHTTPErrorClass('URITooLongError',                    HTTPStatusCode.URITooLong,                    'Uritoo long');
export const UnsupportedMediaTypeError          = createHTTPErrorClass('UnsupportedMediaTypeError',          HTTPStatusCode.UnsupportedMediaType,          'Unsupported media type');
export const RangeNotSatisfiableError           = createHTTPErrorClass('RangeNotSatisfiableError',           HTTPStatusCode.RangeNotSatisfiable,           'Range not satisfiable');
export const ExpectationFailedError             = createHTTPErrorClass('ExpectationFailedError',             HTTPStatusCode.ExpectationFailed,             'Expectation failed');
export const ImATeapotError                     = createHTTPErrorClass('ImATeapotError',                     HTTPStatusCode.ImATeapot,                     "I'm a teapot");
export const MisdirectedRequestError            = createHTTPErrorClass('MisdirectedRequestError',            HTTPStatusCode.MisdirectedRequest,            'Misdirected request');
export const UnprocessableEntityError           = createHTTPErrorClass('UnprocessableEntityError',           HTTPStatusCode.UnprocessableEntity,           'Unprocessable entity');
export const LockedError                        = createHTTPErrorClass('LockedError',                        HTTPStatusCode.Locked,                        'Locked');
export const FailedDependencyError              = createHTTPErrorClass('FailedDependencyError',              HTTPStatusCode.FailedDependency,              'Failed dependency');
export const TooEarlyError                      = createHTTPErrorClass('TooEarlyError',                      HTTPStatusCode.TooEarly,                      'Too early');
export const UpgradeRequiredError               = createHTTPErrorClass('UpgradeRequiredError',               HTTPStatusCode.UpgradeRequired,               'Upgrade required');
export const PreconditionRequiredError          = createHTTPErrorClass('PreconditionRequiredError',          HTTPStatusCode.PreconditionRequired,          'Precondition required');
export const TooManyRequestsError               = createHTTPErrorClass('TooManyRequestsError',               HTTPStatusCode.TooManyRequests,               'Too many requests');
export const RequestHeaderFieldsTooLargeError   = createHTTPErrorClass('RequestHeaderFieldsTooLargeError',   HTTPStatusCode.RequestHeaderFieldsTooLarge,   'Request header fields too large');
export const UnavailableForLegalReasonsError    = createHTTPErrorClass('UnavailableForLegalReasonsError',    HTTPStatusCode.UnavailableForLegalReasons,    'Unavailable for legal reasons');
export const InternalServerError                = createHTTPErrorClass('InternalServerError',                HTTPStatusCode.InternalServer,                'Internal server');
export const NotImplementedError                = createHTTPErrorClass('NotImplementedError',                HTTPStatusCode.NotImplemented,                'Not implemented');
export const BadGatewayError                    = createHTTPErrorClass('BadGatewayError',                    HTTPStatusCode.BadGateway,                    'Bad gateway');
export const ServiceUnavailableError            = createHTTPErrorClass('ServiceUnavailableError',            HTTPStatusCode.ServiceUnavailable,            'Service unavailable');
export const GatewayTimeoutError                = createHTTPErrorClass('GatewayTimeoutError',                HTTPStatusCode.GatewayTimeout,                'Gateway timeout');
export const HTTPVersionNotSupportedError       = createHTTPErrorClass('HTTPVersionNotSupportedError',       HTTPStatusCode.HTTPVersionNotSupported,       'HTTP version not supported');
export const VariantAlsoNegotiatesError         = createHTTPErrorClass('VariantAlsoNegotiatesError',         HTTPStatusCode.VariantAlsoNegotiates,         'Variant also negotiates');
export const InsufficientStorageError           = createHTTPErrorClass('InsufficientStorageError',           HTTPStatusCode.InsufficientStorage,           'Insufficient storage');
export const LoopDetectedError                  = createHTTPErrorClass('LoopDetectedError',                  HTTPStatusCode.LoopDetected,                  'Loop detected');
export const NotExtendedError                   = createHTTPErrorClass('NotExtendedError',                   HTTPStatusCode.NotExtended,                   'Not extended');
export const NetworkAuthenticationRequiredError = createHTTPErrorClass('NetworkAuthenticationRequiredError', HTTPStatusCode.NetworkAuthenticationRequired, 'Network authentication required');
/* eslint-enable prettier/prettier */

export const httpErrorFromStatusCode = (statusCode: number): HTTPErrorConstructor | null => {
  switch (statusCode) {
    case HTTPStatusCode.BadRequest:
      return BadRequestError;
    case HTTPStatusCode.Unauthorized:
      return UnauthorizedError;
    case HTTPStatusCode.PaymentRequired:
      return PaymentRequiredError;
    case HTTPStatusCode.Forbidden:
      return ForbiddenError;
    case HTTPStatusCode.NotFound:
      return NotFoundError;
    case HTTPStatusCode.MethodNotAllowed:
      return MethodNotAllowedError;
    case HTTPStatusCode.NotAcceptable:
      return NotAcceptableError;
    case HTTPStatusCode.ProxyAuthenticationRequired:
      return ProxyAuthenticationRequiredError;
    case HTTPStatusCode.RequestTimeout:
      return RequestTimeoutError;
    case HTTPStatusCode.Conflict:
      return ConflictError;
    case HTTPStatusCode.Gone:
      return GoneError;
    case HTTPStatusCode.LengthRequired:
      return LengthRequiredError;
    case HTTPStatusCode.PreconditionFailed:
      return PreconditionFailedError;
    case HTTPStatusCode.PayloadTooLarge:
      return PayloadTooLargeError;
    case HTTPStatusCode.URITooLong:
      return URITooLongError;
    case HTTPStatusCode.UnsupportedMediaType:
      return UnsupportedMediaTypeError;
    case HTTPStatusCode.RangeNotSatisfiable:
      return RangeNotSatisfiableError;
    case HTTPStatusCode.ExpectationFailed:
      return ExpectationFailedError;
    case HTTPStatusCode.ImATeapot:
      return ImATeapotError;
    case HTTPStatusCode.MisdirectedRequest:
      return MisdirectedRequestError;
    case HTTPStatusCode.UnprocessableEntity:
      return UnprocessableEntityError;
    case HTTPStatusCode.Locked:
      return LockedError;
    case HTTPStatusCode.FailedDependency:
      return FailedDependencyError;
    case HTTPStatusCode.TooEarly:
      return TooEarlyError;
    case HTTPStatusCode.UpgradeRequired:
      return UpgradeRequiredError;
    case HTTPStatusCode.PreconditionRequired:
      return PreconditionRequiredError;
    case HTTPStatusCode.TooManyRequests:
      return TooManyRequestsError;
    case HTTPStatusCode.RequestHeaderFieldsTooLarge:
      return RequestHeaderFieldsTooLargeError;
    case HTTPStatusCode.UnavailableForLegalReasons:
      return UnavailableForLegalReasonsError;
    case HTTPStatusCode.InternalServer:
      return InternalServerError;
    case HTTPStatusCode.NotImplemented:
      return NotImplementedError;
    case HTTPStatusCode.BadGateway:
      return BadGatewayError;
    case HTTPStatusCode.ServiceUnavailable:
      return ServiceUnavailableError;
    case HTTPStatusCode.GatewayTimeout:
      return GatewayTimeoutError;
    case HTTPStatusCode.HTTPVersionNotSupported:
      return HTTPVersionNotSupportedError;
    case HTTPStatusCode.VariantAlsoNegotiates:
      return VariantAlsoNegotiatesError;
    case HTTPStatusCode.InsufficientStorage:
      return InsufficientStorageError;
    case HTTPStatusCode.LoopDetected:
      return LoopDetectedError;
    case HTTPStatusCode.NotExtended:
      return NotExtendedError;
    case HTTPStatusCode.NetworkAuthenticationRequired:
      return NetworkAuthenticationRequiredError;
    default:
      return null;
  }
};

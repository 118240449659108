import React from 'react';

import { Background, Detail, Glyph } from '../common';

export const ProductIconConnect = () => (
  <>
    <Background d="M0 8C0 5.09903 0 3.64855 0.595437 2.55452C1.04613 1.72642 1.72642 1.04613 2.55452 0.595437C3.64855 0 5.09903 0 8 0C10.901 0 12.3515 0 13.4455 0.595437C14.2736 1.04613 14.9539 1.72642 15.4046 2.55452C16 3.64855 16 5.09903 16 8C16 10.901 16 12.3515 15.4046 13.4455C14.9539 14.2736 14.2736 14.9539 13.4455 15.4046C12.3515 16 10.901 16 8 16C5.09903 16 3.64855 16 2.55452 15.4046C1.72642 14.9539 1.04613 14.2736 0.595437 13.4455C0 12.3515 0 10.901 0 8Z" />
    <Detail d="M10.8584 10.8945H11.5434C11.7956 10.8945 12 10.6943 12 10.4472V9.55275C12 9.30574 11.7956 9.1055 11.5434 9.1055H10.8584C10.7323 9.1055 10.6301 9.00537 10.6301 8.88187V7.64792C10.6301 7.30274 10.2478 7.08767 9.94335 7.2616L5.82656 9.61367C5.52449 9.78626 5.52449 10.2137 5.82656 10.3863L9.94335 12.7384C10.2478 12.9123 10.6301 12.6973 10.6301 12.3521V11.1181C10.6301 10.9946 10.7323 10.8945 10.8584 10.8945Z" />
    <Glyph d="M5.1416 5.1055H4.45664C4.20445 5.1055 4 5.30574 4 5.55275V6.44725C4 6.69426 4.20445 6.8945 4.45664 6.8945H5.1416C5.2677 6.8945 5.36993 6.99462 5.36993 7.11812V8.35207C5.36993 8.69726 5.75223 8.91233 6.05666 8.7384L10.1734 6.38632C10.4755 6.21374 10.4755 5.78626 10.1734 5.61367L6.05666 3.2616C5.75223 3.08767 5.36993 3.30274 5.36993 3.64792V4.88187C5.36993 5.00537 5.2677 5.1055 5.1416 5.1055Z" />
  </>
);

export default ProductIconConnect;

import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { ProductIconProduct } from '../../assets/ProductIcon/ProductIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import ProductIcon from '../../assets/ProductIcon/ProductIcon';
import { FocusRingSelf } from '../../common/focus_rings';
import { colors, fontWeights, styled } from '../../stitches.config';
import { Small2 } from '../../text/Small';

const Container = styled('a', FocusRingSelf, {
  hStack: '$4',
  padding: '$6 $8',
  borderRadius: '$8',
  variants: {
    isSelected: {
      true: { backgroundColor: colors['brand-600'] },
      false: {},
    },
  },
});

const IconContainer = styled('div', {
  display: 'none',
  '@md': {
    display: 'inline-flex',
  },
});

const Label = styled('div', Small2, {
  fontWeight: fontWeights.medium,
  variants: {
    isSelected: {
      true: {
        color: colors.white,
      },
      false: {},
    },
  },
});

export interface HeaderNavItemProps {
  label: string;
  icon: ProductIconProduct;
  isSelected?: boolean;
}

export const HeaderNavItem = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      label,
      icon,
      isSelected,
      ...props
    }: PolymorphicComponentProps<Tag, HeaderNavItemProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <Container as={as} ref={forwardedRef} {...props} isSelected={isSelected}>
      <IconContainer>
        <ProductIcon selected={isSelected} product={icon} />
      </IconContainer>
      <Label isSelected={isSelected}>{label}</Label>
    </Container>
  ),
) as Polymorphic.ForwardRefComponent<'a', HeaderNavItemProps>;

export default HeaderNavItem;

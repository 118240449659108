import { merge } from 'lodash';
import React from 'react';
import { match } from 'ts-pattern';

import type { CSS } from '../../stitches.config';
import type { ColorProps } from '../shared/Color';
import type { OpacityTypes } from '../shared/Opacity';
import type { PolymorphicComponentProps } from '../types/polymorphicAsProp';
import { fontFamilies } from '../../common/fonts';
import { fontWeights, styled } from '../../stitches.config';
import { colorCSS } from '../shared/Color';
import { opacityCSS } from '../shared/Opacity';

const BaseText = styled('span');

export type TextAlign = 'center' | 'justify' | 'left' | 'right';
export type TextDecoration = 'none' | 'line-through' | 'underline';
export type TextDisplay = 'block' | 'flex' | 'inline' | 'inline-block' | 'inline-flex';
export type TextFamily = 'brand' | 'monospace' | 'regular';
export type TextItalicize = 'italic' | 'regular';
export type TextLineHeight = 12 | 16 | 20 | 24 | 28 | 32;
export type TextSize = 11 | 12 | 13 | 14 | 16 | 20 | 24;
export type TextTransform = 'capitalize' | 'lowercase' | 'none' | 'uppercase';
export type TextVariant =
  | 'diagonal-fractions'
  | 'lining'
  | 'oldstyle'
  | 'ordinal'
  | 'proportional'
  | 'slashed-zero'
  | 'stacked-fractions'
  | 'regular'
  | 'tabular';
export type TextWeight = 'light' | 'medium' | 'regular';
export type TextWhitespace = 'normal' | 'no-wrap' | 'pre' | 'pre-line' | 'pre-wrap';
export type TextWordBreak = 'break-all' | 'break-word' | 'normal';

interface Props {
  children?: React.ReactNode;
  className?: string;

  align?: TextAlign;
  color?: ColorProps;
  decoration?: TextDecoration;
  display?: TextDisplay;
  family?: TextFamily;
  italicize?: TextItalicize;
  lineHeight?: TextLineHeight;
  size?: TextSize;
  transform?: TextTransform;
  opacity?: OpacityTypes;
  variant?: TextVariant;
  weight?: TextWeight;
  whitespace?: TextWhitespace;
  wordBreak?: TextWordBreak;
}

export type TextProps<Tag extends React.ElementType> = PolymorphicComponentProps<Tag, Props>;

export type TextComponent = <C extends React.ElementType = 'span'>(
  props: TextProps<C>,
) => React.ReactElement | null;

export const Text: TextComponent = <Tag extends React.ElementType = 'span'>({
  as = 'span' as Tag,
  align,
  className,
  color,
  decoration,
  display = 'inline',
  family,
  italicize,
  lineHeight,
  opacity,
  size,
  transform,
  variant,
  visibility,
  weight,
  whitespace,
  wordBreak,
  ...remaining
}: TextProps<Tag>) => {
  const fontFamily = match(family)
    .with('brand', () => fontFamilies.title)
    .with('monospace', () => fontFamilies.mono)
    .with('regular', () => fontFamilies.sans)
    .otherwise(() => fontFamilies.sans)
    .toString();

  const fontWeight = match(weight)
    .with('light', () => fontWeights.light)
    .with('regular', () => fontWeights.regular)
    .with('medium', () => fontWeights.medium)
    .otherwise(() => fontWeights.regular)
    .toString();

  const baseCSS: CSS = {
    display,
    textAlign: align,
    textDecoration: decoration,
    fontFamily,
    lineHeight: `$${lineHeight}`,
    fontSize: size,
    fontStyle: italicize,
    textTransform: transform,
    fontVariant: variant,
    fontWeight,
    whiteSpace: whitespace,
    wordBreak,
  };

  const css = merge(baseCSS, colorCSS(color?.dark, color?.light), opacityCSS(opacity));

  return <BaseText {...remaining} as={as} css={css} />;
};

export const Text2 = styled('span');

export default Text;

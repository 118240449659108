import React from 'react';

import ControllerIcon from '../ControllerIcon/ControllerIcon';
import DeviceIcon from '../DeviceIcon/DeviceIcon';
import SwitchIcon from '../SwitchIcon/SwitchIcon';

export type HardwareIconName = 'controller' | 'switch' | 'ap';

export interface HardwareIconProps {
  icon: HardwareIconName;
  size: 'small' | 'medium' | 'large';
}

export const HardwareIcon = ({ icon, ...props }: HardwareIconProps) =>
  ({
    controller: <ControllerIcon {...props} />,
    switch: <SwitchIcon {...props} />,
    ap: <DeviceIcon {...props} />,
  }[icon]);

export default HardwareIcon;

import type { AccordionTriggerProps } from '@radix-ui/react-accordion';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';

import Icon from '../../assets/Icon/Icon';
import { styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
  padding: '$2 $0',
});
const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  hStack: '$8',
});
const StyledChevron = styled(Icon, {
  width: '$8',
  height: '$8',
  '[data-state=open] &': { transform: 'rotate(90deg)' },
});

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, ...props }, forwardedRef) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={forwardedRef}>
        <StyledChevron icon="chevronRight" aria-hidden />
        <Body2>{children}</Body2>
      </StyledTrigger>
    </StyledHeader>
  ),
);

export default AccordionTrigger;

import type React from 'react';

import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Small2 } from '../../text/Small';

export const ListItemLabel = styled('dt', Small2, {
  fontWeight: fontWeights.regular,
  color: colors['gray-600'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
});

export type ListItemLabelProps = React.ComponentProps<typeof ListItemLabel>;

export default ListItemLabel;

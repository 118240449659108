import React from 'react';

import type { ControlSize } from '../../components/Control/types';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const BaseSVG = styled('svg', {
  marginLeft: 'auto',
  color: colors['gray-400'],
  pointerEvents: 'none',
  flexShrink: 0,
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
  variants: {
    size: {
      small: {
        width: '$6',
      },
      medium: {
        width: '$8',
      },
      large: {
        width: '$8',
      },
      'x-large': {
        width: '$8',
      },
    },
  },
});

export const SelectArrows: React.FC<{ size: ControlSize }> = ({ size }) => (
  <BaseSVG size={size} width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M7.84245 5.0205C8.06868 4.70222 8.04932 4.24939 7.79696 3.95595L4.66616 0.315675C4.30417 -0.105225 3.69583 -0.105225 3.33384 0.315675L0.203043 3.95595C-0.0493234 4.24939 -0.0686772 4.70222 0.157546 5.0205C0.436488 5.41294 0.966738 5.44046 1.27792 5.07865L3.33384 2.68816C3.69583 2.26726 4.30417 2.26726 4.66616 2.68816L6.72209 5.07865C7.03326 5.44047 7.56351 5.41294 7.84245 5.0205Z"
    />
    <path
      fill="currentColor"
      d="M0.157546 6.9795C-0.0686768 7.29778 -0.0493236 7.75061 0.203042 8.04405L3.33384 11.6843C3.69583 12.1052 4.30417 12.1052 4.66616 11.6843L7.79696 8.04405C8.04932 7.75061 8.06868 7.29778 7.84245 6.9795C7.56351 6.58706 7.03326 6.55954 6.72208 6.92135L4.66616 9.31184C4.30417 9.73274 3.69583 9.73274 3.33384 9.31184L1.27791 6.92135C0.966737 6.55953 0.436488 6.58706 0.157546 6.9795Z"
    />
  </BaseSVG>
);

import { merge } from 'lodash';

export type OverflowTypes = 'auto' | 'hidden' | 'scroll' | 'visible';
export type OverflowProps = {
  all?: OverflowTypes;
  x?: OverflowTypes;
  y?: OverflowTypes;
};

const overflowCSSInternal = (overflow?: OverflowTypes) => (overflow != null ? { overflow } : {});

export const overflowCSS = (all?: OverflowTypes, x?: OverflowTypes, y?: OverflowTypes) =>
  all != null ? overflowCSSInternal(all) : merge(overflowCSSInternal(x), overflowCSSInternal(y));

import { api } from '@meterup/proto';
import { match } from 'ts-pattern';
import * as z from 'zod';

export const formatISPProduct = (product: api.ISPProduct) =>
  match(product)
    .with(api.ISPProduct.IR_UNKNOWN, () => 'Unknown')
    .with(api.ISPProduct.IR_DEDICATED_FIBER, () => 'Dedicated fiber')
    .with(api.ISPProduct.IR_SHARED_FIBER, () => 'Shared fiber')
    .with(api.ISPProduct.IR_POINT_TO_POINT_WIRELESS, () => 'Point to point wireless')
    .with(api.ISPProduct.IR_RESIDENTIAL_FIBER, () => 'Residential fiber')
    .with(api.ISPProduct.IR_RESIDENTIAL_CABLE, () => 'Residential cable')
    .with(api.ISPProduct.IR_BUSINESS_CABLE, () => 'Business cable')
    .exhaustive();

export const validISPProduct = z.nativeEnum(api.ISPProduct);

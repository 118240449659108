import {
  DrawerControls,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton/CloseDrawerButton';
import { paths } from '../../../../../constants';
import { makeDrawerLink } from '../../../../../utils/makeLink';

export function PlanControls({
  controllerName,
  planId,
}: {
  controllerName: string;
  planId: string;
}) {
  const navigate = useNavigate();

  return (
    <DrawerControls>
      <DropdownMenu>
        <DropdownMenuTriggerButton
          variant="secondary"
          arrangement="hidden-label"
          icon="overflowVertical"
        >
          Actions
        </DropdownMenuTriggerButton>
        <DropdownMenuContent>
          <DropdownMenuItem
            icon="pencil"
            onSelect={() =>
              navigate(
                makeDrawerLink(paths.drawers.ISPEdit, {
                  controllerName,
                  planId,
                }),
              )
            }
          >
            Edit plan
          </DropdownMenuItem>
          <DropdownMenuItem
            icon="delete"
            onSelect={() =>
              navigate(
                makeDrawerLink(paths.drawers.ISPDelete, {
                  controllerName,
                  planId,
                }),
              )
            }
          >
            Delete plan
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <CloseDrawerButton />
    </DrawerControls>
  );
}

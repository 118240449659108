import * as Tooltip from '@radix-ui/react-tooltip';
import React, { useCallback } from 'react';

import type { CapsuleContainerArrangement } from './atoms/CapsuleContainer';
import { copyTextToClipboard } from '../../utilities/clipboard';
import { defer } from '../../utilities/defer';
import { useTimedToggle } from '../../utilities/useTimedToggle';
import CapsuleContainer from './atoms/CapsuleContainer';
import CapsuleTooltip from './atoms/CapsuleTooltip';

export type CopyCapsuleProps = {
  /**
   * Description of the action taken when clicking the component. **Required** for accessibility support.
   */
  'aria-label': string;
  /**
   * Set the content's order and visibility.
   */
  arrangement: CapsuleContainerArrangement;
  /**
   * Displayed as the contents of the component.
   */
  children: React.ReactNode;
  /**
   * Displayed as the contents of the tooltip.
   */
  tooltip?: React.ReactNode;
  /**
   * Value to copy to the clipboard when the capsule is clicked.
   */
  textValue: string;
};

export const CopyCapsule = ({
  arrangement,
  children,
  tooltip = 'Copied!',
  textValue,
  ...props
}: CopyCapsuleProps) => {
  const { value: isClicked, hide, show } = useTimedToggle();

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      await copyTextToClipboard(textValue);
      hide();
      await defer(50);
      show();
    },
    [textValue, hide, show],
  );

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isClicked}>
        <Tooltip.Trigger asChild>
          <CapsuleContainer
            aria-label={props['aria-label']}
            arrangement={arrangement}
            icon="copy"
            onClick={handleClick}
            isClicked={isClicked}
          >
            {children}
          </CapsuleContainer>
        </Tooltip.Trigger>
        <Tooltip.Content side="top" sideOffset={-2} asChild>
          <CapsuleTooltip>{tooltip}</CapsuleTooltip>
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default CopyCapsule;

import type { FormikProps } from 'formik';
import type React from 'react';
import { isDefined } from '@meterup/common';

export const getInputProps = <T extends object>(form: FormikProps<T>, key: keyof T & string) => {
  const formikInputProps = form.getFieldProps(key);

  return {
    ...formikInputProps,
    selectedKey: form.values[key],
    onChange: (eventOrValue: React.ChangeEvent | any) => {
      if (typeof eventOrValue === 'object') {
        form.setFieldValue(key, eventOrValue.target.value);
      } else {
        form.setFieldValue(key, eventOrValue);
      }
    },
    onValueChange: (v: string) => form.setFieldValue(key, v),
    onSelectionChange: (v: string) => form.setFieldValue(key, v),
    hasError: form.touched[key] && isDefined(form.errors[key]),
  };
};

export const getFieldProps = <T extends object>(form: FormikProps<T>, key: keyof T) => ({
  errorMessage: form.touched[key] ? form.errors[key] : undefined,
});

import type { ReactNode, RefObject } from 'react';
import type { AriaOverlayProps } from 'react-aria';
import React from 'react';
import { DismissButton, FocusScope, useOverlay, useOverlayPosition } from 'react-aria';

import type { ControlSize } from '../../components/Control/types';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const Container = styled('div', {
  position: 'absolute',
  width: 'fit-content',
  padding: '$4',
  overflow: 'auto',
  background: colors.white,
  boxShadow: shadows.overlayLight,

  [darkThemeSelector]: {
    background: colors['gray-700'],
    boxShadow: shadows.overlayDark,
  },

  variants: {
    size: {
      small: { borderRadius: '$6' },
      medium: { borderRadius: '$8' },
      large: { borderRadius: '$10' },
      'x-large': { borderRadius: '$10' },
    },
  },
});

interface SelectPopoverProps extends AriaOverlayProps {
  popoverRef?: RefObject<HTMLElement>;
  triggerRef: RefObject<HTMLElement>;
  children: ReactNode;
  size?: ControlSize;
  width?: number;
  isOpen?: boolean;
  onClose?: () => void;
}

function SelectPopover(props: SelectPopoverProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { isOpen, onClose, children, triggerRef, width, popoverRef = ref, size = 'medium' } = props;

  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef,
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: popoverRef,
    placement: 'bottom',
    offset: size === 'small' ? 4 : 8,
    isOpen,
  });

  return (
    <FocusScope restoreFocus>
      <Container
        {...overlayProps}
        {...positionProps}
        ref={popoverRef as RefObject<any>}
        size={size}
        style={{ ...positionProps.style, width, minWidth: 'fit-content' }}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </Container>
    </FocusScope>
  );
}

export default SelectPopover;

import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import Icon from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { useControlSize } from '../../common/control_size';
import { visuallyHiddenCSS } from '../../common/visually_hidden';
import {
  activeThemeSelector,
  colors,
  darkThemeSelector,
  fonts,
  fontWeights,
  styled,
} from '../../stitches.config';
import { space } from '../../utilities/shared/sizes';

export type BadgeArrangement = 'hidden-label' | 'leading-icon' | 'leading-label';
export type BadgeEnds = 'card' | 'pill';
export type BadgeSize = 'small' | 'medium' | 'large';
export type BadgeVariant = 'alternative' | 'brand' | 'negative' | 'neutral' | 'positive';

export type BadgeProps = {
  /**
   * Set the content's order and visibilty.
   */
  arrangement?: BadgeArrangement;
  /**
   * Displayed as the label of the component. **Required** for accessibility support.
   */
  children: React.ReactNode;
  /**
   * Style choice for the left and right edges of the component.
   */
  ends?: BadgeEnds;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Set a size of the component.
   */
  size?: BadgeSize;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: BadgeVariant;
};

const BadgeContainer = styled('div', {
  display: 'inline-flex',
  width: 'fit-content',
  paddingX: '$8',
  paddingY: '$2',
  variants: {
    ends: {
      card: {
        borderRadius: '$8',
      },
      pill: {
        borderRadius: 9999,
      },
    },
    size: {
      small: { lineHeight: '$16', height: '$20', paddingY: '$2' },
      medium: { lineHeight: '$20', height: '$24', paddingY: '$2' },
      large: { lineHeight: '$20', height: '$28', paddingY: '$4' },
    },
    color: {
      alternative: {
        backgroundColor: fade(palette['blue-100'], 0.75),
        $$labelColor: colors['blue-900'],
        $$iconColor: colors['blue-800'],
        [darkThemeSelector]: {
          backgroundColor: colors['blue-500'],
          $$labelColor: colors.white,
          $$iconColor: colors['blue-50'],
        },
        [activeThemeSelector]: {
          backgroundColor: colors.white,
          $$labelColor: colors['blue-600'],
          $$iconColor: colors['blue-500'],
        },
      },
      brand: {
        backgroundColor: fade(palette['brand-100'], 0.75),
        $$labelColor: colors['brand-900'],
        $$iconColor: colors['brand-800'],
        [darkThemeSelector]: {
          backgroundColor: colors['brand-500'],
          $$labelColor: colors.white,
          $$iconColor: colors['brand-50'],
        },
        [activeThemeSelector]: {
          backgroundColor: colors.white,
          $$labelColor: colors['brand-600'],
          $$iconColor: colors['brand-500'],
        },
      },
      negative: {
        backgroundColor: fade(palette['red-100'], 0.75),
        $$labelColor: colors['red-900'],
        $$iconColor: colors['red-800'],
        [darkThemeSelector]: {
          backgroundColor: colors['red-500'],
          $$labelColor: colors.white,
          $$iconColor: colors['red-50'],
        },
        [activeThemeSelector]: {
          backgroundColor: colors.white,
          $$labelColor: colors['red-600'],
          $$iconColor: colors['red-500'],
        },
      },
      neutral: {
        backgroundColor: fade(palette['gray-100'], 0.75),
        $$labelColor: colors['gray-700'],
        $$iconColor: colors['gray-600'],
        [darkThemeSelector]: {
          backgroundColor: fade(palette['gray-400'], 0.5),
          $$labelColor: colors.white,
          $$iconColor: colors['gray-50'],
        },
        [activeThemeSelector]: {
          backgroundColor: colors.white,
          $$labelColor: colors['gray-600'],
          $$iconColor: colors['gray-500'],
        },
      },
      positive: {
        backgroundColor: fade(palette['green-100'], 0.75),
        $$labelColor: colors['green-900'],
        $$iconColor: colors['green-800'],
        [darkThemeSelector]: {
          backgroundColor: colors['green-700'],
          $$labelColor: colors.white,
          $$iconColor: colors['green-50'],
        },
        [activeThemeSelector]: {
          backgroundColor: colors.white,
          $$labelColor: colors['green-700'],
          $$iconColor: colors['green-600'],
        },
      },
    },
  },
  compoundVariants: [
    {
      ends: 'card',
      size: 'small',
      css: {
        paddingX: '$4',
        borderRadius: '$6',
      },
    },
    {
      ends: 'pill',
      size: 'small',
      css: {
        paddingX: '$6',
      },
    },
    {
      ends: 'pill',
      size: 'medium',
      css: {
        paddingX: '$10',
      },
    },
    {
      ends: 'card',
      size: 'large',
      css: {
        paddingX: '$10',
        paddingY: '$4',
      },
    },
    {
      ends: 'pill',
      size: 'large',
      css: {
        paddingX: '$12',
        paddingY: '$4',
      },
    },
  ],
});

const LabelAndIcon = styled('div', {
  hStack: '$4',
  variants: {
    arrangement: {
      'leading-icon': {
        flexDirection: 'row-reverse',
      },
      'leading-label': {
        flexDirection: 'row',
      },
      'hidden-label': {},
    },
  },
});

const Label = styled('div', {
  color: '$$labelColor',
  fontWeight: fontWeights.medium,
  fontFamily: fonts.sans,
  variants: {
    isHidden: {
      true: visuallyHiddenCSS,
      false: {},
    },
    size: {
      small: {
        fontSize: '$12',
        lineHeight: '$16',
      },
      medium: {
        fontSize: '$14',
        lineHeight: '$20',
      },
      large: {
        fontSize: '$14',
        lineHeight: '$20',
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
});

export const Badge: React.FunctionComponent<BadgeProps> = ({
  arrangement = 'leading-label',
  children,
  ends = 'card',
  icon,
  variant = 'neutral',
  size,
}) => {
  const controlSize = useControlSize(size, 'medium') as BadgeSize;

  return (
    <BadgeContainer size={controlSize} ends={ends} color={variant}>
      {/*
        TRICKY: This zero-width-space ensures the Badge always has a baseline to
        align with even when the label is visually hidden.
        @see https://www.w3.org/TR/CSS22/visudet.html#propdef-vertical-align
      */}
      <Label size={controlSize} aria-hidden>
        {'\u200B'}
      </Label>

      <LabelAndIcon arrangement={arrangement}>
        <Label size={controlSize} isHidden={arrangement === 'hidden-label'}>
          {children}
        </Label>
        {icon && <StyledIcon icon={icon} size={controlSize === 'small' ? space(12) : space(16)} />}
      </LabelAndIcon>
    </BadgeContainer>
  );
};

export default Badge;

import type { CSSProperties } from 'react';
import React, { useEffect, useRef } from 'react';

import { colors, darkThemeSelector, keyframes, styled } from '../../stitches.config';

const skeletonRipple = keyframes({
  '0%, 100%': { opacity: '0.1' },
  '50%': { opacity: '0.2' },
});

const skeletonRippleDark = keyframes({
  '0%, 100%': { opacity: '0.1' },
  '50%': { opacity: '0.2' },
});

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const Base = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  opacity: 0,
  animation: `${fadeIn} 1s ease-out forwards`,
  animationDelay: 'var(--delay-stagger, 500ms)',
  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: colors['gray-400'],
    animation: `${skeletonRipple} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
    [darkThemeSelector]: {
      backgroundColor: colors['gray-300'],
      animation: `${skeletonRippleDark} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
    },
  },
});

export interface SkeletonProps {
  /**
   * Set the display type of the box modal.
   */
  display?: CSSProperties['display'];
  /**
   * A string to represent the height as a CSS value.
   */
  height: CSSProperties['height'];
  /**
   * Set the border radius of the component.
   */
  radius?: CSSProperties['borderRadius'];
  /**
   * A string to represent the width as a CSS value.
   */
  width?: CSSProperties['width'];
}

export const Skeleton: React.FunctionComponent<SkeletonProps> = ({
  display,
  height = '40px',
  radius,
  width = '40px',
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const delay = rect.x + rect.y + 250;
      element.style.setProperty('--delay-stagger', `${delay}ms`);
    }
  });

  return (
    <Base
      ref={ref}
      style={{
        width,
        height,
        display,
        borderRadius: radius,
      }}
    />
  );
};

export default Skeleton;

import { NeutralBadge } from '@meterup/common';
import {
  CopyCapsule,
  List,
  ListControls,
  ListItemHeader,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/metric';
import { mboot } from '@meterup/proto';
import React from 'react';
import { match } from 'ts-pattern';

import type { NetworkInfoResponseData, NetworkInfoUpdateRequest } from '../../../../api/types';
import type { LegacyNetworkInfo } from '../../../../utils/legacy_network_info';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import {
  LegacyNetworkPSKSchemeType,
  networkInfoAPIResponseToList,
} from '../../../../utils/legacy_network_info';

const LegacyNetworkInfoCard = ({ value }: { value: LegacyNetworkInfo }) => {
  const isRotating = value.passwordScheme === LegacyNetworkPSKSchemeType.Rotating;
  return (
    <List>
      <ListItemHeader>
        <ListTitle>{value.key}</ListTitle>
        <ListControls>
          {value.isCustomKey && (
            <Tooltip content="This SSID is custom and cannot be modified through NOC">
              <NeutralBadge>Custom</NeutralBadge>
            </Tooltip>
          )}
        </ListControls>
      </ListItemHeader>
      <ListItemPair>
        <ListItemLabel>SSID</ListItemLabel>
        <ListItemValue>
          {value.isSSIDDisabled && <NeutralBadge>Disabled</NeutralBadge>}
          <CopyCapsule arrangement="leading-icon" aria-label="Copy SSID" textValue={value.ssid}>
            {value.ssid}
          </CopyCapsule>
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Rotation</ListItemLabel>
        <ListItemValue>
          {isRotating
            ? match(value.rotationStrategy)
                .with(mboot.GuestPasswordStrategy.UNKNOWN, () => 'Unknown')
                .with(mboot.GuestPasswordStrategy.NEVER, () => 'Never')
                .with(mboot.GuestPasswordStrategy.DAILY, () => 'Daily')
                .with(mboot.GuestPasswordStrategy.MONTHLY, () => 'Monthly')
                .otherwise(() => 'Unknown')
            : 'Static'}
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>{isRotating ? 'Current password' : 'Password'}</ListItemLabel>
        <ListItemValue>
          <CopyCapsule
            arrangement="leading-icon"
            aria-label="Copy password"
            textValue={value.password ?? ''}
          >
            {value.password}
          </CopyCapsule>
        </ListItemValue>
      </ListItemPair>
    </List>
  );
};

export const LegacyNetworkInfoCards = ({
  networkPass,
}: {
  networkPass: NetworkInfoResponseData | NetworkInfoUpdateRequest;
}) => {
  const list = networkInfoAPIResponseToList(networkPass);

  return (
    <>
      {list.map((value) => (
        <LegacyNetworkInfoCard key={value.key} value={value} />
      ))}
    </>
  );
};

/* eslint-disable react/no-unstable-nested-components */
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  DisabledBadge,
  expectDefinedOrThrow,
  getEnabledRadioForBandOrNull,
  isDefined,
  NeutralBadge,
  OnlineOfflineDraftBadge,
  ResourceNotFoundError,
  Tooltip,
} from '@meterup/common';
import { Badge, Button, DeviceIcon, HStack, space } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import type { CSVRowFormatter } from '../../../components/AutoTable/AutoTable';
import { fetchControllerJSON } from '../../../api/controllers_api';
import { fetchDevicesJSON, fetchFloorPlan } from '../../../api/devices_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { APCSVUploadDialog } from '../../../components/Device/APCSVUploadDialog';
import { UploadFloorPlanDialog } from '../../../components/Device/UploadFloorPlanDialog';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices',
});

const RadioNumbers = ({ value }: { value: api.AccessPointRadioResponse }) => (
  <HStack spacing={space(4)}>
    <Tooltip content="Channel">
      <Badge size="small">{value.channel}</Badge>
    </Tooltip>
    <Tooltip content="Channel Width">
      <Badge size="small">{value.channel_width}</Badge>
    </Tooltip>
    <Tooltip content="Power">
      <Badge size="small">{value.power}</Badge>
    </Tooltip>{' '}
  </HStack>
);

const columns: Column<api.AccessPointRadiosResponse>[] = [
  {
    Header: 'Location',
    accessor: (row) => row.access_point?.location,
    Cell: (props: CellProps<api.AccessPointRadiosResponse, string>) => (
      <HStack spacing={space(8)}>
        <DeviceIcon size="small" />
        {props.value}
      </HStack>
    ),
  },
  {
    Header: 'Name',
    accessor: (row) => row.access_point?.id,
  },
  {
    Header: '5G band',
    accessor: (row) => {
      const radio = getEnabledRadioForBandOrNull(row, api.RadioBand.RB_5G);
      return radio
        ? [radio.channel.toString(), radio.channel_width.toString(), radio.power.toString()].join(
            ', ',
          )
        : null;
    },
    Cell: (props: CellProps<api.AccessPointRadiosResponse>) => {
      const value = getEnabledRadioForBandOrNull(props.row.original, api.RadioBand.RB_5G);
      return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
    },
  },
  {
    Header: '2.4G band',
    accessor: (row) => {
      const radio = getEnabledRadioForBandOrNull(row, api.RadioBand.RB_2G);
      return radio
        ? [radio.channel.toString(), radio.channel_width.toString(), radio.power.toString()].join(
            ', ',
          )
        : null;
    },
    Cell: (props: CellProps<api.AccessPointRadiosResponse>) => {
      const value = getEnabledRadioForBandOrNull(props.row.original, api.RadioBand.RB_2G);
      return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
    },
  },
  {
    Header: 'Version',
    accessor: (row) => row.access_point?.version,
    Cell: (props: CellProps<api.AccessPointRadiosResponse>) => (
      <NeutralBadge ends="card">{props.value}</NeutralBadge>
    ),
  },
  {
    Header: 'Clients',
    accessor: (row) => row.access_point?.connected_clients,
  },
  {
    Header: 'Status',
    accessor: (row) => row.access_point?.status,
    Cell: OnlineOfflineDraftBadge,
  },
];

const csvRowFormatter: CSVRowFormatter<api.AccessPointRadiosResponse> = (
  row: api.AccessPointRadiosResponse,
) =>
  row.radios.map((radio) => ({
    name: row.access_point?.name,
    location: row.access_point?.location,
    index: radio.index,
    channel: radio.channel,
    power: radio.power,
    channel_width: radio.channel_width,
    disabled: radio.disabled,
  }));

export default function ControllerDevicesList() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDevicesList),
  );

  const network = useQuery(
    ['controller', controllerName, 'json'],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(network, new ResourceNotFoundError('Controller not found'));

  const devices =
    useQuery(['controller', controllerName, 'devices'], () => fetchDevicesJSON(controllerName), {
      suspense: true,
    }).data ?? [];

  const floorPlan = useQuery(['controller', controllerName, 'floor-plan'], async () =>
    fetchFloorPlan(controllerName),
  ).data;

  useDocumentTitle('Devices', controllerName, network);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.DeviceDetail);

  const floorPlanButton = isDefined(floorPlan) ? (
    <Button
      arrangement="leading-icon"
      icon="floorplan"
      variant="tertiary"
      as={Link}
      to={makeDrawerLink(paths.drawers.FloorPlanDetail, { controllerName })}
    >
      View floor plan
    </Button>
  ) : (
    <UploadFloorPlanDialog
      controllerName={controllerName}
      trigger={
        <Button icon="floorplan" variant="secondary">
          Upload floor plan
        </Button>
      }
    />
  );

  return (
    <AutoTable
      additionalControls={
        <>
          {floorPlanButton}
          <APCSVUploadDialog
            controllerName={controllerName}
            trigger={
              <Button arrangement="leading-icon" icon="upload" variant="tertiary">
                Import
              </Button>
            }
          />
        </>
      }
      isRowSelected={(row) => row.access_point?.id === drawerParams?.id}
      linkProps={(row) => ({
        to: Nav.makeTo({
          drawer: makeLink(paths.drawers.DeviceDetail, {
            controllerName,
            id: row.access_point?.id ?? '',
          }),
        }),
      })}
      columns={columns}
      data={devices}
      csvFileName={`${controllerName}-devices.csv`}
      csvRowFormatter={csvRowFormatter}
    />
  );
}

import React from 'react';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const Container = styled('div', {
  background: colors.white,
  position: 'sticky',
  bottom: 0,
  marginTop: 'auto',
  padding: '$12',
  boxShadow: shadows.fenceTopLight,
  [darkThemeSelector]: {
    background: colors['gray-800'],
    boxShadow: shadows.fenceTopDark,
  },
});

export interface MobileSidebarLocationControlContainerProps {}

export const MobileSidebarLocationControlContainer: React.FC<
  MobileSidebarLocationControlContainerProps
> = ({ children }) => <Container>{children}</Container>;

export default MobileSidebarLocationControlContainer;

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@meterup/metric';
import React from 'react';

import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { PlanControls } from './common/PlanControls';
import PlanDetails from './common/PlanDetails';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/:planId',
});

export default function ISPDetails() {
  const { controllerName, planId } = Nav.useRegionParams('drawer', paths.drawers.ISPDetails)!;

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Internet service plan</DrawerTitle>
        <PlanControls controllerName={controllerName} planId={planId} />
      </DrawerHeader>
      <DrawerContent>
        <PlanDetails controllerName={controllerName} planId={planId} />
      </DrawerContent>
    </Drawer>
  );
}

import { max } from 'lodash';

function isInset(shadow: string) {
  return shadow.includes('inset');
}

/**
 * Compares two shadow definitions, sorting inset shadows before other shadows
 */
const compareShadows = (a: string, b: string) => {
  const isAInset = isInset(a);
  const isBInset = isInset(b);

  if (isAInset === isBInset) {
    return a.localeCompare(b);
  }
  return isAInset ? -1 : 1;
};

/**
 * Pads a set of shadow definitions so that every definition has the same number
 * of shadows, matching up inner and outer shadows. This allows the browser to
 * smoothly animate transitions on the box-shadow property.
 *
 * @param states An object whose keys identify shadow states defined by the
 * array of corresponding values.
 *
 * @returns An object with keys identical to the input but with values of
 * fully-formed shadows including empty padding shadows.
 *
 * @example
 * createShadowAnimationStack({
 *   initial: ['0 0 0 1px black'],
 *   hovered: ['0 0 0 1px black', '0 0 0 2px blue'],
 * }) => {
 *  initial: '0 0 0 0 rgba(0,0,0,0), 0 0 0 1px black',
 *  hovered: '0 0 0 1px black, 0 0 0 2px blue'
 * }
 */
export function createShadowAnimationStack<T extends Record<string, Array<string>>>(
  states: T,
): { [k in keyof T]: string } {
  const maxInsetShadows =
    max(
      Object.values(states).map((shadows) => shadows.filter((shadow) => isInset(shadow)).length),
    ) ?? 0;

  const maxOuterShadows =
    max(
      Object.values(states).map((shadows) => shadows.filter((shadow) => !isInset(shadow)).length),
    ) ?? 0;

  return Object.fromEntries(
    Object.entries(states).map(([state, shadows]) => {
      const insetShadows = shadows.filter((shadow) => isInset(shadow)).sort(compareShadows);
      const outerShadows = shadows.filter((shadow) => !isInset(shadow)).sort(compareShadows);

      const fillerInsetShadows = new Array(maxInsetShadows - insetShadows.length).fill(
        'inset 0 0 0 0 rgba(0,0,0,0)',
      );

      const fillerOuterShadows = new Array(maxOuterShadows - outerShadows.length).fill(
        '0 0 0 0 rgba(0,0,0,0)',
      );

      return [
        state,
        [...insetShadows, ...fillerInsetShadows, ...outerShadows, ...fillerOuterShadows].join(', '),
      ];
    }),
  ) as { [k in keyof T]: string };
}

import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/noc/src/routes/pages/AppPage.layout.tsx";
import PagefilesImport1 from "/vercel/path0/apps/noc/src/routes/pages/CLI.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/noc/src/routes/pages/ControllersList.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/noc/src/routes/pages/GlobalStats.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/noc/src/routes/pages/companies/CompaniesList.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/noc/src/routes/pages/companies/CompanyUsers.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerClientsList.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerConfig.page.tsx";
import PagefilesImport8 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerDetails.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerDeviceClients.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerDevicesList.page.tsx";
import PagefilesImport11 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerIncidentsList.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerLogs.page.tsx";
import PagefilesImport13 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerPage.layout.tsx";
import PagefilesImport14 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerServicePlans.page.tsx";
import PagefilesImport15 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerState.page.tsx";
import PagefilesImport16 from "/vercel/path0/apps/noc/src/routes/pages/controllers/ControllerStats.page.tsx";

const routes = [
{path: "/",children: [{path: "/cli",element: React.createElement(PagefilesImport1)},
{index: true,element: React.createElement(PagefilesImport2)},
{path: "/companies",element: React.createElement(PagefilesImport4)},
{path: "/companies/:companyName/users",element: React.createElement(PagefilesImport5)},
{path: "/stats",element: React.createElement(PagefilesImport3)},],element: React.createElement(PagefilesImport0)},
{path: "/controllers/:controllerName",children: [{path: "/controllers/:controllerName/clients",element: React.createElement(PagefilesImport6)},
{path: "/controllers/:controllerName/config",element: React.createElement(PagefilesImport7)},
{index: true,element: React.createElement(PagefilesImport8)},
{path: "/controllers/:controllerName/devices",element: React.createElement(PagefilesImport10)},
{path: "/controllers/:controllerName/devices/:deviceName/clients",element: React.createElement(PagefilesImport9)},
{path: "/controllers/:controllerName/incidents",element: React.createElement(PagefilesImport11)},
{path: "/controllers/:controllerName/logs",element: React.createElement(PagefilesImport12)},
{path: "/controllers/:controllerName/plans",element: React.createElement(PagefilesImport14)},
{path: "/controllers/:controllerName/state",element: React.createElement(PagefilesImport15)},
{path: "/controllers/:controllerName/stats",element: React.createElement(PagefilesImport16)},],element: React.createElement(PagefilesImport13)},
];

export default routes;
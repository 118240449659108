import type { PropsWithChildren, ReactNode } from 'react';
import { CapsuleTooltip } from '@meterup/metric';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';

import { colors } from '../../stitches';

export const Tooltip = ({ children, content }: PropsWithChildren<{ content: ReactNode }>) => (
  <TooltipPrimitive.Root>
    <TooltipPrimitive.Trigger>{children}</TooltipPrimitive.Trigger>
    <TooltipPrimitive.Content side="top" asChild>
      <CapsuleTooltip
        style={{ color: colors['gray-700'].toString(), padding: '4px 8px', maxWidth: 200 }}
      >
        {content}
      </CapsuleTooltip>
    </TooltipPrimitive.Content>
  </TooltipPrimitive.Root>
);

import type { api } from '@meterup/proto';
import { getMany } from '@meterup/common';
import { orderBy } from 'lodash';

import { axiosInstanceJSON } from './api_clients';

const clientJSONDataToIClientData = (d: api.ControllerClient) => ({
  ...d,
  name: d.lease!.name,
  ip: d.lease!.ip,
  mac: d.lease!.mac,
});

export const fetchClientsJSON = async (controllerName: string, deviceName?: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ConnectedClientsResponse>(
      `/v1/controllers/${controllerName}/clients`,
      { params: { ap: deviceName } },
    );
    return orderBy(
      result.data.clients.map(clientJSONDataToIClientData),
      (d) => d.last_seen,
      'desc',
    );
  });

export const fetchClientHistoryJSON = async (controllerName: string, mac: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ConnectedClientsResponse>(
      `/v1/controllers/${controllerName}/clients`,
      { params: { mac } },
    );
    return orderBy(
      result.data.clients.map(clientJSONDataToIClientData),
      (d) => d.last_seen,
      'desc',
    );
  });

import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

const Box = styled('div');

export interface CaptivePageLayoutProps {
  content: React.ReactNode;
  sideContent?: React.ReactNode;
  footer: React.ReactNode;
}

export const CaptivePageLayout: React.FunctionComponent<CaptivePageLayoutProps> = ({
  content,
  sideContent,
  footer,
}) => (
  <Box css={{ display: 'grid', minHeight: '100vh', '@lg': { gridTemplateColumns: '1fr 1fr' } }}>
    <Box
      css={{
        display: 'grid',
        gridTemplateRows: 'auto min-content',
        backgroundColor: 'white',
        [darkThemeSelector]: {
          backgroundColor: colors['gray-800'],
        },
      }}
    >
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          css={{
            width: '100%',
            maxWidth: 536,
            padding: '$32 $80',
          }}
        >
          {content}
        </Box>
      </Box>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box css={{ width: '100%', maxWidth: 536, padding: '$32 $80' }}>{footer}</Box>
      </Box>
    </Box>
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: colors.captiveChecker,
        [darkThemeSelector]: {
          background: colors.captiveCheckerDark,
        },
      }}
    >
      {sideContent}
    </Box>
  </Box>
);

export default CaptivePageLayout;

import React from 'react';

export const DeviceIconSmall: React.FunctionComponent<{}> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="xMidYMid meet"
    style={{
      filter: `
        drop-shadow(-1px 0 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(1px 0 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 -1px 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 1px 0 rgba(9, 11, 20, 0.05)) 
        drop-shadow(0 1px 1px rgba(9, 11, 20, 0.08))
      `,
    }}
  >
    <path
      d="M20 10C20 6.58894 19.7675 2.74054 18.5175 1.48688C17.2675 0.233235 12.7907 0 10.0291 0C7.26743 0 2.79072 0.233235 1.5407 1.48688C0.290696 2.74054 0 6.58894 0 10C0 13.4111 0.2907 17.2595 1.5407 18.5131C2.79072 19.7668 7.26743 20 10.0291 20C12.7907 20 17.2674 19.7668 18.5174 18.5131C19.7675 17.2595 20 13.4111 20 10Z"
      fill="white"
    />
    <path
      d="M20 10C20 6.58894 19.7675 2.74054 18.5175 1.48688C17.2675 0.233235 12.7907 0 10.0291 0C7.26743 0 2.79072 0.233235 1.5407 1.48688C0.290696 2.74054 0 6.58894 0 10C0 13.4111 0.2907 17.2595 1.5407 18.5131C2.79072 19.7668 7.26743 20 10.0291 20C12.7907 20 17.2674 19.7668 18.5174 18.5131C19.7675 17.2595 20 13.4111 20 10Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M6.75761 12.45H7.551V12.3639C7.551 12.1872 7.55059 12.0105 7.55017 11.8338C7.54914 11.3898 7.5481 10.9459 7.55361 10.5019C7.5566 10.2513 7.57564 9.9996 7.60864 9.75123C7.65297 9.4171 7.76147 9.10185 7.95108 8.81964C8.13769 8.54187 8.37229 8.33112 8.72008 8.29215C9.11912 8.24738 9.45321 8.40474 9.55063 8.84391C9.58623 9.00409 9.59718 9.17251 9.59783 9.33728C9.60076 10.0647 9.60047 10.7921 9.60019 11.5196C9.60009 11.7982 9.59998 12.0768 9.60005 12.3554C9.60005 12.3765 9.60145 12.3977 9.60283 12.4188C9.60348 12.4286 9.60412 12.4383 9.60461 12.448H10.4013V12.3688C10.4013 12.1869 10.4008 12.0049 10.4004 11.8229C10.3994 11.3658 10.3984 10.9086 10.4039 10.4516C10.4067 10.2081 10.4256 9.96292 10.4612 9.72211C10.5202 9.32433 10.6612 8.9569 10.9273 8.64718C11.1448 8.39396 11.4174 8.26397 11.7529 8.28716C12.0294 8.30631 12.2355 8.43481 12.3485 8.69693C12.4277 8.88072 12.4433 9.07569 12.4434 9.27229C12.4441 10.0283 12.444 10.7843 12.4439 11.5403C12.4438 11.8156 12.4438 12.091 12.4438 12.3664V12.4491H13.2386C13.2398 12.4257 13.2417 12.4057 13.2417 12.3854C13.2418 12.1273 13.2419 11.8691 13.2421 11.6109C13.2426 10.7593 13.2431 9.90757 13.2393 9.05601C13.2385 8.88449 13.2189 8.70974 13.182 8.54241C13.1016 8.1774 12.9127 7.88009 12.5721 7.70709C12.1902 7.51319 11.7886 7.51144 11.387 7.63293C10.9355 7.76939 10.6169 8.07506 10.3777 8.47297C10.3633 8.49683 10.3488 8.5207 10.3289 8.5536C10.3224 8.5309 10.3176 8.51377 10.3133 8.49881C10.3107 8.48969 10.3082 8.48138 10.3057 8.4731C10.189 8.09394 9.96558 7.80647 9.59223 7.65558C9.30273 7.53868 9.00149 7.52695 8.69921 7.59113C8.20054 7.69725 7.8461 7.99834 7.59677 8.43562C7.58983 8.44778 7.58295 8.45995 7.57608 8.47211C7.57145 8.48031 7.56683 8.4885 7.56221 8.4967L7.5552 8.49386L7.54878 8.49131V7.64183H6.75761V12.45Z"
      fill="#5461C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2499 3.56364C12.5813 3.56364 12.8499 3.29501 12.8499 2.96364C12.8499 2.63228 12.5813 2.36365 12.2499 2.36365C11.9186 2.36365 11.6499 2.63228 11.6499 2.96364C11.6499 3.29501 11.9186 3.56364 12.2499 3.56364ZM7.75007 3.56366C8.08144 3.56366 8.35007 3.29503 8.35007 2.96366C8.35007 2.63229 8.08144 2.36366 7.75007 2.36366C7.4187 2.36366 7.15007 2.63229 7.15007 2.96366C7.15007 3.29503 7.4187 3.56366 7.75007 3.56366Z"
      fill="#33C44A"
    />
    <path
      d="M6.10002 2.96368C6.10002 3.29505 5.83139 3.56367 5.50002 3.56367C5.16865 3.56367 4.90002 3.29505 4.90002 2.96368C4.90002 2.63231 5.16865 2.36368 5.50002 2.36368C5.83139 2.36368 6.10002 2.63231 6.10002 2.96368Z"
      fill="#6977E1"
    />
    <path
      d="M10.6 2.96368C10.6 3.29505 10.3314 3.56367 10 3.56367C9.66863 3.56367 9.40001 3.29505 9.40001 2.96368C9.40001 2.63231 9.66863 2.36368 10 2.36368C10.3314 2.36368 10.6 2.63231 10.6 2.96368Z"
      fill="#6977E1"
    />
    <path
      d="M14.5 3.56367C14.8314 3.56367 15.1 3.29505 15.1 2.96368C15.1 2.63231 14.8314 2.36368 14.5 2.36368C14.1686 2.36368 13.9 2.63231 13.9 2.96368C13.9 3.29505 14.1686 3.56367 14.5 3.56367Z"
      fill="#6977E1"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="18.2413"
        y1="0.758017"
        x2="7.62243"
        y2="22.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.03" />
        <stop offset="0.500255" stopColor="#E1E4EC" stopOpacity="0.11" />
        <stop offset="1" stopColor="#D4DAE5" stopOpacity="0.56" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeviceIconSmall;

import {
  BasicSelect,
  BasicSelectItem,
  FieldContainer,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  SecondaryField,
  Textarea,
  TextInput,
} from '@meterup/metric';
import { capitalize } from 'lodash';
import React from 'react';

import { FieldProvider } from '../../../../../components/FieldProvider';
import { TimezoneAbbreviation } from '../../../../../components/timestamps';
import { useCurrentTimezone } from '../../../../../providers/CurrentTimezoneProvider';
import { categories, CATEGORY_NONE } from './form_data';

type EditFormContentProps = {
  page: string;
};

export const EditFormContent = ({ page }: EditFormContentProps) => {
  const timezone = useCurrentTimezone();
  const tz = TimezoneAbbreviation(timezone);

  return (
    <>
      {page === 'duration' && (
        <FieldContainer>
          <ListItemHeader>
            <ListTitle>Edit duration</ListTitle>
          </ListItemHeader>
          <FieldProvider name="start_time">
            <SecondaryField
              label={`Start time (${tz})`}
              element={<TextInput type="datetime-local" />}
            />
          </FieldProvider>
          <FieldProvider name="end_time">
            <SecondaryField
              label={`End time (${tz})`}
              element={<TextInput type="datetime-local" />}
            />
          </FieldProvider>
        </FieldContainer>
      )}
      {page === 'details' && (
        <FieldContainer>
          <FieldProvider name="notes">
            <PrimaryField label="Notes" element={<Textarea />} />
          </FieldProvider>
          <FieldProvider name="category">
            <SecondaryField
              label="Category"
              element={
                <BasicSelect>
                  <BasicSelectItem value={CATEGORY_NONE}>None</BasicSelectItem>
                  {categories.map((c) => (
                    <BasicSelectItem key={c} value={c}>
                      {capitalize(c)}
                    </BasicSelectItem>
                  ))}
                </BasicSelect>
              }
            />
          </FieldProvider>
        </FieldContainer>
      )}
    </>
  );
};

import { colors, darkThemeSelector, fonts, fontWeights, globalCss } from './stitches.config';

export const injectGlobalMetricStyles = globalCss({
  body: {
    fontSize: 16,
    fontFamily: fonts.sans,
    fontWeight: fontWeights.regular,
    background: colors.white,
    color: colors['gray-600'],
    [darkThemeSelector]: {
      color: colors['gray-200'],
      background: colors['gray-800'],
    },
    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
  },
});

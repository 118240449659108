import type { ValueOf } from 'ts-pattern/dist/types/helpers';
import { freeze, immerable } from 'immer';

import type { ControllerConfigObject, ct } from '../types/config/config';
import {
  METER_V1_NETWORK_VLAN_PREFIX,
  METER_V2_WIRELESS_ACCESS_POINTS_PREFIX,
  METER_V2_WIRELESS_TAGS_PREFIX,
} from '../types/config/config';
import { MeterV1NetworkVLAN } from './MeterV1NetworkVLAN';
import { MeterV2WirelessAccessPoint } from './MeterV2WirelessAccessPoint';
import { MeterV2WirelessServiceSet } from './MeterV2WirelessServiceSet';
import { MeterV2WirelessTag } from './MeterV2WirelessTag';

function getKeyValuePairsFromPrefix<T extends ValueOf<ControllerConfigObject>>(
  prefix: string,
  json: ControllerConfigObject,
) {
  return Object.entries(json)
    .filter(([key]) => key.startsWith(prefix))
    .map(([key, value]) => ({
      fullKey: key,
      shortKey: key.substring(prefix.length),
      value: value as T,
    }));
}

export class MeterControllerConfig {
  [immerable] = true;

  private constructor(
    public tags: MeterV2WirelessTag[],
    public serviceSets: MeterV2WirelessServiceSet[],
    public vlans: MeterV1NetworkVLAN[],
    public accessPoints: MeterV2WirelessAccessPoint[],
    public json: ControllerConfigObject,
  ) {}

  static fromJSON(json: ControllerConfigObject): MeterControllerConfig {
    const tagsJSON = getKeyValuePairsFromPrefix<ct.MeterV2WirelessTag>(
      METER_V2_WIRELESS_TAGS_PREFIX,
      json,
    );

    const tags = tagsJSON.map(({ shortKey, value }) =>
      MeterV2WirelessTag.fromJSON(shortKey, value),
    );

    const vlans = getKeyValuePairsFromPrefix<ct.MeterV1NetworkVLAN>(
      METER_V1_NETWORK_VLAN_PREFIX,
      json,
    ).map(({ shortKey, value }) => MeterV1NetworkVLAN.fromJSON(shortKey, value));

    const accessPoints = getKeyValuePairsFromPrefix<ct.MeterV2WirelessAccessPoint>(
      METER_V2_WIRELESS_ACCESS_POINTS_PREFIX,
      json,
    ).map(({ shortKey, value }) => MeterV2WirelessAccessPoint.fromJSON(shortKey, value));

    const serviceSets = tagsJSON
      .map(({ shortKey: tagName, value }) =>
        Object.entries(value['service-sets'] ?? {}).map(([ssid, ssidJSON]) =>
          MeterV2WirelessServiceSet.fromJSON(tagName, ssid, ssidJSON),
        ),
      )
      .flat();

    return freeze(new MeterControllerConfig(tags, serviceSets, vlans, accessPoints, json), true);
  }

  getAPsBroadcastingServiceSet(
    serviceSet: MeterV2WirelessServiceSet,
  ): MeterV2WirelessAccessPoint[] {
    const tag = this.tags.find((t) => t.name === serviceSet.tagName);

    if (!tag) {
      return [];
    }

    return this.getAccessPointsWithTag(tag);
  }

  doesAccessPointBroadcastServiceSet(
    accessPoint: MeterV2WirelessAccessPoint,
    serviceSet: MeterV2WirelessServiceSet,
  ): boolean {
    const tag = this.tags.find((t) => t.name === serviceSet.tagName);

    if (!tag) {
      return false;
    }

    return this.doesAccessPointHaveTag(accessPoint, tag);
  }

  // eslint-disable-next-line class-methods-use-this
  private doesAccessPointHaveTag(
    accessPoint: MeterV2WirelessAccessPoint,
    tag: MeterV2WirelessTag,
  ): boolean {
    return accessPoint.json.includes(tag.name);
  }

  getServiceSetByEphemeralId(ephemeralId: string): MeterV2WirelessServiceSet | null {
    return this.serviceSets.find((serviceSet) => serviceSet.ephemeralId === ephemeralId) ?? null;
  }

  getVLANByName(name: string): MeterV1NetworkVLAN | null {
    return this.vlans.find((vlan) => vlan.name === name) ?? null;
  }

  private getAccessPointsWithTag(tag: MeterV2WirelessTag) {
    return this.accessPoints.filter((ap) => ap.json.includes(tag.name));
  }

  private getServiceSetsForTag(tagName: string) {
    return this.serviceSets.filter((serviceSet) => serviceSet.tagName === tagName);
  }

  toJSON(): ControllerConfigObject {
    return {
      ...this.json,
      ...Object.fromEntries(
        this.tags.map((tag) => [
          `${METER_V2_WIRELESS_TAGS_PREFIX}${tag.name}`,
          tag.toJSON(this.getServiceSetsForTag(tag.name)),
        ]),
      ),
      ...Object.fromEntries(
        this.vlans.map((vlan) => [`${METER_V1_NETWORK_VLAN_PREFIX}${vlan.name}`, vlan.toJSON()]),
      ),
      ...Object.fromEntries(
        this.accessPoints.map((ap) => [
          `${METER_V2_WIRELESS_ACCESS_POINTS_PREFIX}${ap.name}`,
          ap.toJSON(),
        ]),
      ),
    };
  }
}

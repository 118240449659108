/* eslint-disable react/prop-types */
import type { LinkProps } from 'react-router-dom';
import type {
  Accessor,
  Column,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
} from 'react-table';
import { isDefined } from '@meterup/common';
import {
  activeThemeClassName,
  Alert,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTriggerButton,
  NavigableTableArrowCell,
  NavigableTableDeselectCell,
  NavigableTablePlaceholderCell,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TextInput,
} from '@meterup/metric';
import { ExportToCsv } from 'export-to-csv';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';

import { css, styled } from '../../stitches';

export type CSVRowFormatter<D extends object> = (
  row: D,
  rowIndex: number,
) => Record<string, unknown> | Record<string, unknown>[];

function createDefaultCSVRowFormatter<D extends object>(
  columns: readonly Column<D>[],
): CSVRowFormatter<D> {
  return (row: D, rowIndex: number) =>
    Object.fromEntries(
      columns.map((column) => [
        column.Header,
        typeof column.accessor === 'function'
          ? (column.accessor as Accessor<D>)(row, rowIndex, { subRows: [], depth: 0, data: [] })
          : row[column.accessor as keyof D],
      ]),
    );
}

function createCSVExportHandler<D extends object>(
  csvRowFormatter: CSVRowFormatter<D>,
  data: readonly D[],
  exportFileName: string,
) {
  const dataToExport = data.map(csvRowFormatter).flat();

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: exportFileName.replace('.csv', ''),
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  return () => csvExporter.generateCsv(dataToExport);
}

const TableGroupContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '-0.5px',
  marginBottom: '-0.5px',
});

const TableGroupBar = css({
  hStack: '$16',
  height: '$56',
  width: '100%',
  maxWidth: '100%',
  overflow: 'auto',
});

const TableGroupTopBar = styled('div', TableGroupBar, {
  padding: '$12 14px',
  borderRadiusTop: '$8',
});

const TableGroupBarTabs = styled('div', {});
const TableGroupBarControls = styled('div', { marginLeft: 'auto', hStack: '$8' });

const GlobalSearch: React.FC<{
  instanceProps: UseGlobalFiltersInstanceProps<any> & { state: UseGlobalFiltersState<any> };
}> = ({ instanceProps }) => (
  <TextInput
    aria-label="Search"
    type="search"
    id="search"
    icon="searchScoped"
    value={instanceProps.state.globalFilter}
    onChange={(e) => {
      instanceProps.setGlobalFilter(e);
    }}
  />
);

export interface Table2Props<D extends object = {}> {
  columns: ReadonlyArray<Column<D>>;
  data: readonly D[];
  tabs?: React.ReactNode;
  emptyStateHeading?: string;
  emptyStateCopy?: string;
  linkProps?: (row: D) => LinkProps;
  isRowSelected?: (row: D) => boolean;
  onRowDeselect?: (row: D) => void;
  additionalDropdownItems?: React.ReactNode;
  additionalControls?: React.ReactNode;
  shouldShowTopBar?: boolean;
  csvFileName?: string;
  csvRowFormatter?: CSVRowFormatter<D>;
}

export const AutoTable = <D extends object>({
  columns,
  data,
  linkProps,
  isRowSelected,
  onRowDeselect,
  tabs,
  shouldShowTopBar = true,
  csvFileName = 'export.csv',
  csvRowFormatter,
  emptyStateHeading = 'No rows',
  emptyStateCopy,
  additionalDropdownItems,
  additionalControls,
}: Table2Props<D>) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const isNavigableTable = isDefined(linkProps);

  const exportHandler = useMemo(
    () =>
      createCSVExportHandler(
        csvRowFormatter ?? createDefaultCSVRowFormatter(columns),
        data,
        csvFileName,
      ),
    [csvRowFormatter, csvFileName, columns, data],
  );

  return (
    <TableGroupContainer>
      {shouldShowTopBar && (
        <TableGroupTopBar>
          {tabs && <TableGroupBarTabs>{tabs}</TableGroupBarTabs>}
          <TableGroupBarControls>
            {additionalControls}
            <Button
              icon="download"
              arrangement="leading-icon"
              variant="tertiary"
              onClick={exportHandler}
            >
              Export
            </Button>
            <GlobalSearch instanceProps={tableInstance} />
            {additionalDropdownItems && (
              <DropdownMenu>
                <DropdownMenuTriggerButton
                  arrangement="hidden-label"
                  icon="overflowVertical"
                  variant="secondary"
                >
                  Actions
                </DropdownMenuTriggerButton>
                <DropdownMenuContent>{additionalDropdownItems}</DropdownMenuContent>
              </DropdownMenu>
            )}
          </TableGroupBarControls>
        </TableGroupTopBar>
      )}
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                let sortDirection: 'ascending' | 'descending' | undefined;
                if (column.isSorted) {
                  if (column.isSortedDesc) {
                    sortDirection = 'descending';
                  } else {
                    sortDirection = 'ascending';
                  }
                }

                return (
                  <TableHeadCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sortDirection={sortDirection}
                  >
                    {column.render('Header')}
                  </TableHeadCell>
                );
              })}
              {isNavigableTable && <NavigableTablePlaceholderCell />}
            </TableHeadRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const SmartLinkOrDiv = isDefined(linkProps) ? Link : 'div';
            const maybeLinkProps = isDefined(linkProps) ? linkProps(row.original) : {};

            const rowIsSelected = isDefined(isRowSelected) ? isRowSelected(row.original) : false;

            return (
              <TableRow
                {...row.getRowProps()}
                {...maybeLinkProps}
                as={SmartLinkOrDiv}
                isSelected={rowIsSelected}
                className={rowIsSelected && activeThemeClassName}
              >
                {row.cells.map((cell) => (
                  <TableDataCell {...cell.getCellProps()}>{cell.render('Cell')}</TableDataCell>
                ))}
                {rowIsSelected && onRowDeselect ? (
                  <NavigableTableDeselectCell onClick={() => onRowDeselect(row.original)} />
                ) : (
                  isNavigableTable && <NavigableTableArrowCell />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {rows.length === 0 && (
        <Alert heading={emptyStateHeading} copy={emptyStateCopy} cornerStyle="square" />
      )}
    </TableGroupContainer>
  );
};

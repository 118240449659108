import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../../common/animations';
import { colors, darkThemeSelector, shadows, styled } from '../../../stitches.config';
import { Small2 } from '../../../text/Small';

export const CapsuleTooltip = styled('div', Small2, {
  display: 'flex',
  width: 'fit-content',
  backgroundColor: colors.white,
  boxShadow: shadows.modalLight,
  borderRadius: '$4',
  paddingX: '$4',
  color: colors['brand-600'],
  [darkThemeSelector]: {
    color: colors['brand-100'],
    backgroundColor: colors['gray-700'],
    boxShadow: shadows.modalDark,
  },
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '150ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="instant-open"], &[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export default CapsuleTooltip;

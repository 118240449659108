import React from 'react';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(min-content, 1fr) min-content minmax(min-content, 1fr)',
  alignItems: 'center',
  gap: '$8',
  height: '$40',
  paddingX: '$12',
  boxShadow: shadows.fenceBottomLight,
  background: colors.white,
  [darkThemeSelector]: {
    background: colors['gray-800'],
    boxShadow: shadows.fenceBottomDark,
  },
});

const NavigationContainer = styled('div', { hStack: '$2' });
const ControlsContainer = styled('div', { hStack: '$8', justifyContent: 'end' });

export interface HeaderProps {
  navigation?: React.ReactNode;
  logo?: React.ReactNode;
  controls?: React.ReactNode;
}

export const Header: React.FunctionComponent<HeaderProps> = ({ controls, logo, navigation }) => (
  <Container>
    <NavigationContainer>{navigation}</NavigationContainer>
    <div>{logo}</div>
    <ControlsContainer>{controls}</ControlsContainer>
  </Container>
);

export default Header;
